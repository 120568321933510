import Vue from "vue"

import { Game } from "@/types/game"

import ActionTypes from "@/store/action-types"

const ClientGamesConsumer = Vue.extend({
  name: "ClientGamesConsumer",
  computed: {
    games(): Game[] {
      return this.$store.state.pregame.games
    }
  },
  created() {
    this.$store.dispatch(ActionTypes.SUBSCRIBE_TO_CLIENT_GAMES)
  },
  beforeDestroy() {
    this.$store.dispatch(ActionTypes.UNSUBSCRIBE_FROM_CLIENT_GAMES)
  },
  methods: {
    _getGameByID(gameID: string) {
      return this.games.find(game => game.id === gameID)
    }
  }
})

export default ClientGamesConsumer
