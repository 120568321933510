var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("RoomDetailLayout", { attrs: { imageTitle: _vm.imageTitle } }, [
    _c("div", { staticClass: "main-title" }, [_vm._v("WELCOME")]),
    _c("div", [
      _vm._v("You can begin a chat with any of the attendees on the left"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }