




































































































































































































// @ts-nocheck
import { mapGetters, mapActions } from "vuex"
import { db } from "@/firebase"

import { GameType } from "@/entities/GameType"

import ClientUsersConsumer from "@/modules/users/components/ClientUsersConsumer"
import ClientGamesConsumer from "@/consumers/ClientGamesConsumer"

import PreGamePlayer from "@/components/Lobby/Player.vue"
import UserName from "@/components/GroupTeams/Common/User/UserName.vue"
import RoomInfo from "@/components/RoomInfo.vue"
import PreGameCountdown from "@/components/PreGameCountdown.vue"
import User from "@shared/User"
import { Role } from "@/helpers"

import LiveChatMixin from "@/mixins/LiveChat"

const TIMEOUT_DELAY = 15000

const FirebaseEvent = {
  VALUE: "value"
}

export default ClientUsersConsumer.extend(
  ClientGamesConsumer.extend({
    name: "ProfileLobby",
    mixins: [LiveChatMixin],
    props: {
      userID: {
        type: String,
        required: true
      },
      clientID: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        time: 0,
        loading: false,
        assignedToGame: false,
        subscribedToRoomID: false,
        subscribedToRoom: false
      }
    },
    async created() {
      await Promise.all([
        this.subscribeToUser({ userID: this.userID }),
        this.subscribeToUserRoomID({ userID: this.userID })
      ])
      this.subscribedToRoomID = true
    },
    components: {
      PreGamePlayer,
      RoomInfo,
      UserName,
      PreGameCountdown
    },
    watch: {
      profileRoomID: {
        async handler(value) {
          if (value) {
            this.subscribedToRoom = false
            await this.subscribeToUserRoom({ roomID: value })
            this.subscribedToRoom = true
          } else {
            await this.purgeRoomData()
            this.subscribedToRoom = false
          }
        },
        immediate: true
      },
      async profileGameID(gameID) {
        if (gameID) {
          await this.fetchGame({ gameID, orgID: this.profileOrgID })
          this.time = Date.now()
        }
      },
      async assignedToGame(game) {
        await this.pushUsersToGame({ users: [this.userID], gameID: game.id })
      }
    },
    computed: {
      ...mapGetters("auth", ["user", "isHost", "isSuper", "client"]),
      ...mapGetters("profile", {
        profileGame: "game",
        profileUser: "user",
        profileRoom: "room",
        profileRoomID: "roomID"
      }),
      ...mapGetters("livechat", ["roomID", "max"]),
      profileGameID() {
        return this.profileUser?.gameID
      },
      profileOrgID() {
        return this.profileUser?.orgID
      },
      profileUserID() {
        return this.profileUser?.id
      },
      gameStartTime() {
        return parseInt(this.profileGame?.startTimestamp) || 0
      },
      isProfileUserBusy() {
        return (
          !User.isSelectingRoom(this.profileUser) &&
          this.$store.getters.isUserAlive(this.profileUser)
        )
      },
      currentRoomUserIDs() {
        if (!this.profileRoom) return []
        return Object.values(this.profileRoom.users)
          .filter(user => user)
          .filter(({ status, userID }) => {
            return status === "active" && userID !== this.userID && userID
          })
          .map(({ userID }) => userID)
      },
      isProfileRoomFull() {
        return this.currentRoomUserIDs.length + 1 > this.max - 1 && !this.isHost
      },
      isAloneInRoom() {
        return !this.currentRoomUserIDs.length
      },
      currentRoomUsers() {
        const currentRoomUserIDs = this.currentRoomUserIDs
        return this.users.filter(user => currentRoomUserIDs.includes(user.id))
      },
      request() {
        if (this.profileRoom && this.isCurrentRoom) {
          return this.profileRoom.users[this.userID]
        } else {
          return null
        }
      },
      isCurrentRoom() {
        return this.profileRoomID === this.roomID && !!this.roomID
      },
      requestStatus() {
        return this.request ? this.request.status : null
      },
      isWaiting() {
        return !this.requestStatus && this.loading
      },
      isCurrentUser() {
        return this.user ? this.user.id === this.userID : false
      },
      isProfileRoomSet() {
        return !!this.profileRoomID && !!this.profileRoom
      },
      isProfileRoomLocked() {
        return !!this.profileRoom?.locked
      },
      isProfileUserOnboarding() {
        return User.isOnboarding(this.profileUser)
      },
      requestButtonStatus() {
        if (
          !this.subscribedToRoomID ||
          (this.profileRoomID && !this.subscribedToRoom) ||
          this.isCurrentUser ||
          !this.isUserOnline ||
          this.requestStatus === "active" ||
          this.isCurrentRoom
        ) {
          return "hidden"
        }

        if (this.isProfileUserOnboarding) {
          return "onboarding"
        }

        if (this.isProfileRoomFull) {
          return "full"
        }

        if (this.isProfileUserBusy) {
          return "playing"
        }

        if (this.isWaiting) {
          return "loading"
        }

        if (
          (this.isUserBroadcasting && !this.isVip && !this.isHost) ||
          (this.isUserLocked && !this.isSuper) ||
          (this.isProfileRoomSet && this.isProfileRoomLocked && !this.isSuper)
        ) {
          return "locked"
        }

        if (
          (this.isVip || this.isHost) &&
          this.$_LiveChatMixin_isUserBroadcasting
        ) {
          return "show"
        }

        if (this.isProfileRoomSet && !this.isAloneInRoom) {
          return "join"
        }

        return "show"
      },
      isUserOnline() {
        return this.$store.getters.isUserAlive(this.profileUser)
      },
      filteredGamesArray() {
        const games = this.games.filter(
          obj =>
            obj.id !== this.profileUser.gameID &&
            !obj.deactivate &&
            !obj.endTimestamp &&
            !obj.deletedTimestamp &&
            (!obj.gameType ||
              obj.gameType === GameType.Standard ||
              obj.gameType === GameType.GreenRoom ||
              obj.streamUrl)
        )
        if (this.profileUser.gameID) games.push({ id: 0, name: "Unassign" })
        return games
      },
      isSpectator() {
        return (
          this.profileUser.role === Role.Audit ||
          this.profileUser.role === Role.Spectator
        )
      },
      isVip() {
        return this.user?.vip
      },
      streamRoomID() {
        return this.client?.stream?.roomID
      },
      isUserBroadcasting() {
        return this.profileRoomID === this.streamRoomID
      },
      isUserLocked() {
        return this.$_LiveChatMixin_isUserLocked(this.profileUser)
      }
    },
    methods: {
      ...mapActions("profile", [
        "subscribeToUser",
        "fetchGame",
        "subscribeToUserRoom",
        "subscribeToUserRoomID",
        "purgeRoomData"
      ]),
      ...mapActions("GameUsers", ["pushUsersToGame"]),
      isHostRole(role) {
        return role === Role.Host
      },
      onGameClick() {
        this.$emit("onGameClick", {
          gameID: this.profileUser.gameID,
          scroll: true
        })
      },
      onJoin() {
        this.$emit("onJoin", { roomID: this.profileRoomID })
      },
      async inviteChat() {
        this.loading = true

        let priority = 0
        if (this.isHost) priority = 1

        await this.$store.dispatch("livechat/invite", {
          userID: this.userID,
          priority
        })
        this.loading = false
      },

      async inviteBroadcast() {
        this.loading = true

        await this.$store.dispatch("livechat/invite", {
          userID: this.userID,
          priority: 2,
          _roomID: this.$_LiveChatMixin_streamRoomID
        })

        this.loading = false
      }
    }
  })
)
