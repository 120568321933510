var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ToolbarDropdown",
    {
      attrs: {
        title: "Requested Games",
        svgIcon: "bell",
        value: _vm.value,
        disabled: !_vm.filtered.length,
        success: !!_vm.filtered.length,
      },
      on: {
        input: function ($event) {
          return _vm.$emit("input", $event)
        },
      },
    },
    [
      _c(
        "v-list",
        {
          staticClass: "requested-list",
          staticStyle: { "max-height": "240px", "overflow-y": "auto" },
          attrs: { dark: "" },
        },
        [
          _c("v-divider"),
          _vm._l(_vm.filtered, function (game, index) {
            return [
              _c(
                "v-list-tile",
                {
                  key: game.id + index,
                  on: {
                    click: function ($event) {
                      return _vm.$emit("select", { gameID: game.id })
                    },
                  },
                },
                [
                  _c(
                    "v-list-tile-content",
                    [
                      _c("v-list-tile-title", [
                        _vm._v(
                          " Game: " +
                            _vm._s(game.externalName || game.name) +
                            " "
                        ),
                      ]),
                      _c("v-list-tile-sub-title", [
                        _vm._v(" User: " + _vm._s(game.helpRequestedBy) + " "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { key: game.id }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }