var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "lobby-page lobby-page--whith-right-indent",
      attrs: { "fill-height": "", fluid: "" },
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            {
              staticStyle: { "backround-color": "#16111a !important" },
              attrs: { shrink: "" },
            },
            [
              (!!_vm.client && _vm.isHost) || _vm.isAudit || _vm.isSpeaker
                ? _c("Header", {
                    attrs: { "logo-size": "sm" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "game-name",
                          fn: function () {
                            return [
                              _c("h1", { staticClass: "rtb-header__title" }, [
                                _vm._v(" " + _vm._s(_vm.client.name) + " "),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "right",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rtb-d-flex items-center justify-end h-full pr-2",
                                },
                                [
                                  _vm.isHost
                                    ? [
                                        _vm.isHost
                                          ? _c(
                                              "rtb-control-button",
                                              {
                                                staticClass: "mr-2",
                                                on: {
                                                  click:
                                                    _vm.recordClientAnnouncement,
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    name: "record",
                                                    width: "20",
                                                    height: "20",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              "nudge-width": 100,
                                              "offset-y": "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "rtb-control-button",
                                                        _vm._g(
                                                          {
                                                            staticClass: "mr-2",
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c("SvgIcon", {
                                                            attrs: {
                                                              name: "gear-1",
                                                              width: "20",
                                                              height: "20",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3490029337
                                            ),
                                          },
                                          [
                                            _c(
                                              "v-list",
                                              { attrs: { dark: "" } },
                                              [
                                                _vm._l(
                                                  _vm.hostOptions,
                                                  function (item, index) {
                                                    return [
                                                      item.type
                                                        ? _c("v-divider", {
                                                            key:
                                                              item +
                                                              "-" +
                                                              index,
                                                          })
                                                        : _c(
                                                            "v-list-tile",
                                                            {
                                                              key:
                                                                item +
                                                                "-" +
                                                                index,
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.callAction(
                                                                      item.action
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-tile-title",
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.title
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                    ]
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "rtb-control-button",
                                          {
                                            staticClass:
                                              "whitespace-no-wrap mr-2",
                                            on: {
                                              click: function ($event) {
                                                _vm.showEditEvent = true
                                              },
                                            },
                                          },
                                          [_vm._v(" Edit Event ")]
                                        ),
                                        !_vm.isModerator
                                          ? _c(
                                              "rtb-control-button",
                                              {
                                                staticClass:
                                                  "whitespace-no-wrap mr-2",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$navigator.navigateTo(
                                                      "/game/" +
                                                        _vm.urlID +
                                                        "/settings"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" Dashboard ")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "rtb-control-button",
                                          {
                                            staticClass:
                                              "whitespace-no-wrap mr-2",
                                            on: {
                                              click: function ($event) {
                                                if (!$event.altKey) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.resetTimes($event)
                                              },
                                            },
                                          },
                                          [_vm._v(" ALT - Reset 5 Min ")]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm.isSpeaker
                                    ? _c(
                                        "rtb-control-button",
                                        {
                                          staticClass: "mr-2",
                                          on: {
                                            click: _vm.recordClientAnnouncement,
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              name: "record",
                                              width: "20",
                                              height: "20",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "20px",
                                              },
                                            },
                                            [_vm._v(" Record Annoucnement")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.isSpeaker
                                    ? _c(
                                        "rtb-control-button",
                                        {
                                          staticClass:
                                            "whitespace-no-wrap mr-2",
                                          on: { click: _vm.auditGamesAction },
                                        },
                                        [_vm._v(" Oversee Game ")]
                                      )
                                    : _vm._e(),
                                  _vm.isSuper || _vm.isEscapeRoom
                                    ? _c("ClientAudioPlayer", {
                                        staticClass: "ml-2",
                                      })
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2600905758
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-layout",
            {
              staticClass: "flex-grow h-0 lobby-page__content",
              attrs: { row: "" },
            },
            [
              _c(
                "v-layout",
                {
                  staticClass: "lobby-page__right-column",
                  attrs: { md3: "", grow: "" },
                },
                [
                  _c("LobbyTitle", [
                    _vm._v(_vm._s(_vm.client.lobbyName || _vm.client.name)),
                  ]),
                  _c(
                    "v-layout",
                    {
                      staticClass: "lobby-page__right-column-cnt",
                      attrs: { column: "", grow: "", "fill-height": "" },
                    },
                    [
                      _c("LiveChat", {
                        staticStyle: { "flex-grow": "0" },
                        attrs: { selectedGameID: _vm.selectedGameID },
                        on: {
                          onGameClick: _vm.selectGameCard,
                          onPlayerInvite: _vm.onInvite,
                        },
                      }),
                      _c(
                        "v-flex",
                        { attrs: { grow: "" } },
                        [
                          _c(
                            "LobbyTabs",
                            {
                              staticClass: "rtb-vertical-grow-tabs fill-height",
                              attrs: { value: _vm.videoGroupTab },
                              on: {
                                "update:value": function ($event) {
                                  _vm.videoGroupTab = $event
                                },
                              },
                            },
                            [
                              _c("v-tab", { key: "all-players" }, [
                                _vm._v("PEOPLE "),
                              ]),
                              _c("v-tab", { key: "recommended" }, [
                                _vm._v("RECOMMENDED"),
                              ]),
                              _c(
                                "v-tab-item",
                                { key: "all-players" },
                                [
                                  _c("UserSearch", {
                                    staticClass: "h-full",
                                    staticStyle: { "min-height": "200px" },
                                    attrs: {
                                      clientID: _vm.clientID,
                                      selectedUserID: _vm.selectedUserID,
                                    },
                                    on: { onSelect: _vm.onUserSelect },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { key: "recommended" },
                                [
                                  _c("Recommended", {
                                    on: { onSelect: _vm.onUserSelect },
                                  }),
                                  _c("VideoGroups"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.showChatBody
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            right: "20px",
                            bottom: "5px",
                          },
                        },
                        [
                          _c("LobbyChatWrap", {
                            attrs: { showChatBody: _vm.showChatBody },
                            on: {
                              closeChat: function ($event) {
                                _vm.showChatBody = false
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "lobby-page__viewing py-3", attrs: { md6: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "relative h-full rtb-d-flex rtb-flex-col" },
                    [
                      !_vm.isTwilioLobbyStreamFullscreen
                        ? [
                            _vm.isTwilioLobbyStream
                              ? _c("LiveChatBroadcastCard", {
                                  on: {
                                    show: function ($event) {
                                      _vm.showLobbyScreen = true
                                    },
                                  },
                                })
                              : _vm.isVideo(_vm.selectedGame)
                              ? _c("GameInfoVideo", {
                                  key: "game-info-" + _vm.selectedGame.id,
                                  attrs: {
                                    videoDrawer: _vm.isGameVideoVisible,
                                    canPlay: _vm.noAnnouncement,
                                  },
                                  on: { onVideoDrawer: _vm.onVideoDrawer },
                                })
                              : _vm.selectedGame && !!_vm.selectedGame.streamUrl
                              ? _c("GameInfo", {
                                  key: "game-info-" + _vm.selectedGame.id,
                                  attrs: {
                                    selectedUserID: _vm.selectedUserID,
                                    isPlayedByUser: _vm.isRoomVisitedByUser(
                                      _vm.selectedGame
                                    ),
                                    isUnlockedByUser: _vm.isRoomUnlockedByUser(
                                      _vm.selectedGame
                                    ),
                                    videoDrawer: _vm.isGameVideoVisible,
                                    canPlay: _vm.noAnnouncement,
                                  },
                                  on: {
                                    onVideoDrawer: _vm.onVideoDrawer,
                                    onJoinGame: _vm.onJoinGame,
                                    onLeaveGame: _vm.onLeaveGame,
                                    onEnterGame: _vm.onEnterGame,
                                    onBookGame: _vm.onBookGame,
                                    onSelect: _vm.onUserSelect,
                                  },
                                })
                              : _vm.isStandard(_vm.selectedGame)
                              ? _c("GameInfo", {
                                  key: "game-info-" + _vm.selectedGame.id,
                                  attrs: {
                                    selectedUserID: _vm.selectedUserID,
                                    isPlayedByUser: _vm.isRoomVisitedByUser(
                                      _vm.selectedGame
                                    ),
                                    isUnlockedByUser: _vm.isRoomUnlockedByUser(
                                      _vm.selectedGame
                                    ),
                                    videoDrawer: _vm.isGameVideoVisible,
                                    canPlay: _vm.noAnnouncement,
                                  },
                                  on: {
                                    onJoinGame: _vm.onJoinGame,
                                    onLeaveGame: _vm.onLeaveGame,
                                    onEnterGame: _vm.onEnterGame,
                                    onBookGame: _vm.onBookGame,
                                    onSelect: _vm.onUserSelect,
                                  },
                                })
                              : _vm.isImage(_vm.selectedGame)
                              ? _c("GameImage", {
                                  attrs: { game: _vm.selectedGame },
                                })
                              : _vm.isScoreboard(_vm.selectedGame)
                              ? _c("LobbyGameScoreboard", {
                                  staticClass: "rtb-d-flex flex-column",
                                  attrs: {
                                    game: _vm.selectedGame,
                                    "can-play": _vm.noAnnouncement,
                                    games: _vm.games,
                                  },
                                  on: {
                                    onSelect: _vm.onUserSelect,
                                    onCloseStandings: _vm.onCloseStandings,
                                  },
                                })
                              : _c("GamePlaceholder"),
                          ]
                        : _vm._e(),
                      _vm.isFlashCards(_vm._getGameByID(_vm.selectedFlashcard))
                        ? _c("GameIceBreakers", {
                            key: "flash-card-game-" + _vm.selectedFlashcard.id,
                            attrs: {
                              selectedUserID: _vm.selectedUserID,
                              game: _vm._getGameByID(_vm.selectedFlashcard),
                            },
                            on: { onCloseStandings: _vm.toggleFlashCard },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "bottom-lobby-toolbar", attrs: { row: "" } },
                    [
                      _c(
                        "RtbControlButton",
                        {
                          staticClass: "pa-2 lobby-toolbar-button",
                          class: {
                            "lobby-toolbar-button-selected":
                              _vm.showChatBody &&
                              !_vm.isTwilioLobbyStreamFullscreen,
                            "lobby-toolbar-button__on-popup":
                              _vm.isTwilioLobbyStreamFullscreen,
                            "lobby-toolbar-button__on-popup--active":
                              _vm.isTwilioLobbyStreamFullscreen &&
                              _vm.showChatBody,
                          },
                          attrs: {
                            dark: "",
                            variant: _vm.isTwilioLobbyStreamFullscreen
                              ? "outline"
                              : undefined,
                          },
                          on: {
                            click: function ($event) {
                              _vm.showChatBody = !_vm.showChatBody
                            },
                          },
                        },
                        [
                          _c("SvgIcon", {
                            attrs: {
                              name: "chatcircles",
                              width: "24",
                              height: "24",
                            },
                          }),
                          !_vm.showChatBody
                            ? _c("LobbyChatWrap", {
                                attrs: { showChatHead: true },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ml-2 hidden-md-and-down rtb-truncate",
                                            },
                                            [_vm._v("Lobby Chat")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2426051713
                                ),
                              })
                            : _c(
                                "span",
                                {
                                  staticClass:
                                    "ml-2 hidden-md-and-down rtb-truncate",
                                },
                                [_vm._v("Lobby Chat ")]
                              ),
                        ],
                        1
                      ),
                      _vm.hasStandings
                        ? _c(
                            "v-btn",
                            {
                              staticClass:
                                "lobby-toolbar-button lobby-toolbar-button--top-triangle",
                              class: {
                                "lobby-toolbar-button-selected":
                                  _vm.isScoreboard(_vm.selectedGame),
                              },
                              attrs: {
                                "pa-2": "",
                                dark: "",
                                disabled: _vm.isStreamingOn,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectStandings()
                                },
                              },
                            },
                            [
                              _c("SvgIcon", {
                                attrs: {
                                  name: "lobby-trophy",
                                  width: "24",
                                  height: "24",
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "ml-2 hidden-md-and-down" },
                                [_vm._v("Standings")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.hasImage
                        ? _c(
                            "v-btn",
                            {
                              staticClass:
                                "lobby-toolbar-button lobby-toolbar-button--top-triangle",
                              class: {
                                "lobby-toolbar-button-selected": _vm.isImage(
                                  _vm.selectedGame
                                ),
                              },
                              attrs: {
                                "pa-2": "",
                                grow: "",
                                dark: "",
                                disabled: _vm.isStreamingOn,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectImage()
                                },
                              },
                            },
                            [
                              _c("SvgIcon", {
                                attrs: {
                                  name: "lobby-map",
                                  width: "24",
                                  height: "24",
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "ml-2 hidden-md-and-down" },
                                [_vm._v(_vm._s(_vm.imageName))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.hasFlashCard
                        ? _c(
                            "v-btn",
                            {
                              staticClass:
                                "lobby-toolbar-button lobby-toolbar-button--top-triangle lobby-toolbar-button--triangle-light",
                              class: {
                                "lobby-toolbar-button-selected":
                                  _vm.isFlashCards(
                                    _vm._getGameByID(_vm.selectedFlashcard)
                                  ),
                              },
                              staticStyle: { "z-index": "110" },
                              attrs: {
                                "pa-2": "",
                                grow: "",
                                dark: "",
                                disabled: _vm.isStreamingOn,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleFlashCard()
                                },
                                dblclick: function ($event) {
                                  return _vm.editFlashCard()
                                },
                              },
                            },
                            [
                              _c("SvgIcon", {
                                attrs: {
                                  name: "camera-solid",
                                  width: "24",
                                  height: "24",
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "ml-2 hidden-md-and-down" },
                                [_vm._v("Photo Booth")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.hasFlashCard
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "lobby-toolbar-button",
                              class: {
                                "lobby-toolbar-button-selected":
                                  _vm.showActivityFeed,
                              },
                              staticStyle: { "z-index": "110" },
                              attrs: { "pa-2": "", dark: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleActivityFeed()
                                },
                              },
                            },
                            [
                              _c("SvgIcon", {
                                attrs: {
                                  name: "photos",
                                  width: "24",
                                  height: "24",
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "ml-2 hidden-md-and-down" },
                                [_vm._v("Photos")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      !!_vm.client.returnUrl
                        ? _c(
                            "v-btn",
                            {
                              staticClass:
                                "lobby-toolbar-button lobby-toolbar-button--top-triangle shrink",
                              attrs: { dark: "", disabled: _vm.isStreamingOn },
                              on: {
                                click: function ($event) {
                                  return _vm.goToMeeting()
                                },
                              },
                            },
                            [
                              _c("SvgIcon", {
                                attrs: {
                                  name: "exit",
                                  width: "19",
                                  height: "19",
                                },
                              }),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "ml-2 hidden-md-and-down rtb-truncate",
                                  attrs: {
                                    href: _vm.client.returnUrl,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.returnUrlText) + " ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-flex",
                {
                  staticClass: "lobby-page__rooms",
                  attrs: { lg3: "", md3: "" },
                },
                [
                  _c("LobbyTitle", { staticStyle: { "margin-left": "11px" } }, [
                    _vm._v("ROOMS"),
                  ]),
                  _vm.isHost
                    ? _c(
                        "v-flex",
                        {
                          staticStyle: {
                            margin: "5px 0",
                            width: "100%",
                            "max-height": "50px",
                            top: "5px",
                          },
                          attrs: { "d-flex": "" },
                        },
                        [_c("RoundMover")],
                        1
                      )
                    : _vm._e(),
                  _c("LobbyRooms"),
                  _vm.showActivityFeed
                    ? _c("ActivityFeed", {
                        staticClass: "rtb-d-flex flex-column",
                        attrs: { "can-play": _vm.noAnnouncement },
                        on: { close: _vm.onCloseStandings },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticClass: "overlay",
                  style: {
                    visibility: _vm.selectedUserID ? "visible" : "hidden",
                  },
                  on: {
                    mousemove: function ($event) {
                      if ($event.target !== $event.currentTarget) {
                        return null
                      }
                      return _vm.onMouseLeave.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "popup" } },
                    [
                      _vm.isProfileVisible
                        ? _c("Profile", {
                            key: "user-profile-" + _vm.selectedUserID,
                            staticClass: "profile-popup",
                            class: _vm.popupClass,
                            style: { left: _vm.x + "px", top: _vm.y + "px" },
                            attrs: {
                              userID: _vm.selectedUserID,
                              clientID: _vm.clientID,
                            },
                            on: {
                              onInvite: _vm.onInvite,
                              onJoin: _vm.onJoin,
                              onGameClick: _vm.selectGameCard,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("RightToolbar", {
        attrs: { fixed: "", isLobby: true },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function () {
                return [_vm._v(" " + _vm._s(_vm.client.name) + " ")]
              },
              proxy: true,
            },
            _vm.isHost && _vm.client.escapeRoom
              ? {
                  key: "lobby",
                  fn: function (ref) {
                    var hover = ref.hover
                    var currentMenu = ref.currentMenu
                    var setCurrentMenu = ref.setCurrentMenu
                    return [
                      _c("RequestedGames", {
                        attrs: {
                          active: hover,
                          value: currentMenu,
                          games: _vm.sortedGames,
                        },
                        on: { input: setCurrentMenu, select: _vm.onJoinGame },
                      }),
                    ]
                  },
                }
              : null,
            {
              key: "bottom",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "rtb-right-toolbar__list-item mt-auto" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rtb-right-toolbar__list-item-cnt",
                          on: {
                            click: function ($event) {
                              return _vm.$navigator.navigateTo("/logout")
                            },
                          },
                        },
                        [
                          _c("SvgIcon", {
                            staticClass: "rtb-right-toolbar__list-item-icon",
                            attrs: {
                              name: "signout_24px",
                              width: "20",
                              height: "20",
                              color: "danger",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "rtb-right-toolbar__list-item-title",
                            },
                            [
                              _c("UserName", {
                                attrs: {
                                  firstname: _vm.user.firstname,
                                  lastname: _vm.user.lastname,
                                },
                              }),
                              _vm._v(" (Logout) "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _vm.isHost
        ? _c(
            "v-dialog",
            {
              staticClass: "edit-event-dialog",
              attrs: { "max-width": "80%", "hide-overlay": "" },
              model: {
                value: _vm.showEditEvent,
                callback: function ($$v) {
                  _vm.showEditEvent = $$v
                },
                expression: "showEditEvent",
              },
            },
            [
              _vm.showEditEvent
                ? _c("SessionSettings", {
                    staticClass: "lobby-page__settings",
                    attrs: { clientID: _vm.clientID, client: _vm.client },
                    on: { auditGames: _vm.auditGamesAction },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          staticClass: "edit-event-dialog",
          attrs: { "max-width": "70%", "hide-overlay": "" },
          model: {
            value: _vm.showAuditor,
            callback: function ($$v) {
              _vm.showAuditor = $$v
            },
            expression: "showAuditor",
          },
        },
        [
          _vm.showAuditor
            ? _c("GamesAuditor", {
                attrs: { games: _vm.sortedGames },
                on: {
                  close: function ($event) {
                    _vm.showAuditor = false
                  },
                  onEnterGame: _vm.onJoinGame,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.isHost
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "no-click-animation": "", width: "60%" },
              model: {
                value: _vm.editGameDialog,
                callback: function ($$v) {
                  _vm.editGameDialog = $$v
                },
                expression: "editGameDialog",
              },
            },
            [
              _c("GameSettings", {
                attrs: { game: _vm.selectedGame },
                on: { close: _vm.closeEdit },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "Popup",
        {
          attrs: {
            header: _vm.recordAnnouncementHeader,
            isOpen: _vm.showRecordingDialog,
          },
          on: {
            onClose: function ($event) {
              _vm.showRecordingDialog = false
            },
          },
        },
        [
          _vm.showRecordingDialog
            ? _c("RecordVideo", {
                attrs: {
                  clientID: _vm.clientID,
                  gameID: _vm.recordAnnouncementGameID,
                  videoTarget: _vm.recordAnnouncementTarget,
                },
                on: { onPosted: _vm.clientAnnouncementPosted },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.hasLobbyScreen
        ? _c(
            "LobbyScreen",
            {
              staticClass: "lobby-page__lobby-screen",
              attrs: {
                title: _vm.lobbyScreenTitle,
                description: _vm.lobbyScreenDescription,
              },
              on: {
                close: function ($event) {
                  return _vm.onLobbyScreenClose()
                },
              },
            },
            [
              _vm.isMediaBannerOpen
                ? _c("MediaBannerWithHubSpotForm", {
                    staticClass: "lobby-page__lobby-screen-ad",
                  })
                : _vm.isTwilioLobbyStreamFullscreen
                ? _c("LiveChatBroadcast", {
                    staticClass: "lobby-page__lobby-screen-broadcast",
                  })
                : _vm._e(),
            ],
            1
          )
        : !_vm.isTwilioLobbyStream
        ? _c("VideoDrawer", {
            attrs: {
              game: _vm.selectedGame,
              canPlay: _vm.noAnnouncement,
              isAnnoncementVisible: _vm.isAnnoncementVisible,
              isGameVideoVisible: _vm.isGameVideoVisible,
              announcementURL: _vm.announcementClientURL,
              playAnnouncement: _vm.playAnnouncement,
              announcementFlipped: _vm.announcementClientURL_flipped,
            },
            on: {
              onCloseDrawer: _vm.closeDrawer,
              onLoadVideo: _vm.onLoadVideo,
              playAnnoncementVideo: _vm.playAnnoncementVideo,
            },
          })
        : _vm._e(),
      _c("VenmoModal"),
      _c("GameStartSound"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }