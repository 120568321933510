import { render, staticRenderFns } from "./GameYouTube.vue?vue&type=template&id=353d0a18&"
import script from "./GameYouTube.vue?vue&type=script&lang=ts&"
export * from "./GameYouTube.vue?vue&type=script&lang=ts&"
import style0 from "./GameYouTube.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/Users/dkurbatov/Documents/TGG/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('353d0a18')) {
      api.createRecord('353d0a18', component.options)
    } else {
      api.reload('353d0a18', component.options)
    }
    module.hot.accept("./GameYouTube.vue?vue&type=template&id=353d0a18&", function () {
      api.rerender('353d0a18', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Lobby/GameYouTube.vue"
export default component.exports