<template>
  <div class="twilio-stream" />
</template>

<script>
import Vue from "vue"
import { Player } from "@twilio/player-sdk"

import { STREAM_TYPE_TO_SERVICE } from "@/services/stream.service/constants"
import { StreamType } from "@/services/stream.service/enums"

const { host, protocol } = window.location
const playerWasmAssetsPath = `${protocol}//${host}/js`
import { stream } from "@/modules/smart-media"
export default Vue.extend({
  name: "TwilioStream",
  props: {
    id: {
      type: String,
      required: true
    },
    controls: {
      type: Boolean,
      default: true
    },
    type: {
      validator: value => Object.values(StreamType).includes(value),
      required: true
    },
    muted: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this._service = STREAM_TYPE_TO_SERVICE[this.type]
  },
  data() {
    return {
      player: null,
      video: null,
      interval: null
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
    this.video && this.$el?.removeChild(this.video)
    this.video && this.video.removeEventListener("canplay", this.onCanPlay)
    this.player?.disconnect()
  },
  mounted() {
    this.$nextTick(() => {
      this.initialize()
      this.interval = setInterval(this.initialize, 10000)
    })
  },
  methods: {
    async initialize() {
      this.video && this.video.removeEventListener("canplay", this.onCanPlay)
      this.video && this.$el?.removeChild(this.video)
      this.player?.disconnect()

      const data = await this._service.getToken(this.id)

      const token = data?.data?.token
      this.player = await Player.connect(token, { playerWasmAssetsPath })
      this.player.play()
      this.video = this.player.videoElement
      this.video.className = "twilio-stream__video"
      this.video.muted = this.muted
      this.video.addEventListener("canplay", this.onCanPlay)
      if (this.controls) this.video.setAttribute("controls", "controls")
      this.$el.appendChild(this.video)
      stream(this.video, 1, false)
    },
    onCanPlay() {
      clearInterval(this.interval)
    }
  }
})
</script>

<style lang="scss">
.twilio-stream {
  width: 100%;
  height: 100%;
  position: relative;
  &__video {
    position: absolute;
    transform: scaleX(1) !important;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
