<template>
  <div class="pseudo-tournament-sidebar-header">
    <!-- <v-flex style="text-align: center" align-center>
      <v-btn
        v-if="round < rounds.length && isHost"
        class="tournament-btn rtb-background-secondary rtb-color-secondary"
        @click="nextRound"
      >
        <span class="contrast-text-color">
          Advance to Round {{ round + 1 }}
        </span>
      </v-btn>
    </v-flex> -->
    <div class="sidebar-divider"></div>

    <v-layout row wrap class="pseudo-tournament-sidebar-header__rounds">
      <v-flex
        shrink
        d-flex
        align-center
        key="tournament-header-round-all"
        class="pseudo-tournament-sidebar-header__round"
        :class="{ '--active': !round }"
        @click="onSelect(null)"
      >
        <svg-icon v-if="!round" size="small" name="caret-right" />
        ALL
      </v-flex>
      <v-flex
        shrink
        d-flex
        align-center
        v-for="n in rounds"
        :key="`tournament-header-round-${n}`"
        class="pseudo-tournament-sidebar-header__round"
        :class="{ '--active': n == parseInt(round) }"
        @click="onSelect(n)"
      >
        <svg-icon v-if="n == parseInt(round)" size="small" name="caret-right" />
        Round {{ n }}
      </v-flex>
      <v-flex shrink d-flex align-center key="tournament-header-checkbox">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-checkbox
              class="pseudo-tournament-sidebar-header__checkbox"
              v-on="on"
              v-model="showStagingGames"
              dark
              dense
              hide-details
            />
          </template>
          <span class="top">
            {{ showStagingGames ? "Hide Staging Games" : "Show Staging Games" }}
          </span>
        </v-tooltip>
      </v-flex>
      <v-flex shrink d-flex align-center>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <SvgIcon
              name="replay"
              width="20"
              height="20"
              v-on="on"
              @click="onResetRoundNumber"
              class="pseudo-tournament-sidebar-header__reset-icon"
            />
          </template>
          <span class="top">Reset Current Round Number</span>
        </v-tooltip>
      </v-flex>
      <v-flex v-if="round < rounds.length && isHost" shrink d-flex align-center>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <SvgIcon
              name="play"
              width="20"
              height="20"
              v-on="on"
              @click="nextRound"
              class="pseudo-tournament-sidebar-header__advance"
            />
          </template>
          <span class="top"> Advance to Round {{ round + 1 }}</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <div class="sidebar-divider"></div>
  </div>
</template>

<script>
import Vue from "vue"
import { mapActions, mapGetters } from "vuex"
import { db } from "@/firebase"
import { Filters } from "@/store/PreGame"
import ActionTypes from "@/store/action-types"

export default Vue.extend({
  name: "RoundMover",
  watch: {
    "client.roundNum": {
      immediate: true,
      handler(round) {
        this.round = parseInt(round)
      }
    }
  },
  computed: {
    ...mapGetters("pregame", ["filters", "games"]),
    ...mapGetters("auth", ["isHost", "clientID", "client"]),
    activeGames() {
      return this.games.filter(game => !game.deletedTimestamp)
    },
    allGames() {
      return this.activeGames.filter(
        game =>
          !game.gameType ||
          game.gameType === "Standard" ||
          game.gameType === "Tournament"
      )
    },
    round: {
      get() {
        return parseInt(this.filters[Filters.Round]) || 0
      },
      set(value) {
        if (value) {
          this.$store.dispatch(ActionTypes.ADD_TO_FILTERS, {
            key: Filters.Round,
            value: value
          })
        } else {
          this.$store.dispatch(ActionTypes.REMOVE_FROM_FILTERS, {
            key: Filters.Round
          })
        }
      }
    },
    showStagingGames: {
      get() {
        return !this.filters[Filters.Staging]
      },
      set(value) {
        if (value) {
          this.$store.dispatch(ActionTypes.REMOVE_FROM_FILTERS, {
            key: Filters.Staging
          })
        } else {
          this.$store.dispatch(ActionTypes.ADD_TO_FILTERS, {
            key: Filters.Staging
          })
        }
      }
    },
    rounds() {
      return Object.keys(
        this.activeGames.reduce((acc, val) => {
          const int = parseInt(val.round)
          if (int > 0) {
            acc[int] = true
          }
          return acc
        }, {})
      ).sort((a, b) => a - b)
    }
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    onResetRoundNumber() {
      db.auxiliary().ref(`clients/${this.clientID}/roundNum`).set(null)
    },
    nextRound() {
      const nextRound = this.round + 1
      const currentRoundGames = this.activeGames.filter(
        game => game.round == this.round
      )
      const nextRoundGames = this.activeGames.filter(
        game => game.round == this.round + 1
      )
      const nextRoundGameMaxPlayers = nextRoundGames[0].players
      if (this.client.escapeRoom) {
        var accept = prompt(
          "Do you want to move onto round " +
            nextRound +
            "? This will stage current games and unstage next round games. Enter max number of players in each of the next " +
            nextRoundGames.length +
            " room.",
          nextRoundGameMaxPlayers
        )
      } else {
        var accept = confirm(
          "Do you want to move onto round " +
            nextRound +
            "? This will stage current games and unstage next round games."
        )
      }
      console.log("NEXT ROUND", nextRoundGames)
      console.log("CURRENT ROUND", currentRoundGames)

      if (accept) {
        var maxPlayers = 25
        if (this.client.escapeRoom) {
          maxPlayers = accept
        }
        let obj = {}
        for (let i in currentRoundGames) {
          obj.theKey = currentRoundGames[i].id
          obj.ondeck = true
          this.updateGameAny(obj)
        }
        for (let i in nextRoundGames) {
          obj.theKey = nextRoundGames[i].id
          obj.ondeck = false
          obj.players = maxPlayers
          this.updateGameAny(obj)
        }
        this.round = this.round + 1
        var client = {}

        client.id = this.clientID
        client.roundNum = this.round
        this.$services
          .get("client")
          .then(service => service.updateClient(client))
      }
    },
    onSelect(round) {
      this.round = round || 0
    }
  }
})
</script>

<style lang="scss">
.pseudo-tournament-sidebar-header {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 15px 0 24px;

  &__reset-icon {
    color: red;
    position: relative;
    top: -2px;
    margin-left: 10px;
    transition: transform 0.3s;
    transform-origin: 50% 63%;
    &:hover {
      color: #fff;
    }
  }
  &__advance {
    color: #8680ff;
    position: relative;
    top: -2px;
    margin-left: 10px;
    &:hover {
      color: #fff;
    }
  }

  &__checkbox {
    margin: auto;
    padding: 0;
    .v-input--selection-controls__input {
      margin: 0;
    }
  }

  &__round {
    user-select: none;
    &:not(.--active) {
      cursor: pointer;
    }
    margin: 0 4px;
    &.--active {
      color: $color-white;

      .svg-icon {
        color: $secondary_accent_color;
      }
    }
  }

  &__rounds {
    display: flex;
    justify-content: center;
    padding: 8px 0;
    list-style: none;
    font-size: 14px;
    color: #686868;
    text-transform: uppercase;

    button {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .svg-icon--sidebar-divider {
    height: 2px;
    width: 350px;
  }
  // selector
  $STRIP_OPACITY: 0.4;
  $GRADIENT_START: 20%;
  .sidebar-divider {
    position: relative;
    width: 100%;
    height: 1px;

    &::after {
      position: absolute;
      content: "";
      height: 1px;
      width: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, $STRIP_OPACITY) $GRADIENT_START,
        rgba(255, 255, 255, $STRIP_OPACITY) 100% - $GRADIENT_START,
        rgba(255, 255, 255, 0) 100%
      );

      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
