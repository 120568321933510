var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.truncatedUsers.length > 0,
          expression: "truncatedUsers.length > 0",
        },
      ],
      staticClass: "video-group-players rtb-d-flex items-center",
    },
    [
      _c(
        "div",
        { staticClass: "video-group-players__container rtb-d-flex" },
        [
          _vm._l(_vm.truncatedUsers, function (player, index) {
            return _c(
              "div",
              {
                key: player.id,
                staticClass:
                  "rtb-d-flex flex-column items-center video-group-players__item",
                style: {
                  "z-index": _vm.truncatedUsers.length - index,
                  transform:
                    index > 0
                      ? "translateX(" + _vm.getOffset(index) + ")"
                      : undefined,
                },
              },
              [
                _c("LobbyPlayer", {
                  staticClass: "video-group-players__player",
                  attrs: {
                    player: player,
                    userID: player.id,
                    "with-details": false,
                  },
                  on: { onMouseOver: _vm.onMouseOver },
                  nativeOn: {
                    mouseleave: function ($event) {
                      return _vm.onMouseLeave.apply(null, arguments)
                    },
                    click: [
                      function ($event) {
                        if (!$event.altKey) {
                          return null
                        }
                        return _vm.edit(player)
                      },
                      function ($event) {
                        if (!$event.shiftKey) {
                          return null
                        }
                        return _vm.edit(player)
                      },
                    ],
                  },
                }),
                _c(
                  "span",
                  {
                    staticClass:
                      "video-group-players__player-name mt-1 rtb-truncate",
                  },
                  [
                    _vm._o(
                      _c("UserName", {
                        attrs: { firstname: player.firstname },
                      }),
                      0,
                      player.id
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm.hasMoreThanMaxUsers
            ? _c(
                "div",
                {
                  staticClass: "video-group-players__truncated-count",
                  style: {
                    transform:
                      "translateX(" +
                      _vm.getOffset(_vm.truncatedUsers.length) +
                      ")",
                  },
                },
                [_vm._v(" +" + _vm._s(_vm.truncatedUsersLength) + " ")]
              )
            : _vm._e(),
        ],
        2
      ),
      !_vm.isInRoom && !_vm.hasMoreThanMaxInRoom
        ? _c(
            "a",
            {
              staticClass: "video-group-players__button ml-3 mb-4 no-underline",
              style: {
                transform:
                  _vm.truncatedUsers.length > 0
                    ? "translateX(" +
                      _vm.getOffset(
                        _vm.truncatedUsers.length -
                          (_vm.hasMoreThanMaxUsers ? 0 : 1)
                      ) +
                      ")"
                    : undefined,
              },
              attrs: { href: "#", role: "button" },
              on: { click: _vm.onJoin },
            },
            [_vm._v(" Join ")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }