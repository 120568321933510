<template>
  <v-flex grow class="room-detail activity-feed-card">
    <div
      class="room-detail-image"
      style="
        background-image: url('https://assets.remoteteambuilding.com/gamephotos/c51a01df-85ae-4215-81fe-46b4897d7466.png');
      "
    ></div>
    <div class="room-detail-info rtb-d-flex flex-column h-0 flex-grow">
      <v-layout row shrink>
        <v-flex xs3 shrink>
          <div class="main-title">PHOTOS</div>
        </v-flex>
        <v-spacer />
        <v-flex shrink>
          <rtb-button
            class="mr-1 text-capitalize mt-2"
            @click="download"
            :disabled="!plays.length || working"
          >
            <template>
              Download All
              <v-progress-circular indeterminate v-if="working" />
            </template>
          </rtb-button>
        </v-flex>
        <v-flex xs1 shrink v-if="isHost">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn title="Close" icon dark v-on="on" @click="remove">
                <SvgIcon name="trash-regular" width="20" height="20" />
              </v-btn>
            </template>
            <span>Clear (Hosts Only)</span>
          </v-tooltip>
        </v-flex>
        <v-flex xs1 shrink>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn title="Close" icon dark v-on="on" @click="$emit('close')">
                <SvgIcon name="times-regular" width="20" height="20" />
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
      <div class="activity-feed h-0 flex-grow -ml-1">
        <DynamicScroller :items="chunks" :min-item-size="54" class="h-full rtl">
          <template v-slot="{ item: chunk, index, active }">
            <DynamicScrollerItem
              :item="chunk"
              :active="active"
              :data-index="index"
            >
              <v-layout row wrap class="ltr">
                <v-flex xs4 v-for="item in chunk.items" :key="item.id">
                  <v-img
                    :src="item.correct.image"
                    aspect-ratio="1"
                    class="feed-image"
                  >
                    <template #placeholder>
                      <v-layout fill-height align-center justify-center ma-0>
                        <v-progress-circular indeterminate color="primary" />
                      </v-layout>
                    </template>
                  </v-img>
                  <div class="layout player-lobby-details justify-center row">
                    <div class="player-name" style="font-size: 14px">
                      {{ item.firstName }}
                    </div>
                  </div>
                  <div class="text">{{ item.message }}</div>
                </v-flex>
              </v-layout>
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
      </div>
    </div>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex"
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller"
import { chunk, throttle } from "lodash"
import { RtbButton } from "@/components/ui"
import ApiService from "@/services/axios.service"
import Mission from "@shared/enums/Mission"
import { db } from "@/firebase"
import Answer from "@shared/Answer"

const ITEMS_IN_A_ROW = 3

export default {
  name: "ActivityFeed",
  components: {
    DynamicScroller,
    DynamicScrollerItem,
    RtbButton
  },
  data() {
    return {
      plays: [],
      working: false
    }
  },
  async created() {
    const ref = db
      .auxiliary()
      .ref(`client/${this.clientID}/play`)
      .orderByChild("behavior")

    const snapshots = await Promise.all([
      ref.equalTo(Mission.TakePhoto).limitToLast(3000).once("value"),
      ref.equalTo(Mission.PhotoBooth).limitToLast(3000).once("value")
    ])

    this.plays = snapshots
      .map(snapshot => snapshot.val())
      .reduce((acc, val) => acc.concat(Answer.normalize(val || {})), [])
      .filter(({ correct }) => correct && correct.image)
  },
  computed: {
    ...mapGetters("auth", ["clientID", "isHost"]),
    chunks() {
      return chunk(this.plays, ITEMS_IN_A_ROW).map(chunk => ({
        id: chunk[0]?.id,
        items: chunk
      }))
    }
  },
  methods: {
    remove() {
      if (!confirm("Are you sure you want to delete all the photos?")) return
      return db.auxiliary().ref(`client/${this.clientID}/play`).remove()
    },
    onSnapshot: throttle(function (snapshot) {
      this.data = snapshot?.val() || {}
    }, 1000),
    async download() {
      this.working = true
      try {
        const { data } = await ApiService({
          method: "get",
          url: `session/${this.clientID}/archive`
        })
        const a = document.createElement("a")
        a.setAttribute("href", data.url)
        a.setAttribute("target", "_blank")
        a.click()
      } catch (e) {
        console.log(e)
      }
      this.working = false
    }
  }
}
</script>

<style lang="scss">
.activity-feed-card {
  position: absolute;
  top: 20px;
  left: 0;
  right: 4px;
  bottom: 20px;
  z-index: 110;
  height: auto;

  background-color: #586475 !important;

  &::after {
    content: "";
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-right: 15px solid #586475;
    position: absolute;
    bottom: 16px;
    left: -15px;
    z-index: 1000;
  }
}
.activity-feed {
  line-height: 1;
  .feed-image {
    margin: 8px;
  }
  .player-name {
    color: #bbb;
  }
}
</style>
