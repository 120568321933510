<template>
  <v-flex class="recomended-players">
    <transition name="fade-in" mode="out-in">
      <UserList
        v-if="recommendedUsers.length > 0"
        :users="recommendedUsers"
        @onSelect="onSelectUser"
      />

      <div class="no-recommended" v-else>No one is available to video chat</div>
    </transition>
  </v-flex>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import ClientUsersConsumer from "@/modules/users/components/ClientUsersConsumer"
import ClientGamesConsumer from "@/consumers/ClientGamesConsumer"
import UserList from "@/components/Lobby/UserList.vue"
import SvgIcon from "@/components/base/SvgIcon.vue"
import User from "@shared/User"
import { Math as Numbers, Role } from "@/helpers"
import LiveChatMixin from "@/mixins/LiveChat"

export default ClientUsersConsumer.extend(
  ClientGamesConsumer.extend({
    name: "Recommended",
    mixins: [LiveChatMixin],
    components: {
      UserList,
      SvgIcon
    },
    props: {
      flashGames: {
        type: Array,
        default: () => []
      },
      selectedGameID: {
        type: String
      }
    },
    data() {
      return {
        show: false,
        videoTrack: null,
        optionalUsersIDs: [],
        timer: null,
        working: false
      }
    },
    async beforeDestroy() {
      clearInterval(this.timer)
      // Remove all stored subscriptions
      await this.removeAllSubscriptions()
    },
    watch: {
      isUserOnline: {
        handler(value) {
          if (value) {
            clearTimeout(this.timer)
            this.optionalUsersIDs = []
          } else {
            this.working = false
            this.timer = setInterval(() => {
              this.checkUsersGame()
              this.addRandomUser()
            }, 5000)
          }
        },
        immediate: true
      },
      optionalUsersIDs(ids) {
        if (ids) {
          this.refreshSubscriptions({
            usersIDs: ids,
            /**
             * This function will be called when one of random users joins to another room
             * @param roomID
             * @param userID
             */
            callback: ({ roomID, userID }) => {
              if (roomID !== this.roomID) {
                const index = this.optionalUsersIDs.indexOf(userID)
                if (index >= 0) {
                  this.optionalUsersIDs.splice(index, 1)
                }
              }
            }
          })
        }
      }
    },
    computed: {
      ...mapGetters("auth", ["user", "access", "client", "isHost"]),
      ...mapGetters("twilio", { twilioUsers: "users" }),
      ...mapGetters("livechat", ["max", "room", "roomID"]),
      recommendedUsers() {
        return this.optionalUsers.slice(0, 3).reverse()
      },
      unassignedOnlineUsers() {
        return this.onlineUsers.filter(user => !user.gameID)
      },
      roomUsers() {
        const onlineUsers = this.onlineUsers
        const roomUsers = this.room?.users
        return this.roomUsersIDs
          .map(userID => ({
            ...onlineUsers.find(user => user.id === userID),
            isChatMuted: !!roomUsers?.[userID]?.muted
          }))
          .filter(user => user)
      },
      optionalUsers() {
        const onlineUsers = this.onlineUsers
        return this.optionalUsersIDs
          .map(userID => onlineUsers.find(user => user.id === userID))
          .filter(
            user =>
              user &&
              !this.$_LiveChatMixin_isUserLocked(user) &&
              !User.isOnboarding(user)
          )
      },
      roomUsersIDs() {
        if (!this.room) return []
        if (!this.room.users) return []
        return Object.values(this.room.users)
          .filter(user => user)
          .filter(({ status, userID }) => {
            return status === "active" && userID !== this.user.id && userID
          })
          .map(({ userID }) => userID)
      },
      isRoomLocked() {
        return this.room ? !!this.room.locked : false
      },
      users() {
        const output = []
        const { isUserOnline, working, optionalUsers, roomUsers } = this

        const sortedRoomUsers = roomUsers.sort((a, b) => {
          if (a.role === Role.Host) return -1
          else if (b.role === Role.Host) return 1
          return 0
        })

        for (let i = 0; i < this.max - 1; i++) {
          if (!isUserOnline && optionalUsers.length && !working) {
            if (optionalUsers[i]) {
              output.push({ ...optionalUsers[i], optional: true })
            } else {
              output.push(null)
            }
          } else {
            output.push(sortedRoomUsers[i])
          }
        }
        return roomUsers
      },
      isUserOnline() {
        return this.twilioUsers
          ? !!this.twilioUsers[this.user.id] || this.room
          : false
      }
    },
    methods: {
      ...mapActions("livechat", [
        "isUserBusy",
        "refreshSubscriptions",
        "removeAllSubscriptions"
      ]),
      onSelectUser(obj) {
        this.$emit("onSelect", obj)
      },
      getPendingGamesIDs() {
        return this.games.filter(({ started }) => !started).map(({ id }) => id)
      },
      anonymousUsers() {
        return this.client ? !!this.client.anonymousUsers : false
      },
      filterByAccess(value) {
        const array = Array.isArray(value) ? value : []
        return array.filter(({ role }) => {
          if (!this.anonymousUsers || this.isHost) {
            return true
          } else {
            return role !== Role.Host
          }
        })
      },
      getRandomUsersPool() {
        const onlineUsersGroupedByGameID = this.onlineUsersGroupedByGameID
        const keys = Object.keys(onlineUsersGroupedByGameID)
        const pendingGamesIDs = this.getPendingGamesIDs()
        const pendingKeys = keys.filter(key => pendingGamesIDs.includes(key))
        const assigned = pendingKeys.reduce(
          (acc, val) =>
            acc.concat(this.filterByAccess(onlineUsersGroupedByGameID[val])),
          []
        )
        const unassigned = this.filterByAccess(this.unassignedOnlineUsers)

        let all = []

        if (assigned && assigned.length) {
          all = all.concat(assigned)
        }

        if (unassigned && unassigned.length) {
          all = all.concat(unassigned)
        }

        const optionalUsersIDs = this.optionalUsersIDs

        return all.filter(
          user =>
            !optionalUsersIDs.some(userID => userID === user.id) &&
            user.id !== this.user.id &&
            !User.isOnboarding(user)
        )
      },
      async addRandomUser() {
        const user = await this.findUser()
        if (user) {
          const { id: userID } = user
          this.optionalUsersIDs = [
            userID,
            ...this.optionalUsersIDs.slice(0, this.max)
          ]
        } else {
          console.log("No available players found")
        }
      },
      async findUser() {
        const MAX = 10
        let found = null
        let count = 0
        const array = this.getRandomUsersPool()

        while (!found && array.length && ++count < MAX && !this.isUserOnline) {
          // console.log(`${count} try`)
          const index = Numbers.getRandomInt(0, array.length - 1)
          const user = array[index]
          if (!user) throw new Error("Undefined user")
          if (!user.id) throw new Error("User doesn't have an ID")
          const isUserBusy = await this.isUserBusy({ userID: user.id })
          if (!isUserBusy) found = user
        }

        return found
      },
      checkUsersGame() {
        const optionalUsersIDs = this.optionalUsersIDs
        const games = this.games
        const optionalUsers = this.optionalUsers
        optionalUsers.forEach(user => {
          if (games.find(game => game.id === user.gameID)?.started) {
            const index = optionalUsersIDs.indexOf(user.id)
            this.optionalUsersIDs.splice(index, 1)
          }
        })
      }
    }
  })
)
</script>

<style lang="scss">
.recomended-players {
  margin-top: 10px;
  min-height: 80px;
  display: flex;
  flex-direction: column;
}

.no-recommended {
  color: #9aa1ad;
  margin-left: 30px;
  height: 100%;
  display: flex;
  align-items: center;
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity ease 0.3s;
  opacity: 1;
}
.fade-in-enter {
  opacity: 0;
}
.fade-in-leave-to {
  opacity: 0;
}

.live-chat {
  position: relative;
  .flip-transition-enter-active,
  .flip-transition-leave-active {
    transition: opacity ease 0.3s;
    opacity: 1;
    // transform: rotateX(0deg);
  }
  .flip-transition-enter {
    opacity: 0;
    // transform: rotateY(90deg);
  }
  .flip-transition-leave-to {
    opacity: 0;
    // transform: rotateY(-90deg);
  }
  .room-lock-link,
  .disconnet-link {
    position: relative;
    z-index: 10;
  }
  .raised-hand {
    display: inline;
  }
  .live-chat-container {
    padding-bottom: 15px;
  }
  .live-chat-controls {
    font-size: 10px;
    font-weight: 500;
    margin: 8px;
    .underline {
      text-decoration: underline;
    }
    i {
      margin-right: 3px;
    }
    .disconnet-link {
      cursor: pointer;
      color: $color-wrong;
      i {
        color: $color-wrong;
      }
    }
    .room-lock-link {
      cursor: pointer;
      color: $color-grey-light4;
      i {
        color: $color-grey-light4;
      }
      &:hover {
        color: $color-correct;
        i {
          color: $color-correct;
        }
      }
      &.locked {
        color: $color-correct;
        i {
          color: $color-correct;
        }
        &:hover {
          color: $color-grey-light4;
          i {
            color: $color-grey-light4;
          }
        }
      }
    }
  }
  .icebreaker-title {
    padding-right: 8px;
    margin-right: 8px;
    font-weight: 800;
    font-size: 14px;
    line-height: 13px;
    height: 12px;
    margin-top: 2px;
    text-transform: uppercase;
    white-space: nowrap;
    border-right: solid 1px $color-grey;
  }
  .icebreaker-game {
    user-select: none;
    white-space: nowrap;
    margin-right: 10px;
    cursor: pointer;
    span {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      display: inline-block;
      vertical-align: top;
      line-height: 16px;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      padding-left: 5px;
      transition: all 0.1s ease-out;
      &:hover {
        opacity: 0.6;
      }
    }
    &:last-child {
      margin-right: 0;
    }
    &.selected {
      color: $primary_accent_color;
    }
  }
  .live-chat-player-wrap-new {
    &:not(:first-child) {
      margin-left: 0;
    }
  }
  .live-chat-text {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    color: $color-grey2;
    font-weight: 400;
    margin-right: 10px;
  }

  .live-chat-status-container {
    white-space: nowrap;
  }
  .live-chat-status {
    display: inline-block;
    vertical-align: middle;
    color: $color-wrong;
    font-size: 16px;
    line-height: 22px;
    margin-right: 4px;
    margin-top: -2px;
    margin-bottom: -2px;
    // margin-left: -5px;
    &.on {
      color: $color-correct;
    }
  }
  .audio-element {
    pointer-events: none;
    position: fixed;
    bottom: 0;
    right: 0;
  }
}

@media (max-width: 1300px) {
  .live-chat {
    .live-chat-container {
      padding-bottom: 10px;
    }
    .live-chat-player-wrap-new {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}
</style>
