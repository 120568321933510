<template>
  <v-flex @mouseenter="onMouseOver">
    <RatioContainer class="pre-game-player">
      <div
        class="lobby-player-points-tag"
        v-if="showScore && parseInt(player.score)"
      >
        {{ parseInt(player.score) }}
      </div>
      <div
        class="video-container outline"
        :class="{
          '--with-onboarding-overlay': isOnboarding,
          'video-container--conference': player.conference
        }"
      >
        <v-flex d-flex justify-center class="video-stream-wrap">
          <v-flex d-flex justify-center class="video-steam-placeholder-wrap">
            <img :src="image" />
          </v-flex>
        </v-flex>
        <span v-if="player.vip && isHost" class="pre-game-player__vip-tag">
          VIP
        </span>
      </div>
    </RatioContainer>
  </v-flex>
</template>

<script>
import RatioContainer from "@/components/GroupTeams/Common/RatioContaier.vue"
import { DEFAULT_PROFILE_IMAGE_BIG } from "@/config"
import { mapGetters } from "vuex"
import User from "@shared/User"

export default {
  name: "LobbyPlayer",
  components: {
    RatioContainer
  },
  props: {
    player: {
      type: Object,
      required: true
    },
    imageURL: {
      type: String,
      default: DEFAULT_PROFILE_IMAGE_BIG
    },
    userID: String,
    showScore: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters("auth", ["isHost"]),
    isOnboarding() {
      return User.isOnboarding(this.player)
    },
    image() {
      const image = this.player.image
      if (!image) {
        return DEFAULT_PROFILE_IMAGE_BIG
      }

      return image.includes("http")
        ? image
        : require(`@/assets/avatars/${image}`)
    }
  },
  methods: {
    onMouseOver($event) {
      if (!this.userID) return
      this.$emit("onMouseOver", { $event, userID: this.userID })
    }
  }
}
</script>

<style lang="scss">
.pre-game-player {
  user-select: none;

  .video-stream-wrap {
    position: relative;
    max-height: 100%;
    width: 100%;
  }

  .video-steam-placeholder-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    // placeholder text
    & > div {
      align-self: center;
      font-size: 14px;
      color: $color-disabled;
    }
    // placeholder image
    & > img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  .video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &::after {
      font-size: 8px;
    }

    &--conference {
      border-radius: 10px !important;
    }

    &--conference::before {
      border-radius: 10px !important;
    }
  }

  .player-name {
    position: absolute;
    width: 100%;
    height: 22px;
    padding-top: 3px;
    line-height: 22px;
    font-size: 11px;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }

  .outline {
    border: 2px solid #fff;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }

  .lobby-player-points-tag {
    position: absolute;
    transition: all 0.5s;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: rgba(255, 255, 255, 0.9);
    overflow: hidden;
    padding: 3px 0 0 0;
    left: -3%;
    bottom: 0;
    background-color: $primary-accent-color;
    z-index: 3;
    font-size: 11px;
    font-weight: bold;
    display: grid;
    place-items: center;
  }

  &__vip-tag {
    position: absolute;
    padding-top: 4px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 70%, #000000 100%);
    color: #fff;
  }
}
</style>
