<template>
  <div
    v-show="truncatedUsers.length > 0"
    class="video-group-players rtb-d-flex items-center"
  >
    <div class="video-group-players__container rtb-d-flex">
      <div
        v-for="(player, index) in truncatedUsers"
        :key="player.id"
        class="rtb-d-flex flex-column items-center video-group-players__item"
        :style="{
          'z-index': truncatedUsers.length - index,
          transform: index > 0 ? `translateX(${getOffset(index)})` : undefined
        }"
      >
        <LobbyPlayer
          :player="player"
          :userID="player.id"
          :with-details="false"
          class="video-group-players__player"
          @onMouseOver="onMouseOver"
          @mouseleave.native="onMouseLeave"
          @click.alt.native="edit(player)"
          @click.shift.native="edit(player)"
        />
        <span class="video-group-players__player-name mt-1 rtb-truncate">
          <UserName v-once :firstname="player.firstname" />
        </span>
      </div>
      <div
        v-if="hasMoreThanMaxUsers"
        :style="{
          transform: `translateX(${getOffset(truncatedUsers.length)})`
        }"
        class="video-group-players__truncated-count"
      >
        +{{ truncatedUsersLength }}
      </div>
    </div>
    <a
      v-if="!isInRoom && !hasMoreThanMaxInRoom"
      href="#"
      role="button"
      class="video-group-players__button ml-3 mb-4 no-underline"
      :style="{
        transform:
          truncatedUsers.length > 0
            ? `translateX(${getOffset(
                truncatedUsers.length - (hasMoreThanMaxUsers ? 0 : 1)
              )})`
            : undefined
      }"
      @click="onJoin"
    >
      Join
    </a>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters, mapActions } from "vuex"

import LobbyPlayer from "@/components/Lobby/Player.vue"
import UserName from "@/components/GroupTeams/Common/User/UserName"

const MAX_PLAYERS_COUNT = 5
const MAX_PLAYERS_IN_ROOM = 6
/** See `<style>` section */
const PLAYER_SIZE = 60
const GAP = 8

export default Vue.extend({
  name: "VideoGroupPlayers",
  components: {
    LobbyPlayer,
    UserName
  },
  props: {
    users: {
      type: Array,
      required: true
    },
    room: {
      type: Object
    }
  },
  data() {
    return {
      mouseOverTimeout: null
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "isHost"]),
    isInRoom() {
      const myID = this.user?.id
      return this.users.some(user => user.id === myID)
    },

    truncatedUsers() {
      return this.users.length > MAX_PLAYERS_COUNT
        ? this.users.slice(0, MAX_PLAYERS_COUNT)
        : this.users
    },
    /** @returns {boolean} */
    hasMoreThanMaxUsers() {
      return this.users.length > MAX_PLAYERS_COUNT
    },
    hasMoreThanMaxInRoom() {
      if (this.isHost) return false
      return this.users.length >= MAX_PLAYERS_IN_ROOM
    },
    /** @returns {number} */
    truncatedUsersLength() {
      return Math.max(0, this.users.length - MAX_PLAYERS_COUNT)
    }
  },
  beforeDestroy() {
    clearInterval(this.mouseOverTimeout)
  },
  methods: {
    ...mapActions("UserSettings", ["updateUser"]),
    onJoin() {
      this.$store.dispatch("livechat/enter", { roomID: this.room?.id })
    },
    onMouseOver({ userID, $event }) {
      const rect = $event.target.getBoundingClientRect()
      const { x, y, height, width } = rect
      clearTimeout(this.mouseOverTimeout)
      this.mouseOverTimeout = setTimeout(() => {
        this.$emit("onSelect", { userID, x, y, height, width })
      }, 500)
    },
    onMouseLeave() {
      clearTimeout(this.mouseOverTimeout)
    },
    edit(user) {
      if (this.isHost) this.updateUser({ user })
    },
    /**
     * @param {number} index
     * @returns {string} offset
     */
    getOffset(index) {
      const value = (PLAYER_SIZE / 2) * index + GAP * index
      return `-${value}px`
    }
  }
})
</script>

<style lang="scss">
$gap: 8px;
$player-size: 60px;
$transition-duration: 250ms;
$transition-easing: ease-out;

.video-group-players {
  $block: &;

  &__container {
    gap: $gap;
    opacity: 0.5;

    transition: opacity $transition-duration $transition-easing;
  }

  &__item,
  &__truncated-count {
    transition: transform $transition-duration $transition-easing;
  }

  &__player {
    min-width: $player-size;
    max-width: $player-size;
    cursor: pointer;
  }

  &__player-name {
    font-size: 12px;
    color: #9aa1ad;
    opacity: 0;
    transition: opacity $transition-duration $transition-easing;
  }

  &__button {
    font-size: 12px;
    font-family: Sofia Pro;
    font-weight: bold;
    color: #b6d6ff;
    transition: transform $transition-duration $transition-easing,
      color $transition-duration $transition-easing;
  }

  &__truncated-count {
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    background-color: #586475;
    font-size: 16px;
    text-align: center;
    color: #f8f9fb;
  }

  &:hover {
    #{$block}__item,
    #{$block}__button,
    #{$block}__truncated-count {
      // We need `!important` here, since styles are apllied using `style="..."`
      transform: translateX(0) !important;
    }

    #{$block}__container,
    #{$block}__player-name {
      opacity: 1;
    }

    #{$block}__button {
      color: #fff;
    }
  }
}
</style>
