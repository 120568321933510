import { store } from "@/store"
import axios from "./axios.service"

class LobbyService {
  static async autoAssignUsers() {
    const sessionId = store.state.auth.clientID
    return axios({
      method: "post",
      url: `/session/${sessionId}/auto-assign-users`
    })
  }
}

export default LobbyService
