
























































































































































// TODO(Andrew): remvoe when time comes
// @ts-nocheck
import { mapGetters } from "vuex"
import { debounce } from "lodash"

import { ClientCustomInputType } from "@/types/client"

import ClientUsersConsumer from "@/modules/users/components/ClientUsersConsumer"

import { RtbRow, RtbCol } from "@/components/ui"
import { WeveTextField, WeveSelectField } from "@weve/ui"

import UserList from "@/components/Lobby/UserList.vue"
import Separator from "@/components/Separator.vue"

import { Role, UserFilter } from "@/helpers"

/**
 * @description Use factory function to create a fresh object every time
 */

function getInitialFiltersState() {
  return {
    unassigned: false,
    assigned: false,
    player: false,
    host: false,
    audit: false,
    spectator: false,
    vip: false
  }
}

/**
 * @description
 * `exclusive` will break filter loop if its predicate returns `false`
 * `incluse` will allow other `inclusive` predicates to also do the fitlering
 */
const FILTER_STATE_TO_FILTER_ENTRY = {
  unassigned: { exclusive: true, predicate: UserFilter.unassignedPredicate },
  assigned: { exclusive: true, predicate: UserFilter.assignedPredicate },
  player: {
    inclusive: true,
    predicate: UserFilter.createRolePredicate(Role.Player)
  },
  host: {
    inclusive: true,
    predicate: UserFilter.createRolePredicate(Role.Host)
  },
  audit: {
    inclusive: true,
    predicate: UserFilter.createRolePredicate(Role.Audit)
  },
  spectator: {
    inclusive: true,
    predicate: UserFilter.createRolePredicate(Role.Spectator)
  },
  vip: {
    inclusive: true,
    predicate: user => user.vip
  }
}

const SIZES = ["sm"]

export default ClientUsersConsumer.extend({
  name: "UserSearch",
  components: {
    RtbRow,
    RtbCol,
    Separator,
    UserList,
    WeveSelectField,
    WeveTextField
  },
  props: {
    selectedUserID: String,
    size: {
      type: String,
      default: undefined,
      validator: val => SIZES.includes(val)
    }
  },
  data() {
    return {
      query: "",
      debouncedQuery: null,

      /* Host realated properties */
      filters: getInitialFiltersState(),
      filtersDisabled: true,
      identifier: undefined,
      options: ["option1", "option2", "option3"]
    }
  },
  watch: {
    query(value) {
      this.onQueryChange(value)
    },
    filters: {
      handler(value, previuousValue) {
        this.resetPagination()
        if (value === previuousValue) {
          this.filtersDisabled = false
        }
      },
      deep: true
    },
    filtersDisabled(value) {
      if (value === true) {
        this.filters = getInitialFiltersState()
      }
    }
  },
  computed: {
    ...mapGetters("auth", [
      "user",
      "role",
      "access",
      "client",
      "isHost",
      "isAudit"
    ]),
    isFilterVisible() {
      return this.customInputOptions.length > 1
    },
    anonymousUsers() {
      return this.client ? !!this.client.anonymousUsers : false
    },
    isVip() {
      return this.user.vip
    },
    shouldHideAuditors() {
      return this.client?.hideAuditors ?? false
    },
    nOfUsers() {
      return this.filteredByAccess.length
    },
    filteredByAccess() {
      return this.onlineUsers.filter(({ role }) => {
        if (
          role === Role.Audit &&
          this.shouldHideAuditors &&
          [Role.Host, Role.Audit].includes(this.role) === false
        ) {
          return false
        }
        if (!this.anonymousUsers || this.isHost || this.isVip) {
          return true
        } else {
          return this.isAudit ? true : role !== Role.Host
        }
      })
    },
    filtered() {
      let result = this.filteredByAccess

      if (this.identifier) {
        result = result.filter(user => user.identifier === this.identifier)
      }

      if (this.activeFilterEntries.length > 0) {
        const exclusiveEntries = this.activeFilterEntries.filter(
          entry => entry.exclusive
        )
        const inclusiveEntries = this.activeFilterEntries.filter(
          entry => entry.inclusive
        )

        if (exclusiveEntries.length > 0) {
          result = result.filter(user =>
            exclusiveEntries.every(entry => entry.predicate(user))
          )
        }

        if (inclusiveEntries.length > 0) {
          result = result.filter(user =>
            inclusiveEntries.some(entry => entry.predicate(user))
          )
        }
      }

      if (!this.debouncedQuery) return result

      const query = this.debouncedQuery.toLowerCase().trim()

      const includes = string => {
        if (!string) return false
        const normalized = String(string).toLowerCase().trim()
        return normalized.indexOf(query) > -1
      }

      return result.filter(
        ({ firstname, lastname }) => includes(firstname) || includes(lastname)
      )
    },
    filteredUsersCount() {
      return this.filtered.length
    },
    sortedUsers() {
      const array = this.filtered.filter(
        user => user && user.id !== this.user.id
      )
      const found = this.filtered.find(user => user?.id === this.user.id)
      if (found) {
        array.unshift(found)
      }
      return array
    },
    activeFilterEntries() {
      const entries = Object.entries(this.filters)
        .filter(entry => entry[1])
        .map(([key]) => FILTER_STATE_TO_FILTER_ENTRY[key])

      return entries
    },
    separatorText() {
      if (this.isHost) {
        return this.filteredUsersCount === 1 ? "User" : "Users"
      } else {
        return this.filteredUsersCount === 1 ? "Player" : "Players"
      }
    },
    shouldDisplayTotalUsersCount() {
      return (
        this.filtersDisabled === false &&
        this.isHost &&
        this.filteredUsersCount !== this.nOfUsers
      )
    },
    hasCustomInputSelect() {
      return this.client.customInputType === ClientCustomInputType.Select
    },
    customInputLabel() {
      return this.client.customInputLabel
    },
    customInputOptions() {
      const { customInputOptions } = this.client
      const value = customInputOptions?.split(",") ?? []
      // TODO(Andrew):
      value.unshift("Any")
      return value
    }
  },
  methods: {
    onQueryChange: debounce(function (value) {
      this.debouncedQuery = value
      this.resetPagination()
    }, 500),
    onSelect(obj) {
      this.$emit("onSelect", obj)
    },
    resetPagination() {
      const { list } = this.$refs
      if (list !== undefined) {
        list.resetPagination()
        return true
      } else {
        return false
      }
    },
    onIdentifierInput(value) {
      // TODO(Andrew):
      if (value === "Any") {
        this.identifier = undefined
      } else {
        this.identifier = value
      }
    }
  }
})
