<template>
  <v-flex class="game-info-tooltip">
    <v-tooltip right v-model="show" :close-delay="1500">
      <template v-slot:activator="{ on }">
        <v-flex
          d-flex
          align-center
          @mouseover="mouseover"
          @mouseleave="mouseleave"
        >
          <div @click="copy" v-on="on" class="share-icon-game-info">
            <v-icon class="game-info-tooltip__icon">share</v-icon>
          </div>
        </v-flex>
      </template>
      <span @mouseover="mouseover" @mouseleave="mouseleave">
        <template v-if="!!copiedText">
          {{ copiedText }}
        </template>
        <template v-else>
          <a class="game-info-tooltip__href" @click.prevent="copy">
            {{ getUrl }}
          </a>
        </template>
      </span>
    </v-tooltip>
  </v-flex>
</template>

<script>
import { copyToClipboard } from "@/helpers"

export default {
  name: "GameInfoTooltip",
  props: {
    clientId: {
      type: String
    },
    gameId: {
      type: String
    }
  },
  data() {
    return {
      show: false,
      copiedText: null,
      hoverTimeout: null,
      url: ""
    }
  },
  computed: {
    getHost() {
      return window.location.origin
    },
    getUrl() {
      return this.getHost + "/login/" + this.clientId + this.gameId + "?auth=0"
    }
  },
  watch: {
    show(value) {
      if (!value) {
        setTimeout(() => {
          this.copiedText = null
        }, 300)
      }
    }
  },
  methods: {
    mouseover() {
      this.show = true
      clearTimeout(this.hoverTimeout)
    },
    mouseleave() {
      this.hoverTimeout = setTimeout(() => {
        this.show = false
      }, 1000)
    },
    copy() {
      copyToClipboard(this.getUrl)
      this.copiedText = "Copied to clipboard!"
      this.mouseleave()
    }
  }
}
</script>

<style lang="scss">
.game-info-tooltip {
  flex: none;
  margin-left: 5px;
  cursor: pointer;
  user-select: none;

  &__href {
    text-decoration: underline;
    color: #fff;
  }

  &__icon {
    color: $color-primary-accent !important;
    font-size: 20px;
  }
}
</style>
