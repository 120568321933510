var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-flex", { staticClass: "game-image", attrs: { grow: "" } }, [
    _c(
      "div",
      { staticClass: "game-image__info rtb-d-flex flex-column h-full" },
      [
        _c("div", { staticClass: "game-image__title" }, [
          _vm._v(_vm._s(_vm.name)),
        ]),
        _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
          _vm._v(_vm._s(_vm.game.description)),
        ]),
        _c(
          "v-layout",
          { staticClass: "h-0 flex-grow overflow-auto", attrs: { column: "" } },
          [
            _c("div", [
              _vm.game.image !== undefined
                ? _c("img", {
                    staticClass: "relative w-full h-full object-cover",
                    attrs: { src: _vm.game.image },
                  })
                : _vm._e(),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }