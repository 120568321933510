var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { ref: "root" },
    [
      _c(
        "RatioContainer",
        {
          staticClass: "lobby-player-slot",
          class: { optional: _vm.player.optional },
        },
        [
          _c("div", { ref: "square", staticClass: "winner-outline" }),
          _c(
            "v-flex",
            {
              staticClass: "player-container",
              class: {
                "player-container--dominant-speaker": _vm.isDominantSpeaker,
              },
              attrs: { "d-flex": "" },
            },
            [
              _c("UserMedia", {
                staticClass: "lobby-player-slot__user-media",
                attrs: {
                  videoTrack: _vm.track,
                  identity: _vm.player.id,
                  imageUrl: _vm.imageURL,
                },
              }),
              _vm.isChatActive && _vm.player.muted
                ? _c(
                    "div",
                    {
                      staticClass: "lobby-player-slot__mute-icon",
                      class: {
                        "lobby-player-slot__mute-icon--active":
                          _vm.player.muted,
                      },
                      attrs: { dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleAudio()
                        },
                      },
                    },
                    [
                      _c("SvgIcon", {
                        attrs: {
                          name: _vm.player.muted ? "muted" : "microphone",
                          width: "18",
                          height: "18",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !!_vm.gameStartTime && false
                ? _c(
                    "div",
                    { staticClass: "lobby-player-slot__countdown" },
                    [
                      _c("PreGameCountdown", {
                        attrs: { endTime: _vm.gameStartTime },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "player-name" },
                [
                  _c("UserName", {
                    attrs: { firstname: _vm.firstname, lastname: _vm.lastname },
                  }),
                ],
                1
              ),
              _vm.player.optional
                ? _c(
                    "v-flex",
                    {
                      staticClass: "invite-text-container",
                      attrs: {
                        "d-flex": "",
                        "align-center": "",
                        "justify-center": "",
                        "text-xs-center": "",
                      },
                      on: { click: _vm.onPlayerInvite },
                    },
                    [
                      _vm.canInvitePlayer
                        ? [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "invite-text-container__text invite-text-container__text--default",
                              },
                              [_vm._v("Invite to Video Chat")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "invite-text-container__text invite-text-container__text--hover",
                              },
                              [_vm._v("Click to Video Chat")]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }