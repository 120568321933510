var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "video-group rtb-d-flex", attrs: { "d-flex": "" } },
    [
      _c("v-layout", { attrs: { column: "" } }, [
        _c(
          "div",
          {
            staticClass: "video-groups-title",
            staticStyle: { "margin-bottom": "10px" },
          },
          [_vm._v(" Video Groups ")]
        ),
        _c("div", { staticClass: "video-group__scrollable" }, [
          _c(
            "div",
            { staticClass: "rtb-d-flex flex-column" },
            _vm._l(_vm.rooms, function (room, index) {
              return _c("VideoGroupPlayers", {
                key: room.id,
                class: { "mt-2": index > 0 },
                attrs: { users: _vm.getUsersByRoom(room), room: room },
              })
            }),
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }