






import { defineComponent } from "@vue/composition-api"
import { useVModel } from "@vueuse/core"

export default defineComponent({
  name: "LobbyTabs",
  props: {
    value: {
      type: Number,
      default: undefined
    }
  },
  setup(props, { emit }) {
    const data = useVModel(props, "value", emit)

    return { data }
  }
})
