var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "game-info-tooltip" },
    [
      _c(
        "v-tooltip",
        {
          attrs: { right: "", "close-delay": 1500 },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "v-flex",
                    {
                      attrs: { "d-flex": "", "align-center": "" },
                      on: {
                        mouseover: _vm.mouseover,
                        mouseleave: _vm.mouseleave,
                      },
                    },
                    [
                      _c(
                        "div",
                        _vm._g(
                          {
                            staticClass: "share-icon-game-info",
                            on: { click: _vm.copy },
                          },
                          on
                        ),
                        [
                          _c(
                            "v-icon",
                            { staticClass: "game-info-tooltip__icon" },
                            [_vm._v("share")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "span",
            { on: { mouseover: _vm.mouseover, mouseleave: _vm.mouseleave } },
            [
              !!_vm.copiedText
                ? [_vm._v(" " + _vm._s(_vm.copiedText) + " ")]
                : [
                    _c(
                      "a",
                      {
                        staticClass: "game-info-tooltip__href",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.copy.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.getUrl) + " ")]
                    ),
                  ],
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }