var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "room-detail rtb-d-flex flex-column flex-grow h-0" },
    [
      !_vm.game.streamUrl
        ? _c(
            "div",
            {
              staticClass: "room-detail-image flex-shrink-0 relative",
              style: _vm.headStyle,
            },
            [
              _vm.isHost
                ? _c(
                    "RtbButton",
                    {
                      staticClass: "abrolute mx-1 my-1",
                      attrs: { variant: "icon", size: "sm" },
                      on: { click: _vm.openEditTeamsDialog },
                    },
                    [
                      _c("SvgIcon", {
                        staticClass: "rtb-right-toolbar__list-item-icon",
                        attrs: {
                          name: "recent-actors-outlined",
                          width: "20",
                          height: "20",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "room-detail-info rtb-d-flex flex-column flex-grow h-0",
        },
        [
          _vm.isStandard && !_vm.isHostless && !_vm.game.streamUrl
            ? _c(
                "v-flex",
                { staticClass: "flex-grow-0" },
                [
                  _c("LobbyRoomHost", {
                    staticClass: "game-info-host",
                    attrs: { user: _vm.roomHost },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isHost
            ? _c(
                "v-layout",
                {
                  staticClass: "flex-grow-0",
                  staticStyle: { margin: "-50px 0 20px 140px" },
                  attrs: { row: "", "align-center": "" },
                },
                [
                  _c(
                    "v-flex",
                    { staticClass: "mr-2", attrs: { shrink: "" } },
                    [
                      _c("GameInfoTooltip", {
                        attrs: {
                          gameId: _vm.game.id,
                          clientId: _vm.game.clientID,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-flex", { attrs: { shrink: "" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "cancel-game",
                        on: {
                          mouseleave: function ($event) {
                            _vm.isOverCancel = false
                          },
                          mouseover: function ($event) {
                            _vm.isOverCancel = true
                          },
                        },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "cancel-button",
                            on: { click: _vm.toggleCancelOptions },
                          },
                          [_vm._v(" Move Participants ")]
                        ),
                        _c(
                          "div",
                          { ref: "cancel", staticClass: "cancel-options" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "cancel-option",
                                on: {
                                  click: function ($event) {
                                    if (
                                      $event.target !== $event.currentTarget
                                    ) {
                                      return null
                                    }
                                    return _vm.endGame.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v(" By Ending Game ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "cancel-option" },
                              [
                                _vm._v(" Merge Into "),
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.filteredGamesArray,
                                    label: "Play Type",
                                    required: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item",
                                        fn: function (data) {
                                          return [
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    data.item.externalName ||
                                                      data.item.name
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2971131368
                                  ),
                                  model: {
                                    value: _vm.mergeToGameSelection,
                                    callback: function ($$v) {
                                      _vm.mergeToGameSelection = $$v
                                    },
                                    expression: "mergeToGameSelection",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "cancel-option",
                                on: {
                                  click: function ($event) {
                                    if (
                                      $event.target !== $event.currentTarget
                                    ) {
                                      return null
                                    }
                                    return _vm.unendGame.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v(" Un-End Game ")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "main-title" }, [_vm._v(_vm._s(_vm.name))]),
          _c("div", [_vm._v(_vm._s(_vm.description))]),
          _c(
            "v-layout",
            {
              staticClass: "room-detail-container flex-grow-0",
              attrs: { row: "", wrap: "" },
            },
            [
              _c(
                "v-flex",
                {
                  staticClass: "room-detail-items",
                  attrs: { grow: "", lg2: "", md4: "" },
                },
                [
                  _vm._v(" Game time"),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.startTime) + " "),
                ]
              ),
              _vm.game.duration
                ? _c(
                    "v-flex",
                    {
                      staticClass: "room-detail-items",
                      attrs: { grow: "", lg2: "", md4: "" },
                    },
                    [
                      _vm._v(" Duration"),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.duration) + " "),
                    ]
                  )
                : _vm._e(),
              _c(
                "v-flex",
                {
                  staticClass: "room-detail-items",
                  staticStyle: { "padding-right": "10px" },
                  attrs: { grow: "", lg2: "", md4: "" },
                },
                [
                  _c("GameTime", {
                    attrs: {
                      game: _vm.game,
                      time: _vm.time,
                      gameStartTime: _vm.gameStartTime,
                      gameEndTime: _vm.gameEndTime,
                      isGameBooked: _vm.isGameBooked,
                      isPlayedByUser: _vm.isVisitedByUser,
                    },
                  }),
                ],
                1
              ),
              _c("v-spacer"),
              _vm.isGameSpectable && _vm.canShowBigButton
                ? _c(
                    "v-flex",
                    { attrs: { grow: "", lg3: "", md6: "" } },
                    [
                      _vm.isGameSpectable
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "spectate-button",
                              staticStyle: { right: "10px" },
                              attrs: { flat: "", loading: _vm.loading },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.tryToJoin({ audit: true })
                                },
                              },
                            },
                            [_vm._v(" Spectate ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canShowBigButton
                ? _c(
                    "v-flex",
                    { attrs: { grow: "", lg3: "", md6: "" } },
                    [
                      _vm.status === "available"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "join-button",
                              staticStyle: { right: "10px" },
                              attrs: { loading: _vm.loading },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.tryToJoin.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v(" Join Now! ")]
                          )
                        : _vm.status === "locked"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "closed-button",
                              staticStyle: {
                                right: "10px",
                                "pointer-events": "none",
                              },
                            },
                            [_vm._v(" Locked ")]
                          )
                        : _vm.status === "played"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "closed-button",
                              staticStyle: { right: "10px" },
                            },
                            [_vm._v(" Played ")]
                          )
                        : _vm.status === "full"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "closed-button",
                              staticStyle: { right: "10px" },
                            },
                            [_vm._v(" Full ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-layout",
                { attrs: { flex: "", row: "" } },
                [
                  !_vm.canShowBigButton
                    ? _c(
                        "v-flex",
                        { staticClass: "mx-3", attrs: { grow: "", lg5: "" } },
                        [
                          _vm.isEnterable
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "spectate-button",
                                  staticStyle: { right: "10px", width: "100%" },
                                  attrs: { flat: "", loading: _vm.loading },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onEnterGame.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Enter ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.canShowBigButton
                    ? _c(
                        "v-flex",
                        { staticClass: "mx-3", attrs: { grow: "", lg5: "" } },
                        [
                          _vm.isLeaveable
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "join-button",
                                  staticStyle: { right: "10px", width: "100%" },
                                  attrs: { loading: _vm.loading },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onLeaveGame.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Leave ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isStreamVisible
            ? _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "increase-video-size-button",
                              staticStyle: { "margin-top": "-30px" },
                              attrs: { flat: "" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.showTheVideo.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v(" Increase Video Size ")]
                          ),
                        ],
                        1
                      ),
                      !_vm.videoDrawer && _vm.canPlay
                        ? _c("WithAudioContext", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var mute = ref.mute
                                    var unmute = ref.unmute
                                    return [
                                      _c("TAStream", {
                                        staticClass:
                                          "flex-grow twitch-container",
                                        staticStyle: {
                                          "align-self": "center height: 40vh",
                                        },
                                        attrs: {
                                          streamUrl: _vm.game.streamUrl,
                                        },
                                        on: {
                                          play: mute,
                                          pause: unmute,
                                          end: unmute,
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1122503333
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : [
                _c("div", { staticClass: "room-detail__attendees-txt" }, [
                  _vm._v("Attendees"),
                ]),
                _c("div", { staticClass: "room-detail__attendees-txt" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.nOfRoomPlayers) +
                      " of " +
                      _vm._s(_vm.nOfMaxPlayers) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "room-detail__attendees-container" },
                  [
                    !_vm.announcementVideo
                      ? _c("UserList", {
                          attrs: {
                            size: _vm.userListRowLength,
                            users: _vm.roomUsersToShow,
                            selectedUserID: _vm.selectedUserID,
                          },
                          on: { onSelect: _vm.onSelectUser },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _vm.isHost && _vm.hasBroadcasting
        ? _c(
            "RtbControlButton",
            {
              staticClass: "abrolute bottom-0",
              attrs: { variant: "outline", danger: "" },
              on: {
                click: function ($event) {
                  _vm.isBroadcastHidden = false
                },
              },
            },
            [_vm._v(" Show Broadcast ")]
          )
        : _vm._e(),
      _vm.isHost
        ? _c("PickTeamsDialog", {
            attrs: { gameID: _vm.game.id, users: _vm.roomUsersToShow },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }