var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppUserCard", {
    attrs: { user: _vm.user, mutable: false, videoTrack: _vm.videoTrack },
    scopedSlots: _vm._u(
      [
        _vm.canMute
          ? {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "UserCardButton",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("toggleMute", _vm.user)
                        },
                      },
                    },
                    [
                      _vm.user.muted
                        ? _c("MicrophoneOffIcon")
                        : _c("MicrophoneIcon"),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }