var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      class: !_vm.showChatHead ? "lobby-chat-container" : "",
      attrs: { column: "", shrink: "", "d-flex": "" },
    },
    [
      _vm.showChatBody
        ? _c("Chat", {
            ref: "chatComponent",
            attrs: {
              teamID: _vm.getTeamID,
              roomID: _vm.getChatRoomID,
              context: _vm.getChatContext,
              placeholder: _vm.messagePlaceholder,
              canSendMessage: _vm.canSendMessage,
              isAnonymousChat: _vm.isAnonymousChat,
              messageText: _vm.message,
            },
            on: {
              onSelectedGameIDUpdate: function (data) {
                return _vm.$emit("onSelectedGameIDUpdate", data)
              },
              "update:messageText": function ($event) {
                _vm.message = $event
              },
              "update:message-text": function ($event) {
                _vm.message = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "chat-header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "lobby-chat-container__header" },
                        [
                          _vm.isSuper
                            ? _c("AnonymousToggle", {
                                attrs: {
                                  status: Boolean(_vm.client.anonymousChat),
                                },
                                on: {
                                  toggleAnonymousChat: _vm.toggleAnonymousChat,
                                },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "lobby-chat-container__header--primary",
                            },
                            [_vm._v(" You’re chatting with the lobby ")]
                          ),
                        ],
                        1
                      ),
                      _vm.availableChatContext.length
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "lobby-chat-container__header-context-toggle",
                            },
                            [
                              _c("ChatContextToggle", {
                                staticStyle: { "margin-top": "14px" },
                                attrs: {
                                  teamID: _vm.getTeamID,
                                  context: _vm.getChatContext,
                                  contexts: _vm.availableChatContext,
                                  initialContext: _vm.getChatContext,
                                },
                                on: {
                                  localContextChange: _vm.onLocalContextChange,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", {
                        staticClass: "lobby-chat-container__header--close",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("closeChat")
                          },
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "chat-messages",
                  fn: function (ref) {
                    var chat = ref.data
                    return [
                      _c(
                        "v-layout",
                        {
                          staticClass: "lobby-chat-container__messages-wrap",
                          attrs: { grow: "" },
                        },
                        [
                          _c("Messages", {
                            attrs: {
                              messages: chat.messages,
                              teamID: chat.teamID,
                              contextRef: chat.contextRef,
                              canLazyLoadMessage: chat.canLazyLoadMessage,
                              context: chat.context,
                            },
                            on: {
                              LAZYLOAD_MESSAGE: chat.LAZYLOAD_MESSAGE,
                              onSelectedGameIDUpdate:
                                chat.onSelectedGameIDUpdate,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "messages",
                                  fn: function () {
                                    return _vm._l(
                                      chat.messages,
                                      function (data, index) {
                                        return _c(
                                          "v-flex",
                                          {
                                            key: index + "-" + data.key,
                                            staticClass:
                                              "lobby-chat-container__message-wrap",
                                            attrs: { "flex-end": "" },
                                          },
                                          [
                                            _c(
                                              "v-layout",
                                              {
                                                staticClass:
                                                  "lobby-chat-container__message-wrap--contain",
                                                class:
                                                  _vm.user.id ===
                                                  data.messagePayload.fromID
                                                    ? "right-message"
                                                    : "left-message",
                                                attrs: { column: "" },
                                              },
                                              [
                                                _vm.user.id !==
                                                data.messagePayload.fromID
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "lobby-chat-container__message-wrap--contain___time flex align-items-center",
                                                      },
                                                      [
                                                        _c("UserName", {
                                                          attrs: {
                                                            firstname:
                                                              data.userDetail
                                                                .firstname,
                                                            lastname:
                                                              data.userDetail
                                                                .lastname,
                                                          },
                                                        }),
                                                        data.userDetail.role ===
                                                        "facilitator"
                                                          ? _c("svg-icon", {
                                                              staticClass:
                                                                "ml-1",
                                                              staticStyle: {
                                                                "margin-bottom":
                                                                  "5px",
                                                              },
                                                              attrs: {
                                                                size: "small",
                                                                name: "union",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                        _vm._v(
                                                          " — " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "messageTime"
                                                              )(
                                                                data
                                                                  .messagePayload
                                                                  .timestamp
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c("div", {
                                                  staticClass: "message",
                                                  class: {
                                                    "message--danger":
                                                      _vm.isHost &&
                                                      data.messagePayload.message.includes(
                                                        "@host"
                                                      ),
                                                  },
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.$options.filters.normalizeMessage(
                                                        data.messagePayload
                                                          .message
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    )
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "chat-loader",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "lobby-chat-container__chat--loading" },
                        [_c("rtb-spinner")],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4063837665
            ),
          })
        : _c("ChatHead", {
            attrs: { teamID: _vm.getTeamID, context: _vm.getChatContext },
            scopedSlots: _vm._u(
              [
                {
                  key: "chat-head-label",
                  fn: function () {
                    return [
                      _c(
                        "v-flex",
                        {
                          staticClass: "lobby-chat-container__chat-head--label",
                        },
                        [
                          _vm._t("default", function () {
                            return [_c("div", [_vm._v("CHAT")])]
                          }),
                          _vm.allUnreadMessage
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "lobby-chat-container__chat-head--label__unread",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("unreadMessageFormat")(
                                          _vm.allUnreadMessage
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }