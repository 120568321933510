











import Vue from "vue"

import AppUserCard from "@/components/UserCard/AppUserCard.vue"
import UserCardButton from "@/components/UserCard/UserCardButton"
import MicrophoneIcon from "@/components/UserCard/icons/MicrophoneIcon"
import MicrophoneOffIcon from "@/components/UserCard/icons/MicrophoneOffIcon"

export default Vue.extend({
  name: "LobbyUserCard",
  components: {
    AppUserCard,
    UserCardButton,
    MicrophoneIcon,
    MicrophoneOffIcon
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    videoTrack: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    canMute() {
      return (
        (this.$store.getters["auth/user"].id !== this.user.id &&
          this.user.muted) ||
        this.$store.getters["auth/isHost"]
      )
    }
  }
})
