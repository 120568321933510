

































































import Vue from "vue"
import { mapGetters, mapActions } from "vuex"
import SmartMedia from "@/modules/smart-media/components/SmartMedia.vue"
import GameYouTube from "@/components/Lobby/GameYouTube.vue"

import GameVideo from "@/components/Lobby/GameVideo.vue"
import WithAudioContext from "@/modules/audio/components/WithAudioContext"

const LOBBY_PAGE_CONTENT_MAX_WIDTH = 1600
const RIGHT_TOOLBAR_WIDTH = 60
const PEOPLE_COLUMN_WIDTH = 400

const N_OF_SECONDS_TO_WAIT_FOR_TIME_UPDATE = 5

// TODO
// refactor this load of nonsense

export default Vue.extend({
  name: "VideoDrawer",
  components: {
    WithAudioContext,
    GameYouTube,
    GameVideo,
    SmartMedia,
    TAStream: () => import("@/components/VideoPlayers/TAStream.vue")
  },
  props: {
    game: {
      type: Object
    },
    showDrawer: {
      type: Boolean
    },
    isAnnoncementVisible: {
      type: Boolean
    },
    isGameVideoVisible: {
      type: Boolean
    },
    announcementURL: {
      type: String,
      default: undefined
    },
    announcementFlipped: {
      type: Boolean
    },
    playAnnouncement: {
      type: Boolean
    },
    canPlay: Boolean
  },
  data() {
    return {
      contentWidth: 0,
      interval: null,
      currentTime: 0,
      noProgressCoutner: 0
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "isPlayer", "isHost", "isAudit", "client"]),
    isVideoOfAnnouncementType() {
      return Boolean(this.isAnnoncementVisible && this.announcementURL)
    },
    showCloseBtn() {
      if (this.$store.getters["group/isViewerHost"]) return true
      if (this.$store.getters["group/isViewerAuditor"]) return true
      if (!this.isVideoOfAnnouncementType) return true
      if (this.noProgressCoutner > N_OF_SECONDS_TO_WAIT_FOR_TIME_UPDATE)
        return true

      return false
    },
    topImageURL() {
      if (this.client.announcementTopImage)
        return this.client.announcementTopImage
      return require("../../assets/v-drawer/v-drawer-ractangle.png")
    },
    bottomImageURL() {
      if (this.client.announcementBottomImage)
        return this.client.announcementBottomImage
      return require("../../assets/v-drawer/v-drawer-circles.png")
    },
    drawerWidth() {
      const contentWdthMoreThanMaxWidth =
        this.contentWidth > LOBBY_PAGE_CONTENT_MAX_WIDTH

      return contentWdthMoreThanMaxWidth
        ? this.contentWidth +
            RIGHT_TOOLBAR_WIDTH / 2 -
            (PEOPLE_COLUMN_WIDTH +
              (this.contentWidth - LOBBY_PAGE_CONTENT_MAX_WIDTH) / 2)
        : this.contentWidth - PEOPLE_COLUMN_WIDTH
    },
    title() {
      return this.game?.externalName || this.game?.name
    },
    visibility() {
      return this.isAnnoncementVisible || this.isGameVideoVisible
    }
  },
  watch: {
    isAnnoncementVisible(newValue, oldValue) {
      if (newValue && this.$store.getters["auth/volume"] < 0.5)
        this.updateUserVolume({ userID: this.user?.id, volume: 0.5 })

      if (newValue !== oldValue) {
        clearInterval(this.interval)
        this.noProgressCoutner = 0
      }

      if (newValue && !oldValue) {
        this.interval = setInterval(() => {
          const currentTime = this.currentTime
          this.currentTime =
            this.$refs.announcementVideoNode?.$el?.currentTime || 0

          if (currentTime === this.currentTime)
            this.noProgressCoutner = this.noProgressCoutner + 1
          else this.noProgressCoutner = 0

          if (this.noProgressCoutner > N_OF_SECONDS_TO_WAIT_FOR_TIME_UPDATE)
            clearInterval(this.interval)
        }, 1000)
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.onWindowResize)
    this.onWindowResize()
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize)
    clearInterval(this.interval)
  },
  methods: {
    ...mapActions("soundeffect", ["updateUserVolume"]),
    onWindowResize() {
      this.contentWidth = window.innerWidth
    },
    close() {
      this.$emit("onCloseDrawer")
    },
    onAnnouncementEnd() {
      setTimeout(() => this.$emit("onCloseDrawer"), 1000)
    }
  }
})
