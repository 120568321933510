var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "lobby-profile", attrs: { "d-flex": "" } },
    [
      !!_vm.profileUser && !!_vm.profileUser.id
        ? _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c(
                "v-flex",
                {
                  staticStyle: { position: "relative" },
                  attrs: {
                    xs4: "",
                    "mt-3": "",
                    "ml-3": "",
                    "mb-3": "",
                    "mr-2": "",
                    "d-flex": "",
                    "align-center": "",
                    "justify-center": "",
                  },
                },
                [
                  _c("PreGamePlayer", {
                    key: _vm.profileUser.id,
                    staticClass: "player",
                    attrs: { player: _vm.profileUser },
                  }),
                  _vm.isSpectator
                    ? _c("svg-icon", {
                        staticClass: "pre-game-profile__media-icon",
                        attrs: { name: "spectator" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs8: "",
                    "d-flex": "",
                    "mt-3": "",
                    "mr-3": "",
                    "mb-3": "",
                  },
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: { row: "", "align-center": "", wrap: "" },
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { shrink: "", "mr-2": "" } },
                                [
                                  _c(
                                    "h1",
                                    {
                                      staticClass:
                                        "profile-user-name rtb-truncate",
                                    },
                                    [
                                      _c("UserName", {
                                        attrs: {
                                          firstname: _vm.profileUser.firstname,
                                          lastname: _vm.profileUser.lastname,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c("v-spacer"),
                              _vm.userID !== _vm.user.id
                                ? _c("v-flex", { attrs: { shrink: "" } }, [
                                    _vm.isUserOnline
                                      ? _c(
                                          "div",
                                          { staticClass: "online-status" },
                                          [_vm._v("ONLINE")]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "online-status offline",
                                          },
                                          [_vm._v("OFFLINE")]
                                        ),
                                  ])
                                : _vm._e(),
                              !!_vm.profileUser.speaker
                                ? _c(
                                    "v-flex",
                                    {
                                      staticClass: "profile-speaker-icon",
                                      attrs: { shrink: "", "ml-2": "" },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-bullhorn",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !!_vm.profileGame
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "game-details",
                              attrs: { "d-flex": "", "align-center": "" },
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass:
                                        "rtb-line-clamp rtb-line-clamp--3",
                                      staticStyle: { "margin-top": "3px" },
                                      attrs: { xs7: "" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "link game-name",
                                          on: { click: _vm.onGameClick },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.profileGame.externalName ||
                                                  _vm.profileGame.name
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-flex",
                        { attrs: { "d-flex": "", "align-center": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "rtb-self-end",
                                  attrs: {
                                    "d-flex": "",
                                    "align-center": "",
                                    xs7: "",
                                  },
                                },
                                [
                                  _c("RoomInfo", {
                                    attrs: {
                                      users: _vm.currentRoomUsers,
                                      nOfSlots: _vm.max - 1,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "buttons ml-2 rtb-flex-col",
                                  attrs: { "d-flex": "", xs5: "" },
                                },
                                [
                                  _vm.isHost &&
                                  (!_vm.isHostRole(_vm.profileUser.role) ||
                                    !!_vm.user.super)
                                    ? _c(
                                        "div",
                                        { staticClass: "move-to-game" },
                                        [
                                          _c("button", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "contrast-text-color",
                                              },
                                              [_vm._v(" Move to Game ")]
                                            ),
                                          ]),
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.filteredGamesArray,
                                              label: "Play Type",
                                              required: "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function (data) {
                                                    return [
                                                      _c("div", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item
                                                                .externalName ||
                                                                data.item.name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2971131368
                                            ),
                                            model: {
                                              value: _vm.assignedToGame,
                                              callback: function ($$v) {
                                                _vm.assignedToGame = $$v
                                              },
                                              expression: "assignedToGame",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.requestButtonStatus === "onboarding"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "invite-button disabled",
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                            disabled: "",
                                          },
                                        },
                                        [_vm._v(" ONBOARDING ")]
                                      )
                                    : _vm.requestButtonStatus === "full"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "invite-button disabled",
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                            disabled: "",
                                          },
                                        },
                                        [_vm._v(" CHAT FULL ")]
                                      )
                                    : _vm.requestButtonStatus === "playing"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "invite-button disabled",
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                            disabled: "",
                                          },
                                        },
                                        [_vm._v(" IN GAME ")]
                                      )
                                    : _vm.requestButtonStatus === "show"
                                    ? [
                                        _vm.$_LiveChatMixin_isUserBroadcasting ||
                                        (_vm.$_LiveChatMixin_streamRoomID &&
                                          _vm.isSuper)
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "invite-button",
                                                attrs: {
                                                  "d-flex": "",
                                                  "align-center": "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.inviteBroadcast.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" + Broadcast ")]
                                            )
                                          : _vm._e(),
                                        !_vm.$_LiveChatMixin_isUserBroadcasting
                                          ? _c(
                                              "button",
                                              {
                                                staticClass: "invite-button",
                                                class: {
                                                  "text-xs": _vm.isSuper,
                                                },
                                                attrs: {
                                                  "d-flex": "",
                                                  "align-center": "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.inviteChat.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(" CHAT "),
                                                _vm.isUserLocked && _vm.isSuper
                                                  ? [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "locked-text",
                                                        },
                                                        [_vm._v("LOCKED")]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm.requestButtonStatus === "loading"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "invite-button disabled",
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                          },
                                        },
                                        [
                                          _c("v-progress-circular", {
                                            staticClass: "spinner",
                                            attrs: { indeterminate: "" },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm.requestButtonStatus === "join"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "invite-button join",
                                          class: { "text-xs": _vm.isSuper },
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.onJoin.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(" CHAT "),
                                          _vm.isUserLocked && _vm.isSuper
                                            ? [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "locked-text",
                                                  },
                                                  [_vm._v("LOCKED")]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm.requestButtonStatus === "locked"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "invite-button disabled locked",
                                          attrs: {
                                            "d-flex": "",
                                            "align-center": "",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-lock locked-icon",
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "locked-text" },
                                            [_vm._v(" LOCKED ")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !!_vm.currentRoomUsers.length
                        ? _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            _vm._l(_vm.currentRoomUsers, function (user) {
                              return _c(
                                "span",
                                {
                                  key: "room-info-user-names-" + user.id,
                                  staticClass: "room-user-name",
                                },
                                [
                                  _c("UserName", {
                                    attrs: {
                                      firstname: user.firstname,
                                      lastname: user.lastname,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }