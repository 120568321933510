var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tabs",
    {
      staticClass: "lobby-tabs",
      attrs: { dark: "" },
      model: {
        value: _vm.data,
        callback: function ($$v) {
          _vm.data = $$v
        },
        expression: "data",
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }