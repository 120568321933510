var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pseudo-tournament-sidebar-header" },
    [
      _c("div", { staticClass: "sidebar-divider" }),
      _c(
        "v-layout",
        {
          staticClass: "pseudo-tournament-sidebar-header__rounds",
          attrs: { row: "", wrap: "" },
        },
        [
          _c(
            "v-flex",
            {
              key: "tournament-header-round-all",
              staticClass: "pseudo-tournament-sidebar-header__round",
              class: { "--active": !_vm.round },
              attrs: { shrink: "", "d-flex": "", "align-center": "" },
              on: {
                click: function ($event) {
                  return _vm.onSelect(null)
                },
              },
            },
            [
              !_vm.round
                ? _c("svg-icon", {
                    attrs: { size: "small", name: "caret-right" },
                  })
                : _vm._e(),
              _vm._v(" ALL "),
            ],
            1
          ),
          _vm._l(_vm.rounds, function (n) {
            return _c(
              "v-flex",
              {
                key: "tournament-header-round-" + n,
                staticClass: "pseudo-tournament-sidebar-header__round",
                class: { "--active": n == parseInt(_vm.round) },
                attrs: { shrink: "", "d-flex": "", "align-center": "" },
                on: {
                  click: function ($event) {
                    return _vm.onSelect(n)
                  },
                },
              },
              [
                n == parseInt(_vm.round)
                  ? _c("svg-icon", {
                      attrs: { size: "small", name: "caret-right" },
                    })
                  : _vm._e(),
                _vm._v(" Round " + _vm._s(n) + " "),
              ],
              1
            )
          }),
          _c(
            "v-flex",
            {
              key: "tournament-header-checkbox",
              attrs: { shrink: "", "d-flex": "", "align-center": "" },
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-checkbox",
                            _vm._g(
                              {
                                staticClass:
                                  "pseudo-tournament-sidebar-header__checkbox",
                                attrs: {
                                  dark: "",
                                  dense: "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.showStagingGames,
                                  callback: function ($$v) {
                                    _vm.showStagingGames = $$v
                                  },
                                  expression: "showStagingGames",
                                },
                              },
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("span", { staticClass: "top" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.showStagingGames
                            ? "Hide Staging Games"
                            : "Show Staging Games"
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { shrink: "", "d-flex": "", "align-center": "" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "SvgIcon",
                            _vm._g(
                              {
                                staticClass:
                                  "pseudo-tournament-sidebar-header__reset-icon",
                                attrs: {
                                  name: "replay",
                                  width: "20",
                                  height: "20",
                                },
                                on: { click: _vm.onResetRoundNumber },
                              },
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("span", { staticClass: "top" }, [
                    _vm._v("Reset Current Round Number"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm.round < _vm.rounds.length && _vm.isHost
            ? _c(
                "v-flex",
                { attrs: { shrink: "", "d-flex": "", "align-center": "" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "SvgIcon",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "pseudo-tournament-sidebar-header__advance",
                                      attrs: {
                                        name: "play",
                                        width: "20",
                                        height: "20",
                                      },
                                      on: { click: _vm.nextRound },
                                    },
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        752992946
                      ),
                    },
                    [
                      _c("span", { staticClass: "top" }, [
                        _vm._v(" Advance to Round " + _vm._s(_vm.round + 1)),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c("div", { staticClass: "sidebar-divider" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }