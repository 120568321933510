


































import { computed, defineComponent, ref } from "@vue/composition-api"

import useLobbyRooms from "@/use/useLobbyRooms"
import useLobby from "@/use/useLobby"
import useStore from "@/use/useStore"

import GameSearch from "@/components/Lobby/GameSearch.vue"
import LobbyTabs from "@/components/Lobby/LobbyTabs.vue"

export default defineComponent({
  name: "LobbyRooms",
  components: { GameSearch, LobbyTabs },
  setup() {
    const roomTab = ref()
    const { store } = useStore()
    const {
      hasDiyRooms,
      hasVideoRooms,
      hasStandardRooms,
      videoRooms,
      diyRooms,
      standardRooms,
      isRoomVisitedByUser
    } = useLobbyRooms()
    const { actualSelectedGameID } = useLobby()
    const isHost = computed(() => store.getters["auth/isHost"])
    return {
      roomTab,
      isHost,
      hasDiyRooms,
      hasVideoRooms,
      hasStandardRooms,
      videoRooms,
      diyRooms,
      standardRooms,
      actualSelectedGameID,
      isRoomVisitedByUser
    }
  }
})
