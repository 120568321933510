<template>
  <div class="live-chat">
    <template v-if="ftuxVisibility && isFtuxAvailable">
      <div class="live-chat__ftux-shadow" :style="ftuxShadowStyle" />
      <div class="live-chat__ftux-overlay" />
    </template>
    <div class="live-chat__top">
      <div class="live-chat__header">VIDEO CHAT</div>
      <div class="live-chat__controls">
        <v-tooltip
          content-class="ftux-tooltip"
          bottom
          color="white"
          :value="ftuxVisibility"
          :disabled="!isFtuxAvailable"
        >
          <template v-slot:activator="{ on }">
            <RtbControlButton
              v-on="on"
              ref="lockBtn"
              sm
              @click="onToggleChatLock"
              class="whitespace-no-wrap"
              :class="{
                'pink--text': isChatLocked,
                'text--accent-3 green--text': !isChatLocked
              }"
            >
              <v-icon :color="isChatLocked ? 'pink' : 'green accent-3'" small>{{
                isChatLocked ? "lock" : "lock_open"
              }}</v-icon
              >Chat {{ isChatLocked ? "Closed" : "Open" }}
            </RtbControlButton>
          </template>
          <div class="bottom black--text ftux-unit">
            <h4>Open to video chat?</h4>
            <p>
              Would you like other attendees to be able to video chat with you?
            </p>
            <div class="ftux-unit__controls">
              <v-btn outline small color="red lighten-1" @click="denyFtux()">
                No, close
              </v-btn>
              <v-btn
                small
                dark
                depressed
                color="red lighten-1"
                @click="allowFtux()"
              >
                Yes, keep open
              </v-btn>
            </div>
          </div>
        </v-tooltip>

        <v-menu
          v-if="!$_LiveChatMixin_isUserBroadcasting"
          offset-y
          style="z-index: 120"
          :nudge-width="100"
          :close-on-content-click="false"
        >
          <template #activator="{ on: action }">
            <RtbControlButton sm class="ml-1" v-on="{ ...action }">
              <v-icon dark small>more_vert</v-icon>
            </RtbControlButton>
          </template>

          <v-list dark>
            <v-list-tile>
              <v-list-tile-title> <h3>Chat Extras</h3> </v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="isVip || isHost"
              @click="onBroadcast"
              :disabled="working"
              :class="{
                'text--accent-3 green--text':
                  !working && $_LiveChatMixin_isUserBroadcasting
              }"
            >
              <v-list-tile-title>
                <SvgIcon name="broadcast" width="20" height="20" class="mr-2" />
                Broadcast to all
              </v-list-tile-title>
            </v-list-tile>
            <v-list-tile class="live-chat__menu-volume">
              <v-list-tile-title>
                <VolumeSlider
                  class="live-chat__volume-slider"
                  v-model="$_LiveChatMixin_volume"
                >
                  Chat Vol.
                </VolumeSlider>
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>

        <v-tooltip bottom v-if="!!room && !$_LiveChatMixin_isUserBroadcasting">
          <template v-slot:activator="{ on }">
            <RtbControlButton
              :disabled="$_LiveChatMixin_isUserBroadcasting"
              sm
              v-on="on"
              @click="onDisconnect"
              class="pink--text ml-1"
            >
              <SvgIcon name="exit" width="16" height="16" />
            </RtbControlButton>
          </template>
          <span class="bottom"> Exit Video Chat </span>
        </v-tooltip>
      </div>
    </div>

    <v-layout
      row
      wrap
      class="live-chat__players"
      v-if="!$_LiveChatMixin_isUserBroadcasting"
    >
      <v-flex xs6 pa-1>
        <LobbyUserCard
          v-if="!!videoTrack && !!currentUser"
          :user="currentUser"
          :videoTrack="videoTrack"
          @toggleMute="muteUser"
        />
        <LiveChatDummySlot v-else />
        <!-- </v-layout> -->
      </v-flex>

      <v-flex
        v-if="roomUsers.length === 0"
        pa-2
        md6
        class="[ grey--text text--lighten-1 ]"
      >
        <p>
          This is your chat room. At the moment, no one can see you or hear you.
        </p>
        <p>Add players from the recommended list below.</p>
      </v-flex>
      <v-flex
        md6
        pa-1
        v-for="(player, i) in roomUsers"
        :key="`live-chat-slot-${i}`"
      >
        <transition name="flip-transition" mode="out-in">
          <LobbyUserCard
            v-if="!!player"
            :key="`player-slot-${player.id}-${i}`"
            :user="player"
            @toggleMute="muteUser"
          />
          <LiveChatDummySlot v-else :key="`player-dummy-${i}`" />
        </transition>
      </v-flex>
    </v-layout>

    <p v-else class="[ pl-2 pt-4 ] [ grey--text text--lighten-1 ]">
      {{
        isVip || isHost
          ? `Select people from below to join the broadcast`
          : `You cannot video chat while broadcasting`
      }}
    </p>

    <UserAudio
      v-for="user in audio"
      :volume="$_LiveChatMixin_volumeComputed"
      :key="`user-audio-${user.id}`"
      :track="user.track"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex"
import { db } from "@/firebase"
import {
  RtbPillButton,
  RtbLink,
  RtbControlButton,
  RtbButton
} from "@/components/ui"

import { ActionTypes as TwilioModuleActionTypes } from "@/store/TwilioModule"

import ClientUsersConsumer from "@/modules/users/components/ClientUsersConsumer"
import ClientGamesConsumer from "@/consumers/ClientGamesConsumer"
import UserVolumeSlider from "@/components/GroupTeams/Misc/UserVolumeSlider.vue"
import VolumeSlider from "@/components/GroupTeams/Misc/VolumeSlider"

import LobbyUserCard from "@/components/GroupTeams/Common/Player/LobbyUserCard/LobbyUserCard.vue"

import LiveChatDummySlot from "@/components/LiveChatDummySlot"
import LobbyPlayerSlot from "@/components/GroupTeams/Common/Player/LobbyPlayerSlot"
import SvgIcon from "@/components/base/SvgIcon.vue"
import UserAudio from "@/components/GroupTeams/Common/User/UserAudio"
import LiveChatMixin from "@/mixins/LiveChat"

import { Role } from "@/helpers"
import { playSound } from "@/helpers/playSound.js"
// assets
import ArriveSound from "@/assets/sounds/arrive.mp3"

import LeaveSound from "@/assets/sounds/leave.mp3"
import UserSoundService from "@/services/user-sound.service"

const FTUX_OFFSET = 1000 * 30
const FTUX_PRESET = 5000

export default ClientUsersConsumer.extend(
  ClientGamesConsumer.extend({
    name: "LiveChat",
    components: {
      LobbyUserCard,
      LiveChatDummySlot,
      LobbyPlayerSlot,
      SvgIcon,
      UserAudio,
      RtbPillButton,
      RtbLink,
      RtbControlButton,
      UserVolumeSlider,
      RtbButton,
      VolumeSlider
    },

    mixins: [LiveChatMixin],

    props: {
      flashGames: {
        type: Array,
        default: () => []
      },
      selectedGameID: {
        type: String
      }
    },

    data() {
      return {
        videoTrack: null,
        optionalUsersIDs: [],
        ftuxVisibility: false,
        ftuxShadowStyle: {},
        working: false,
        ftuxTaskID: null,
        ftuxNotificationUnlockByGame: false,
        ftuxNotificationUnlockByTimeout: false
      }
    },
    async created() {
      await this.startVideo()
    },
    mounted() {
      setTimeout(
        () => (this.ftuxNotificationUnlockByTimeout = true),
        FTUX_PRESET
      )
    },
    async beforeDestroy() {
      this.ftuxTaskID && this.$queue.kill(this.ftuxTaskID)
      this.stopVideo()
      await this.removeAllSubscriptions()
      this.$_LiveChatMixin_exitBroadcast()
    },
    watch: {
      isFtuxAvailable: {
        handler(value) {
          if (value) {
            const action = () =>
              new Promise(resolve => {
                const { height, width, x, y } =
                  this.$refs.lockBtn.getBoundingClientRect()
                this.ftuxShadowStyle = {
                  height: height + `px`,
                  width: width + `px`,
                  top: y + `px`,
                  left: x + `px`,
                  opacity: 1
                }
                this.ftuxVisibility = true
                const unwatch = this.$watch("ftuxVisibility", value => {
                  if (!value) {
                    unwatch()
                    resolve()
                  }
                })
              })

            const { id } = this.$queue.enq({ priority: 1, action })
            this.ftuxTaskID = id
          }
        },
        immediate: true
      },
      isFtuxNotificationWithinTimeRange: {
        handler(value) {
          this.ftuxNotificationUnlockByGame = value
        },
        immediate: true
      },
      helpRequestedGamesLength(newValue, oldValue) {
        if (newValue > oldValue && this.isHost) playSound(HelpRequestedSound)
      },
      optionalUsersIDs(ids) {
        if (ids) {
          this.refreshSubscriptions({
            usersIDs: ids,
            /**
             * This function will be called when one of random users joins to another room
             * @param roomID
             * @param userID
             */
            callback: ({ roomID, userID }) => {
              if (roomID !== this.roomID) {
                const index = this.optionalUsersIDs.indexOf(userID)
                if (index >= 0) {
                  this.optionalUsersIDs.splice(index, 1)
                }
              }
            }
          })
        }
      },
      roomUsers(newValue, previousValue) {
        const { length: currentUssersCount } = newValue
        const { length: previousUsersCount } = previousValue
        if (currentUssersCount > previousUsersCount) {
          this.onParticipantConnected()
        } else if (currentUssersCount < previousUsersCount) {
          this.onParticipantDisconnedcted()
        }
      },
      isMutedAndConnected(value) {
        if (value) {
          this.twilioUsers[this.currentUser.id]?.audioTrack?.disable()
        } else {
          this.twilioUsers[this.currentUser.id]?.audioTrack?.enable()
        }
      }
    },
    computed: {
      ...mapGetters("auth", [
        "user",
        "access",
        "client",
        "isHost",
        "clientID",
        "camera"
      ]),
      ...mapState(["orgID"]),
      ...mapGetters("twilio", { twilioUsers: "users" }),
      ...mapGetters("livechat", ["max", "room", "roomID"]),
      isFtuxNotificationWithinTimeRange() {
        const game = this.$store.getters.actualGame
        // if no game assigned
        if (!game) return true
        const startTime = parseInt(game.startTimestamp)
        // if start time is NaN
        if (isNaN(startTime)) return true
        // or it's in the past
        if (startTime + FTUX_PRESET < Date.now()) return true
        const currentTime = this.$store.getters.time
        // show with respect to offset
        return currentTime + FTUX_OFFSET < startTime
      },
      onlineUsersByID() {
        return this.onlineUsers.reduce((acc, user) => {
          acc[user.id] = user
          return acc
        }, {})
      },
      currentUser() {
        const muted = this.room?.users?.[this.user.id]?.muted
        return {
          ...this.user,
          ...(muted !== undefined && { muted }),
          reaction: !!this.room?.users?.[this.user.id]?.reaction
        }
      },
      isMutedAndConnected() {
        return Boolean(
          this.currentUser.muted && this.twilioUsers[this.currentUser.id]
        )
      },
      roomUsers() {
        return Object.values(this.room?.users || {})
          .reduce((acc, user) => {
            const userData = this.onlineUsersByID?.[user.userID]
            const isActive = user.status === "active"
            const notCurrentUser = user.userID !== this.user.id
            if (user && userData && isActive && notCurrentUser) {
              acc.push({
                ...userData,
                reaction: user.reaction,
                muted: user.muted
              })
            }
            return acc
          }, [])
          .sort((a, b) => b.firstname - a.firstname)
      },
      optionalUsers() {
        const onlineUsers = this.onlineUsers
        return this.optionalUsersIDs
          .map(userID => onlineUsers.find(user => user.id === userID))
          .filter(user => user)
      },
      isRoomLocked() {
        return this.room ? !!this.room.locked : false
      },
      isChatLocked() {
        return this.room
          ? this.isRoomLocked
          : this.$_LiveChatMixin_isUserLocked(this.user)
      },
      isVip() {
        return this.user.vip
      },
      audio() {
        const twilioUsers = this.twilioUsers
        return this.roomUsers.reduce((acc, { id, muted }) => {
          const track = twilioUsers?.[id]?.audioTrack

          if (track && !muted) {
            acc.push({ id, track })
          }
          return acc
        }, [])
      },
      isUserOnline() {
        return this.twilioUsers
          ? !!this.twilioUsers[this.user.id] || this.room
          : false
      },
      isFtuxAvailable() {
        return Boolean(
          !this.client?.disableLiveChatFtux &&
            !this.user?.hasLiveChatFtux &&
            this.ftuxNotificationUnlockByGame &&
            this.ftuxNotificationUnlockByTimeout
        )
      }
    },
    methods: {
      ...mapActions("twilio", [
        TwilioModuleActionTypes.CREATE_LOCAL_VIDEO_TRACK
      ]),
      ...mapActions("livechat", [
        "toggleRoomLock",
        "isUserBusy",
        "toggleUserLock",
        "refreshSubscriptions",
        "removeAllSubscriptions"
      ]),
      isFlashGameSelected({ gameID }) {
        return gameID && gameID === this.selectedGameID
      },
      onFlashGameSelect({ gameID }) {
        this.$emit("onFlashGameSelect", { gameID })
      },
      getPendingGamesIDs() {
        return this.games.filter(({ started }) => !started).map(({ id }) => id)
      },
      anonymousUsers() {
        return this.client ? !!this.client.anonymousUsers : false
      },
      filterByAccess(value) {
        const array = Array.isArray(value) ? value : []
        return array.filter(({ role }) => {
          if (!this.anonymousUsers || this.isHost) {
            return true
          } else {
            return role !== Role.Host
          }
        })
      },
      async onToggleChatLock() {
        if (this.room) {
          await this.toggleRoomLock()
        } else {
          await this.$store.dispatch("updateUser", {
            userID: this.user.id,
            obj: {
              liveChatLocked: !this.user?.liveChatLocked
            }
          })
        }
      },
      async denyFtux() {
        if (!this.isChatLocked) {
          await this.onToggleChatLock()
        }
        await this.switchOffFtux()
      },
      async allowFtux() {
        if (this.isChatLocked) {
          await this.onToggleChatLock()
        }
        await this.switchOffFtux()
      },
      async switchOffFtux() {
        this.ftuxVisibility = false
        await this.$store.dispatch("updateUser", {
          userID: this.user.id,
          obj: {
            hasLiveChatFtux: true
          }
        })
      },
      onGameClick({ gameID }) {
        this.$emit("onGameClick", { gameID, scroll: true })
      },
      onPlayerInvite({ userID }) {
        this.working = true
        this.$emit("onPlayerInvite", { userID })
      },
      async startVideo() {
        this.videoTrack = null
        this.stopVideo()
        try {
          this.videoTrack = await this[
            TwilioModuleActionTypes.CREATE_LOCAL_VIDEO_TRACK
          ]()
        } catch (e) {
          if (this.camera) this.$info(e.message)
        }
      },
      stopVideo() {
        if (this.videoTrack && this.videoTrack.stop) this.videoTrack.stop()
      },
      onDisconnect() {
        this.$store.dispatch("livechat/end")
      },
      checkUsersGame() {
        const optionalUsersIDs = this.optionalUsersIDs
        const games = this.games
        const optionalUsers = this.optionalUsers
        optionalUsers.forEach(user => {
          if (games.find(game => game.id === user.gameID)?.started) {
            const index = optionalUsersIDs.indexOf(user.id)
            this.optionalUsersIDs.splice(index, 1)
          }
        })
      },
      onParticipantConnected() {
        playSound(ArriveSound)
      },
      onParticipantDisconnedcted() {
        playSound(LeaveSound)
      },
      muteUser({ id, muted }) {
        if (id === this.user.id || this.isHost) {
          db.auxiliary()
            .ref(
              `client/${this.clientID}/calls/rooms/${this.roomID}/users/${id}/muted`
            )
            .set(!muted)
        }

        if (this.user.id === id) {
          UserSoundService.toggle(this.user)
        }
      },
      async onBroadcast() {
        if (this.working) return
        this.working = true
        try {
          if (this.$_LiveChatMixin_streamRoomID) {
            await this.$_LiveChatMixin_stopBroadcast()
          } else {
            let roomID = this.roomID
            // initiate a new solo room
            if (!roomID) {
              roomID = await this.$store.dispatch("livechat/initiate", {
                priority: 2
              })
            }

            await this.$_LiveChatMixin_startBroadcast(roomID)
          }
        } catch (e) {
          console.error(e)
        }
        this.working = false
      }
    }
  })
)
</script>

<style lang="scss">
.live-chat {
  margin-bottom: 24px;
  position: relative;

  .flip-transition-enter-active,
  .flip-transition-leave-active {
    transition: opacity ease 0.3s;
    opacity: 1;
    // transform: rotateX(0deg);
  }
  .flip-transition-enter {
    opacity: 0;
    // transform: rotateY(90deg);
  }
  .flip-transition-leave-to {
    opacity: 0;
    // transform: rotateY(-90deg);
  }

  .raised-hand {
    display: inline;
  }
  .live-chat-container {
    padding-bottom: 15px;
  }

  &__ftux-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 900;
  }

  &__ftux-shadow {
    position: fixed;
    opacity: 0;
    z-index: 900;
    box-shadow: 0 0 0 10000px rgba(22, 17, 26, 0.5);
    transition: opacity 0.5s;
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
    align-items: center;
  }

  &__header {
    font-style: normal;
    font-family: "Sofia Pro", san-serif;
    font-weight: 900;
    font-size: 22px;
    line-height: 28px;
    margin-right: 8px;
  }

  &__controls {
    justify-content: end;
    font-weight: 500;
    display: flex;
    flex: 1;
  }

  &__menu-volume {
    height: 42px;
  }

  &__volume-slider {
    padding: 0;
    margin-right: 4px;
    width: 200px;
  }

  &__control-item {
    padding-left: 4px;
    padding-right: 4px;
    cursor: pointer;
    color: $color-grey-light4;
    font-size: 13px;

    i {
      margin-right: 3px;
    }

    &:hover {
      color: $color-correct;
    }

    &--success {
      color: $color-correct;
      &:hover {
        color: $color-grey-light4;
      }
    }

    &--danger {
      color: $color-wrong;
      &:hover {
        color: lighten($color-wrong, 10);
      }
    }
  }

  &__players {
    align-content: flex-start;
    .weve-user-card {
      aspect-ratio: 1/1;
    }
  }

  .live-chat-text {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    color: $color-grey2;
    font-weight: 400;
    margin-right: 10px;
  }
  .live-chat-status-container {
    white-space: nowrap;
  }
  .live-chat-status {
    display: inline-block;
    vertical-align: middle;
    color: $color-wrong;
    font-size: 16px;
    line-height: 22px;
    margin-right: 4px;
    margin-top: -2px;
    margin-bottom: -2px;
    // margin-left: -5px;
    &.on {
      color: $color-correct;
    }
  }
  .audio-element {
    pointer-events: none;
    position: fixed;
    bottom: 0;
    right: 0;
  }
}

@media (max-width: 1300px) {
  .live-chat {
    .live-chat-container {
      padding-bottom: 10px;
    }
  }
}

.ftux-tooltip {
  opacity: 1 !important;
  background-color: #f8f9fb;
}

.ftux-unit {
  width: 180px;
  h4 {
    font-size: 16px;
    font-weight: 900;
  }
  p {
    font-size: 14px;
    font-weight: 400;
  }

  .v-btn {
    font-size: 12px;
    font-weight: 700;
    text-transform: initial;
    margin: 0;
    min-width: auto;
    border-width: 2px;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
  }
}
</style>
