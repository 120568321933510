var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-flex", { staticClass: "room-detail", attrs: { grow: "" } }, [
    _c("div", { staticClass: "room-detail-image", style: _vm.headStyle }),
    _c(
      "div",
      { staticClass: "room-detail-info" },
      [
        _c("div", { staticClass: "main-title" }, [_vm._v(_vm._s(_vm.name))]),
        _c("div", [_vm._v(_vm._s(_vm.game.description))]),
        _vm.game && !_vm.game.stream
          ? _c(
              "v-flex",
              { attrs: { column: "" } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "increase-video-size-button",
                    attrs: { flat: "" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("onVideoDrawer")
                      },
                    },
                  },
                  [_vm._v(" Increase Video Size ")]
                ),
              ],
              1
            )
          : _vm._e(),
        !_vm.videoDrawer && _vm.canPlay
          ? _c("v-layout", { attrs: { column: "" } }, [
              _vm.game.gameType === "YouTube" ||
              _vm.game.gameType === "Twitch" ||
              _vm.game.stream
                ? _c(
                    "div",
                    { staticStyle: { "margin-top": "20px" } },
                    [
                      _c("GameYouTube", {
                        attrs: { game: _vm.game, canPlay: _vm.canPlay },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.game.gameType === "Our Video"
                ? _c(
                    "div",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { height: "50vh" },
                    },
                    [
                      _c("GameVideo", {
                        attrs: {
                          game: _vm.game,
                          canPlay: _vm.canPlay,
                          controls: _vm.isHost,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.game.gameType === "Image"
                ? _c("div", { staticStyle: { "margin-top": "20px" } }, [
                    _vm.game.image !== undefined
                      ? _c("img", {
                          staticClass:
                            "absolute top-0 left-0 w-full h-full object-cover",
                          attrs: { src: _vm.game.image },
                        })
                      : _vm._e(),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }