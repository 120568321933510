import { render, staticRenderFns } from "./New.vue?vue&type=template&id=122c8850&"
import script from "./New.vue?vue&type=script&lang=js&"
export * from "./New.vue?vue&type=script&lang=js&"
import style0 from "./New.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/Users/dkurbatov/Documents/TGG/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('122c8850')) {
      api.createRecord('122c8850', component.options)
    } else {
      api.reload('122c8850', component.options)
    }
    module.hot.accept("./New.vue?vue&type=template&id=122c8850&", function () {
      api.rerender('122c8850', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Chat/LobbyChat/New.vue"
export default component.exports