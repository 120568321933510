<template>
  <v-flex d-flex class="video-group rtb-d-flex">
    <v-layout column>
      <div class="video-groups-title" style="margin-bottom: 10px">
        Video Groups
      </div>
      <div class="video-group__scrollable">
        <div class="rtb-d-flex flex-column">
          <VideoGroupPlayers
            v-for="(room, index) in rooms"
            :key="room.id"
            :users="getUsersByRoom(room)"
            :room="room"
            :class="{ 'mt-2': index > 0 }"
          />
        </div>
      </div>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex"
import { db } from "@/firebase"
import { orderBy } from "lodash"
import { Role } from "@/helpers"

import ClientUsersConsumer from "@/modules/users/components/ClientUsersConsumer"
import VideoGroupPlayers from "@/components/Lobby/VideoGroupPlayers.vue"

/**
 * @param { users?: Record<string, string> }
 */
function byUsersCountPredicate(room) {
  return Object.keys(room.users ?? {}).length
}

export default ClientUsersConsumer.extend({
  name: "VideoGroup",
  components: {
    VideoGroupPlayers
  },
  data() {
    return {
      data: null
    }
  },
  beforeDestroy() {
    this.roomsRef?.off("value", this.onRoomsUpdate)
  },
  watch: {
    roomsRef: {
      handler(newValue, oldValue) {
        this.data = null
        oldValue?.off("value", this.onRoomsUpdate)
        newValue?.on("value", this.onRoomsUpdate)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters("auth", ["clientID", "user", "client", "isHost", "isSuper"]),
    broadcastID() {
      return this.client?.stream?.roomID
    },
    isVip() {
      return this.user.vip
    },
    roomsRef() {
      return this.isSuper
        ? db
            .auxiliary()
            .ref(`client/${this.clientID}/calls/rooms/`)
            .limitToFirst(20)
        : db
            .auxiliary()
            .ref(`client/${this.clientID}/calls/rooms/`)
            .orderByChild("locked")
            .equalTo(null)
            .limitToFirst(20)
    },
    rooms() {
      const { isHost, isVip, broadcastID } = this

      const sorted = orderBy(
        Object.entries(this.data || {}).reduce((acc, [id, _room]) => {
          if (id !== broadcastID || isHost || isVip) {
            const room = _room || {}
            room.id = id
            acc.push(room)
          }
          return acc
        }, []),
        byUsersCountPredicate,
        ["asc"]
      )

      // TODO: optimize this
      if (this.client?.anonymousUsers && !isHost) {
        const getUsersByRoom = this.getUsersByRoom
        return sorted.filter(
          room => !getUsersByRoom(room).every(user => user.role === Role.Host)
        )
      }

      return sorted
    }
  },
  methods: {
    onRoomsUpdate(snapshot) {
      this.data = snapshot.val()
    },
    getUsersByRoom(room) {
      const roomUserIDs = Object.values(room.users ?? {})
        .filter(user => user && user.userID && user.status === "active")
        .map(({ userID }) => userID)
      return this.users.filter(user => roomUserIDs.includes(user.id))
    }
  }
})
</script>
<style lang="scss">
.video-group {
  color: #fff;

  &__scrollable {
    direction: rtl;
    overflow: auto;

    > div {
      direction: ltr;
    }
  }
}
</style>
