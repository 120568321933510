



































import Vue from "vue"
import { playSound } from "@/helpers/playSound.js"
import ToolbarDropdown from "@/components/GroupTeams/Common/ToolbarDropdown.vue"
// @ts-ignore TODO
import HelpRequestedSound from "@/assets/sounds/ding.mp3"
export default Vue.extend({
  name: "RequestedGames",
  components: { ToolbarDropdown },
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: {
      type: [String, Boolean],
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    games: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    filtered() {
      return this.games.reduce((acc, val) => {
        if (val.helpRequested) acc.push(val)
        return acc
      }, [])
    },
    gamesCount() {
      return this.filtered.length
    }
  },
  watch: {
    gamesCount(newValue, oldValue) {
      if (newValue > oldValue) {
        playSound(HelpRequestedSound)
      }
    }
  }
})
