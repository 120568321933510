var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "room-detail rtb-d-flex flex-column flex-grow h-0" },
    [
      _c("div", { staticClass: "room-detail__image", style: _vm.imageStyle }),
      _c("div", { staticClass: "room-detail__info" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }