var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "live-chat" },
    [
      _vm.ftuxVisibility && _vm.isFtuxAvailable
        ? [
            _c("div", {
              staticClass: "live-chat__ftux-shadow",
              style: _vm.ftuxShadowStyle,
            }),
            _c("div", { staticClass: "live-chat__ftux-overlay" }),
          ]
        : _vm._e(),
      _c("div", { staticClass: "live-chat__top" }, [
        _c("div", { staticClass: "live-chat__header" }, [_vm._v("VIDEO CHAT")]),
        _c(
          "div",
          { staticClass: "live-chat__controls" },
          [
            _c(
              "v-tooltip",
              {
                attrs: {
                  "content-class": "ftux-tooltip",
                  bottom: "",
                  color: "white",
                  value: _vm.ftuxVisibility,
                  disabled: !_vm.isFtuxAvailable,
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      return [
                        _c(
                          "RtbControlButton",
                          _vm._g(
                            {
                              ref: "lockBtn",
                              staticClass: "whitespace-no-wrap",
                              class: {
                                "pink--text": _vm.isChatLocked,
                                "text--accent-3 green--text": !_vm.isChatLocked,
                              },
                              attrs: { sm: "" },
                              on: { click: _vm.onToggleChatLock },
                            },
                            on
                          ),
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  color: _vm.isChatLocked
                                    ? "pink"
                                    : "green accent-3",
                                  small: "",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.isChatLocked ? "lock" : "lock_open"
                                  )
                                ),
                              ]
                            ),
                            _vm._v(
                              "Chat " +
                                _vm._s(_vm.isChatLocked ? "Closed" : "Open") +
                                " "
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("div", { staticClass: "bottom black--text ftux-unit" }, [
                  _c("h4", [_vm._v("Open to video chat?")]),
                  _c("p", [
                    _vm._v(
                      " Would you like other attendees to be able to video chat with you? "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ftux-unit__controls" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outline: "",
                            small: "",
                            color: "red lighten-1",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.denyFtux()
                            },
                          },
                        },
                        [_vm._v(" No, close ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            dark: "",
                            depressed: "",
                            color: "red lighten-1",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.allowFtux()
                            },
                          },
                        },
                        [_vm._v(" Yes, keep open ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            !_vm.$_LiveChatMixin_isUserBroadcasting
              ? _c(
                  "v-menu",
                  {
                    staticStyle: { "z-index": "120" },
                    attrs: {
                      "offset-y": "",
                      "nudge-width": 100,
                      "close-on-content-click": false,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function (ref) {
                            var action = ref.on
                            return [
                              _c(
                                "RtbControlButton",
                                _vm._g(
                                  { staticClass: "ml-1", attrs: { sm: "" } },
                                  Object.assign({}, action)
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", small: "" } },
                                    [_vm._v("more_vert")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1058262625
                    ),
                  },
                  [
                    _c(
                      "v-list",
                      { attrs: { dark: "" } },
                      [
                        _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-title", [
                              _c("h3", [_vm._v("Chat Extras")]),
                            ]),
                          ],
                          1
                        ),
                        _vm.isVip || _vm.isHost
                          ? _c(
                              "v-list-tile",
                              {
                                class: {
                                  "text--accent-3 green--text":
                                    !_vm.working &&
                                    _vm.$_LiveChatMixin_isUserBroadcasting,
                                },
                                attrs: { disabled: _vm.working },
                                on: { click: _vm.onBroadcast },
                              },
                              [
                                _c(
                                  "v-list-tile-title",
                                  [
                                    _c("SvgIcon", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        name: "broadcast",
                                        width: "20",
                                        height: "20",
                                      },
                                    }),
                                    _vm._v(" Broadcast to all "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-list-tile",
                          { staticClass: "live-chat__menu-volume" },
                          [
                            _c(
                              "v-list-tile-title",
                              [
                                _c(
                                  "VolumeSlider",
                                  {
                                    staticClass: "live-chat__volume-slider",
                                    model: {
                                      value: _vm.$_LiveChatMixin_volume,
                                      callback: function ($$v) {
                                        _vm.$_LiveChatMixin_volume = $$v
                                      },
                                      expression: "$_LiveChatMixin_volume",
                                    },
                                  },
                                  [_vm._v(" Chat Vol. ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !!_vm.room && !_vm.$_LiveChatMixin_isUserBroadcasting
              ? _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "RtbControlButton",
                                _vm._g(
                                  {
                                    staticClass: "pink--text ml-1",
                                    attrs: {
                                      disabled:
                                        _vm.$_LiveChatMixin_isUserBroadcasting,
                                      sm: "",
                                    },
                                    on: { click: _vm.onDisconnect },
                                  },
                                  on
                                ),
                                [
                                  _c("SvgIcon", {
                                    attrs: {
                                      name: "exit",
                                      width: "16",
                                      height: "16",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3383474493
                    ),
                  },
                  [
                    _c("span", { staticClass: "bottom" }, [
                      _vm._v(" Exit Video Chat "),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      !_vm.$_LiveChatMixin_isUserBroadcasting
        ? _c(
            "v-layout",
            { staticClass: "live-chat__players", attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs6: "", "pa-1": "" } },
                [
                  !!_vm.videoTrack && !!_vm.currentUser
                    ? _c("LobbyUserCard", {
                        attrs: {
                          user: _vm.currentUser,
                          videoTrack: _vm.videoTrack,
                        },
                        on: { toggleMute: _vm.muteUser },
                      })
                    : _c("LiveChatDummySlot"),
                ],
                1
              ),
              _vm.roomUsers.length === 0
                ? _c(
                    "v-flex",
                    {
                      staticClass: "[ grey--text text--lighten-1 ]",
                      attrs: { "pa-2": "", md6: "" },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          " This is your chat room. At the moment, no one can see you or hear you. "
                        ),
                      ]),
                      _c("p", [
                        _vm._v("Add players from the recommended list below."),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._l(_vm.roomUsers, function (player, i) {
                return _c(
                  "v-flex",
                  {
                    key: "live-chat-slot-" + i,
                    attrs: { md6: "", "pa-1": "" },
                  },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "flip-transition", mode: "out-in" } },
                      [
                        !!player
                          ? _c("LobbyUserCard", {
                              key: "player-slot-" + player.id + "-" + i,
                              attrs: { user: player },
                              on: { toggleMute: _vm.muteUser },
                            })
                          : _c("LiveChatDummySlot", {
                              key: "player-dummy-" + i,
                            }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _c(
            "p",
            { staticClass: "[ pl-2 pt-4 ] [ grey--text text--lighten-1 ]" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.isVip || _vm.isHost
                      ? "Select people from below to join the broadcast"
                      : "You cannot video chat while broadcasting"
                  ) +
                  " "
              ),
            ]
          ),
      _vm._l(_vm.audio, function (user) {
        return _c("UserAudio", {
          key: "user-audio-" + user.id,
          attrs: {
            volume: _vm.$_LiveChatMixin_volumeComputed,
            track: user.track,
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }