













































import { defineComponent } from "@vue/composition-api"

import GameYouTube from "@/components/Lobby/GameYouTube.vue"
import GameVideo from "@/components/Lobby/GameVideo.vue"
import useLobbyRoom from "@/use/useLobbyRoom"

export default defineComponent({
  name: "GameInfoVideo",
  components: {
    GameYouTube,
    GameVideo
  },
  props: {
    canPlay: Boolean,
    videoDrawer: Boolean
  },
  setup() {
    const { game, name, headStyle, isHost } = useLobbyRoom()
    return { game, name, headStyle, isHost }
  }
})
