









import Vue from "vue"
export default Vue.extend({
  name: "RoomDetailLayout",
  props: {
    imageTitle: String
  },
  computed: {
    imageStyle() {
      return {
        backgroundImage: `
          linear-gradient(
            360deg,
            rgba(0, 0, 0, 0.6) 20%,
            rgba(0, 0, 0, 0) 100%
          ),
          url(${this.imageTitle})
        `
      }
    }
  }
})
