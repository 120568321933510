<template>
  <v-layout
    column
    shrink
    d-flex
    :class="!showChatHead ? 'lobby-chat-container' : ''"
  >
    <Chat
      v-if="showChatBody"
      :teamID="getTeamID"
      :roomID="getChatRoomID"
      :context="getChatContext"
      @onSelectedGameIDUpdate="data => $emit('onSelectedGameIDUpdate', data)"
      ref="chatComponent"
      :placeholder="messagePlaceholder"
      :canSendMessage="canSendMessage"
      :isAnonymousChat="isAnonymousChat"
      :messageText.sync="message"
    >
      <template #chat-header>
        <div class="lobby-chat-container__header">
          <AnonymousToggle
            v-if="isSuper"
            @toggleAnonymousChat="toggleAnonymousChat"
            :status="Boolean(client.anonymousChat)"
          />
          <div class="lobby-chat-container__header--primary">
            You’re chatting with the lobby
          </div>
        </div>
        <div
          class="lobby-chat-container__header-context-toggle"
          v-if="availableChatContext.length"
        >
          <ChatContextToggle
            style="margin-top: 14px"
            :teamID="getTeamID"
            @localContextChange="onLocalContextChange"
            :context="getChatContext"
            :contexts="availableChatContext"
            :initialContext="getChatContext"
          />
        </div>
        <div
          class="lobby-chat-container__header--close"
          @click="$emit('closeChat')"
        ></div>
      </template>

      <template #chat-messages="{ data: chat }">
        <v-layout grow class="lobby-chat-container__messages-wrap">
          <Messages
            :messages="chat.messages"
            :teamID="chat.teamID"
            :contextRef="chat.contextRef"
            :canLazyLoadMessage="chat.canLazyLoadMessage"
            :context="chat.context"
            @LAZYLOAD_MESSAGE="chat.LAZYLOAD_MESSAGE"
            @onSelectedGameIDUpdate="chat.onSelectedGameIDUpdate"
          >
            <template #messages>
              <v-flex
                flex-end
                v-for="(data, index) in chat.messages"
                :key="`${index}-${data.key}`"
                class="lobby-chat-container__message-wrap"
              >
                <v-layout
                  column
                  class="lobby-chat-container__message-wrap--contain"
                  :class="
                    user.id === data.messagePayload.fromID
                      ? 'right-message'
                      : 'left-message'
                  "
                >
                  <div
                    class="lobby-chat-container__message-wrap--contain___time flex align-items-center"
                    v-if="user.id !== data.messagePayload.fromID"
                  >
                    <UserName
                      :firstname="data.userDetail.firstname"
                      :lastname="data.userDetail.lastname"
                    />
                    <svg-icon
                      v-if="data.userDetail.role === 'facilitator'"
                      style="margin-bottom: 5px"
                      class="ml-1"
                      size="small"
                      name="union"
                    />
                    —
                    {{ data.messagePayload.timestamp | messageTime }}
                  </div>
                  <div
                    class="message"
                    :class="{
                      'message--danger':
                        isHost && data.messagePayload.message.includes('@host')
                    }"
                    v-html="
                      $options.filters.normalizeMessage(
                        data.messagePayload.message
                      )
                    "
                  ></div>
                </v-layout>
              </v-flex>
            </template>
          </Messages>
        </v-layout>
      </template>

      <template #chat-loader>
        <div class="lobby-chat-container__chat--loading">
          <rtb-spinner />
        </div>
      </template>
    </Chat>

    <ChatHead v-else :teamID="getTeamID" :context="getChatContext">
      <template #chat-head-label>
        <v-flex class="lobby-chat-container__chat-head--label">
          <slot>
            <div>CHAT</div>
          </slot>
          <div
            v-if="allUnreadMessage"
            class="lobby-chat-container__chat-head--label__unread"
          >
            <span>{{ allUnreadMessage | unreadMessageFormat }}</span>
          </div>
        </v-flex>
      </template>
    </ChatHead>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import ChatWrap from "@/components/Chat/ChatWrap.vue"
import ChatHead from "@/components/Chat/ChatHead.vue"
import Messages from "@/components/Chat/Messages.vue"
import UserName from "@/components/GroupTeams/Common/User/UserName.vue"
import { RtbSpinner } from "@/components/ui"
import ChatContextToggle from "../ChatContextToggle.vue"
import AnonymousToggle from "../AnonymousToggle.vue"

import { debounce } from "lodash"

import { ALL_CHAT_CONTEXT } from "../constants"

export default ChatWrap.extend({
  name: "NewLobbyChatWrap",
  components: {
    Messages,
    UserName,
    RtbSpinner,
    ChatHead,
    ChatContextToggle,
    AnonymousToggle
  },
  data() {
    return {
      message: null
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "client", "isSuper"])
  },
  props: {
    showChatBody: {
      type: Boolean,
      default: false
    },
    showChatHead: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    availableChatOptionsWatchValue: {
      handler: debounce(async function (newContexts, oldContexts) {
        await this.onWatchAvailableChatOptionsWatchValue(
          newContexts,
          oldContexts,
          ALL_CHAT_CONTEXT
        )
      }, 500),
      immediate: true
    },
    getChatContext: {
      handler: debounce(async function (newContexts, oldContexts) {
        await this.onWatchGetChatContext(
          newContexts,
          oldContexts,
          ALL_CHAT_CONTEXT
        )
      }, 500),
      immediate: true
    },
    showChatBody: {
      handler(val) {
        this.isChatVisible = val
      },
      immediate: true
    }
  },
  methods: {
    toggleAnonymousChat() {
      const { anonymousChat } = this.client
      const clientUpdate = {
        id: this.clientID,
        anonymousChat: !anonymousChat
      }
      this.$services
        .get("client")
        .then(service => service.updateClient(clientUpdate))
    }
  }
})
</script>

<style lang="scss">
.lobby-chat-container {
  $messages-background-color: #393b42;
  z-index: 222;
  display: flex;
  width: 364px;
  height: 565px;
  box-shadow: 0px 37px 48px rgba(15, 15, 17, 0.8);
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 27px;
    left: 100%;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #383c41;
  }

  &__chat-body {
    width: 100%;
    height: 99%;
    margin: 2px;
  }

  &__chat-head {
    justify-content: center;
    justify-self: center;
    flex-direction: column;
    place-items: center;
    width: 100%;
    &--label {
      display: flex;
      flex-direction: row;
      &__unread {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
        color: white;
        border-radius: 50%;
        font-size: 14px;
        background-color: $primary-accent-color !important;
        border-color: $primary-accent-color !important;
        margin-top: -10px;
        margin-left: -2px;
      }
    }
  }

  &__header {
    height: 96px;
    background: $primary-accent-color;
    border-radius: 3px 3px 0px 0px;
    padding: 5px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &--anonymous-btn {
      position: absolute;
      left: -4px;
      top: -4px;
      .v-btn--floating.v-btn--small {
        height: 30px;
        width: 30px;
      }
    }
    &--primary {
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #f8f9fb;
    }
    &--secondary {
      font-family: Sofia Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #f8f9fb;
    }
    &--close {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      color: white;
      border-radius: 50%;
      font-size: 14px;
      background-color: $primary-accent-color;
      position: absolute;
      right: -8px;
      top: -8px;
      cursor: pointer;
      &::before,
      &::after {
        content: "";
        display: block;
        background: white;
        height: 70%;
        width: 1px;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
      &::hover {
        filter: brightness(1.99);
      }
    }

    &-context-toggle {
      display: flex;
      justify-content: center;
      background: $messages-background-color;

      .v-btn-toggle--selected {
        box-shadow: none;
      }
    }
  }

  &__chat--loading {
    display: grid;
    place-items: center;
    height: 99%;
    background: #393b42;
  }

  &__messages-wrap {
    overflow-y: auto;
    height: 0px;
    background: $messages-background-color;
    box-shadow: 0px 37px 48px rgba(15, 15, 17, 0.8);
    .chat-message-body {
      background: inherit;
      position: relative;
      min-height: inherit;
      max-height: inherit;
      &::-webkit-scrollbar {
        width: 5px !important;
        background: #9aa1ad !important;
        border-radius: 12px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #9aa1ad !important;
      }
    }
  }

  &__message-wrap {
    display: flex;
    min-height: 45px;

    &--contain {
      display: flex;
      align-items: flex-start;
      text-align: start;
      padding: 9px 16px;
      width: 80%;
      .message {
        border-radius: 4px;
        padding: 2px 12px;
        overflow: hidden;
        overflow-wrap: break-word;
        font-size: 14px;
        width: inherit;
        font-family: Sofia Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        a {
          color: white;
        }
      }
      &.right-message {
        align-items: flex-end;
        & .message {
          background: $primary-accent-color;
          box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.05);
        }
      }
      &.left-message {
        align-items: flex-start;
        & .message {
          background-color: #292932;
          color: #fff;

          &--danger {
            background: $wrong-color;
          }
        }
      }
      &___time {
        margin-bottom: 2px;
        line-height: 20px;
        font-family: Sofia Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #f8f9fb;
      }
    }
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e8e9eb;
    border-radius: 0px 0px 4px 4px;
    .text-area {
      width: 100%;
    }
    textarea {
      line-height: unset;
      padding: 12px;
      max-width: 100%;
      width: 100%;
      max-height: 30vh;
    }
    &--submit-btn {
      width: 63px;
      height: 37px;
      background: $primary-accent-color !important;
      border-radius: 2px;
      font-weight: bolder;
      &.v-btn {
        color: #ffffff;
        min-width: 0px;
        margin: 5px 4px;
      }
    }
  }
}
</style>
