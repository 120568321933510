<template>
  <v-flex d-flex class="pre-game-user-list">
    <RecycleScroller
      class="pre-game-user-list__recycle-scroller"
      :items="chunks"
      :item-size="110"
      key-field="id"
      v-slot="{ item }"
      ref="scroller"
    >
      <div
        v-for="user in item.users"
        :key="user.id"
        class="pre-game-user-list__column"
        :class="{
          'pre-game-user-list__column--selected': user.id === selectedUserID
        }"
      >
        <LobbyPlayer
          :player="user"
          :showScore="showScore"
          class="pre-game-user-list__user"
          :userID="user.id"
          @onMouseOver="onMouseOver"
          @mouseleave.native="onMouseLeave"
          @click.alt.native="edit(user)"
          @click.shift.native="edit(user)"
        />
        <div mt-1 text-xs-center class="pre-game-user-list__username">
          {{ user.firstname }}
        </div>
      </div>
    </RecycleScroller>
  </v-flex>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import LobbyPlayer from "@/components/Lobby/Player.vue"
import { RecycleScroller } from "vue-virtual-scroller"
import "vue-virtual-scroller/dist/vue-virtual-scroller.css"

export default {
  name: "UserList",
  components: {
    RecycleScroller,
    LobbyPlayer
  },
  props: {
    selectedUserID: String,
    size: {
      type: Number,
      default: 4
    },
    users: {
      type: Array,
      required: true
    },
    showScore: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timeout: null
    }
  },
  beforeDestroy() {
    clearInterval(this.timeout)
  },
  computed: {
    ...mapGetters("auth", ["user", "isHost"]),
    chunks() {
      return _.chunk(this.users, this.size).map(users => ({
        users,
        id: users.reduce((acc, val) => acc + val.id, "")
      }))
    }
  },
  methods: {
    ...mapActions("UserSettings", ["updateUser"]),
    onMouseOver({ userID, $event }) {
      const rect = $event.target.getBoundingClientRect()
      const { x, y, height, width } = rect
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$emit("onSelect", { userID, x, y, height, width })
      }, 500)
    },
    onMouseLeave() {
      clearTimeout(this.timeout)
    },
    edit(user) {
      if (this.isHost) this.updateUser({ user })
    }
  }
}
</script>

<style lang="scss">
.pre-game-user-list {
  width: 100%;
  position: relative;

  .vue-recycle-scroller__item-view {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__recycle-scroller {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__column {
    padding-right: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    &--selected {
      .video-container:before {
        border-width: 40px;
      }
    }
  }

  &__username {
    font-size: 14px;
    max-width: 74px;
    color: $color-white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__user {
    min-width: 74px;
    max-width: 74px;
    cursor: pointer;
    .player-details {
      display: none !important;
    }
    .video-container:before {
      content: "";
      display: block;
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 0px solid $color-white;
      opacity: 0.5;
      transition: border-width 0.5s;
    }
    &:hover {
      .video-container:before {
        border-width: 40px;
      }
    }
  }
}
</style>
