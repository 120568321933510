var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "relative game-youtube",
      style: { height: _vm.height ? _vm.height : "40vh" },
    },
    [
      _c("WithAudioContext", {
        attrs: { disabled: _vm.$store.state.auth.client.disableSmartAudio },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var mute = ref.mute
              var unmute = ref.unmute
              return [
                _vm.game && _vm.game.id && _vm.game.stream
                  ? _c("TwilioStream", {
                      attrs: { id: _vm.game.id, type: _vm.StreamType.ROOM },
                    })
                  : _vm.isTwitch
                  ? _c("TAStream", {
                      attrs: { streamUrl: _vm.game.twitchChannel },
                      on: { play: mute, pause: unmute, end: unmute },
                    })
                  : [
                      _vm.isWatchParty
                        ? _c("YTPlayer", {
                            staticClass: "top-0 left-0 w-full h-full",
                            attrs: {
                              sync: _vm.isHost,
                              videoCode: _vm.game.youTubeUrl,
                              controls: _vm.isHost,
                              loop: _vm.game.videoLoop,
                            },
                            on: { play: mute, pause: unmute, end: unmute },
                            model: {
                              value: _vm.globalState,
                              callback: function ($$v) {
                                _vm.globalState = $$v
                              },
                              expression: "globalState",
                            },
                          })
                        : _c("YTPlayer", {
                            staticClass: "top-0 left-0 w-full h-full",
                            attrs: {
                              sync: "",
                              videoCode: _vm.game.youTubeUrl,
                              autoplay: _vm.game.youTubeAutoPlay,
                              controls: "",
                              loop: _vm.game.videoLoop,
                            },
                            on: { play: mute, pause: unmute, end: unmute },
                            model: {
                              value: _vm.localState,
                              callback: function ($$v) {
                                _vm.localState = $$v
                              },
                              expression: "localState",
                            },
                          }),
                    ],
              ]
            },
          },
        ]),
      }),
      _vm.game.watchParty
        ? _c(
            "div",
            {
              staticClass: "game-youtube__overlay",
              class: { "game-youtube--host": _vm.isHost },
            },
            [
              _vm.globalState.paused
                ? _c(
                    "div",
                    { staticClass: "game-youtube__tip" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "game-youtube__tip-icon",
                          attrs: { color: "#fff" },
                        },
                        [_vm._v("info")]
                      ),
                      _c("p", [_vm._v("Host has paused the movie")]),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }