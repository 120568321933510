// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/game-card-profile-icon-big.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* These colors are supposed to by dynamyc during the runtime */\n/* Please, don't place static colors here, if they are not supposed to be changed in runtime */\n/** Please, use this mixin as the SSOT **/\n/** $theme: \"light\" | \"dark\" **/\n.rtb-border-radius {\n  border-radius: 8px;\n}\n.rtb-border {\n  border: solid 0px var(--primary-accent-color);\n}\n.rtb-box-shadow {\n  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);\n}\n.room-info-icon {\n  user-select: none;\n}\n.room-info-icon .outline {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 92%;\n  background-position: 50% 100%;\n  background-repeat: no-repeat;\n  color: #e1e1e1;\n  border: 2px solid #68635f;\n  background-color: #68635f;\n  display: flex;\n  position: relative;\n  border-radius: 25%;\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n  line-height: 1.1;\n  font-size: 14px;\n}\n.room-info-icon .outline img {\n  min-width: 100%;\n  max-width: 100%;\n  max-height: 100%;\n  object-fit: cover;\n}", ""]);
// Exports
module.exports = exports;
