


































































import Vue, { PropType } from "vue"
import { mapActions, mapGetters } from "vuex"
import type { Game } from "@/types"
import { GameType } from "@/entities/GameType"

import WithAudioContext from "@/modules/audio/components/WithAudioContext"

import TwilioStream from "@/components/TwilioStream.vue"

import { StreamType } from "@/services/stream.service/enums"

export default Vue.extend({
  name: "GameYouTube",
  components: {
    WithAudioContext,
    TAStream: () => import("@/components/VideoPlayers/TAStream.vue"),
    YTPlayer: () => import("@/components/VideoPlayers/YTPlayer.vue"),
    TwilioStream
  },
  props: {
    game: {
      type: Object as PropType<Game>,
      required: true
    },
    height: {
      type: String
    },
    canPlay: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return { StreamType }
  },
  computed: {
    ...mapGetters("auth", ["isHost", "user"]),
    ...mapGetters("pregame", ["selectedKeynote"]),
    ...mapGetters("soundeffect", ["volume"]),
    isTwitch() {
      return this.game.gameType === GameType.Twitch
    },
    isWatchParty() {
      return this.game?.watchParty
    },
    globalState: {
      get() {
        const { videoPaused, videoStartedAt, videoCurrentTime } = this.game
        return {
          paused: (videoPaused ?? true) || !this.canPlay,
          currentTime: videoCurrentTime,
          startedAt: videoStartedAt
        }
      },
      set(value: { paused: string; currentTime: number; startedAt: number }) {
        this.updateGameAny({
          theKey: this.game.id,
          videoPaused: value.paused,
          videoCurrentTime: value.currentTime || null,
          videoStartedAt: value.startedAt || null
        })
      }
    },
    localState: {
      get() {
        const { currentTime, startedAt, paused } = this.selectedKeynote
        return {
          paused: paused || !this.canPlay,
          currentTime,
          startedAt
        }
      },
      set(value) {
        this.updateSelectedKeynote(value)
      }
    }
  },

  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    ...mapActions("pregame", ["updateSelectedKeynote"])
  }
})
