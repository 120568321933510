







































































































































































































































































































































































































































































































// @ts-nocheck
import { mapGetters, mapActions } from "vuex"
import { format } from "date-fns"

import ClientUsersConsumer from "@/modules/users/components/ClientUsersConsumer"
import ClientGamesConsumer from "@/consumers/ClientGamesConsumer"
import LobbyRooms from "@/components/Lobby/LobbyRooms.vue"
import {
  RtbSelect,
  RtbPillButton,
  RtbLink,
  RtbControlButton,
  RtbButton
} from "@/components/ui"
import Profile from "@/components/Lobby/Profile.vue"
import RequestedGames from "@/components/Lobby/RequestedGames.vue"
import GameInfo from "@/components/Lobby/GameInfo.vue"
import GameImage from "@/components/Lobby/GameImage.vue"
import GameInfoVideo from "@/components/Lobby/GameInfoVideo.vue"
import GamePlaceholder from "@/components/Lobby/GamePlaceholder.vue"
import LiveChat from "@/components/Lobby/LiveChat.vue"
import Popup from "@/components/Popups/Popup.vue"
import UserName from "@/components/GroupTeams/Common/User/UserName.vue"
import UserSearch from "@/components/Lobby/UserSearch.vue"
import LobbyChatWrap from "@/components/Chat/LobbyChat/New.vue"
import Recommended from "@/components/Lobby/Recommended.vue"
import VideoGroups from "@/components/Lobby/VideoGroups.vue"
import VideoDrawer from "@/components/Lobby/VideoDrawer.vue"
import ActivityFeed from "@/components/Lobby/ActivityFeed.vue"
import VenmoModal from "@/components/GroupTeams/Common/VenmoModal.vue"
import LobbyTabs from "@/components/Lobby/LobbyTabs.vue"
import RoundMover from "@/components/Lobby/RoundMover.vue"
import LobbyTitle from "@/components/Lobby/LobbyTitle.vue"

import { TARGET_GAME, TARGET_CLIENT } from "@/store/RecordingModule"
import { Role } from "@/helpers"

import EntryPage from "@/enums/EntryPage"
import Page from "@/router/Page"
import { Media } from "@/components/GroupTeams/Common/SmartMedia.vue"
import { GameType } from "@/entities/GameType"

import LobbyStreamingService from "@/services/stream.service/Lobby"
import { db } from "@/firebase"
import LobbyService from "@/services/lobby.service"
import UserService from "@/services/user.service"

import useLobbyAd from "@/use/useLobbyAd"
import useLobby from "@/use/useLobby"
import useLobbyRooms from "@/use/useLobbyRooms"

import LiveChatMixin from "@/mixins/LiveChat"
import { VideoMixin } from "@/mixins/Video"

export default ClientUsersConsumer.extend(
  ClientGamesConsumer.extend({
    name: "LobbyDesktop",
    mixins: [VideoMixin, LiveChatMixin],
    components: {
      MediaBannerWithHubSpotForm: () =>
        import("@/components/MediaBannerWithHubSpotForm"),
      LobbyScreen: () => import("@/components/Lobby/LobbyScreen"),
      TwilioStream: () => import("@/components/TwilioStream"),
      SessionSettings: () => import("@/components/Game/ClientSettings"),
      Header: () => import("@/components/GroupTeams/Common/Header.vue"),
      LobbyGameScoreboard: () =>
        import("@/components/Lobby/LobbyGameScoreboard.vue"),
      LiveChatBroadcast: () =>
        import("@/components/Lobby/LiveChatBroadcast.vue"),
      LiveChatBroadcastCard: () =>
        import("@/components/Lobby/LiveChatBroadcastCard.vue"),
      RecordVideo: () => import("@/components/Recording/RecordVideo.vue"),
      ClientAudioPlayer: () =>
        import("@/components/GroupTeams/Misc/ClientAudioPlayer"),
      GameSettings: () => import("@/components/Game/GameSettings.vue"),
      GameIceBreakers: () => import("@/components/Lobby/GameIceBreakers.vue"),
      GamesAuditor: () => import("@/components/GamesAuditor.vue"),
      GameStartSound: () => import("@/components/Lobby/GameStartSound.vue"),
      RtbSelect,
      LobbyTabs,
      RoundMover,
      LobbyTitle,
      RtbButton,
      RtbPillButton,
      RtbLink,
      LobbyRooms,
      Profile,
      GameInfo,
      GameInfoVideo,
      GameImage,
      GamePlaceholder,
      LiveChat,
      UserSearch,
      Popup,
      UserName,
      VenmoModal,
      RtbControlButton,
      LobbyChatWrap,
      Recommended,
      VideoGroups,
      VideoDrawer,
      RequestedGames,
      ActivityFeed
    },
    setup() {
      const { isOpen: isMediaBannerOpen, hide: closeMediaBanner } = useLobbyAd()
      const {
        selectedGameID,
        showEditEvent,
        showActivityFeed,
        showAuditor,
        returnToKeynote,
        selectedUserID,
        getDefaultKeynote,
        selectGameCard,
        isDefaultKeynoteViewed,
        isOurVideo,
        isTwilioLobbyStream,
        lobbyStreamID,
        isStandard,
        isVideo,
        isGameVideoVisible,
        isImage,
        onVideoDrawer,
        noAnnouncement
      } = useLobby()

      const {
        isRoomVisitedByUser,
        isRoomUnlockedByUser,
        standardRooms,
        sortedGames,
        userGameID,
        toggleUpsideDown,
        upsideDown,
        selectedGame
      } = useLobbyRooms()

      return {
        showAuditor,
        isStandard,
        isRoomUnlockedByUser,
        isVideo,
        isImage,
        showEditEvent,
        onVideoDrawer,
        noAnnouncement,
        isGameVideoVisible,
        lobbyStreamID,
        selectedGameID,
        selectedUserID,
        selectGameCard,
        returnToKeynote,
        closeMediaBanner,
        showActivityFeed,
        isMediaBannerOpen,
        getDefaultKeynote,
        isRoomVisitedByUser,
        isOurVideo,
        isDefaultKeynoteViewed,
        standardRooms,
        sortedGames,
        userGameID,
        selectedGame,
        toggleUpsideDown,
        upsideDown,
        isTwilioLobbyStream
      }
    },
    data() {
      return {
        announcementTaskID: null,
        lobbyScreenStreamType: LobbyStreamingService.TYPE,
        showLobbyScreen: false,
        closedBroadcasts: {},
        editGameDialog: false,
        lastSelectedGameID: null,
        playerTab: null,
        videoGroupTab: null,
        currentAnnouncementVideo: null,
        announcementClientURL: null,
        announcementClientURL_flipped: false,
        recordAnnouncementTarget: null,
        recordAnnouncementGameID: null,
        playAnnouncement: false,
        timeout: null,
        x: 0,
        y: 0,

        showRecordingDialog: false,
        popupClass: null,
        isUserInitializing: false,
        initializing: false,

        announcementVideosInterval: null,

        isAnnoncementVisible: false,
        showChatBody: false,
        selectedFlashcard: null
      }
    },
    async created() {
      try {
        this.initializing = true

        const { isHost, client, clientID, user } = this

        UserService.keepAlive(this.$store.state.auth.user, this.$route.path)

        const promises = [this.$store.dispatch("livechat/subscribe")]

        if (!isHost) {
          promises.push(
            this.$store.dispatch("pregame/fetchUserPlayedGames", {
              clientID,
              userID: user.id
            })
          )
          promises.push(
            this.$store.dispatch("pregame/fetchUserUnlockedGames", {
              clientID,
              userID: user.id
            })
          )
        }

        await Promise.all(promises)

        const userGame = this.userGameID && this._getGameByID(this.userGameID)

        const isUserGameUnavailable = !!(
          userGame?.endTimestamp || userGame?.deletedTimestamp
        )

        if (isUserGameUnavailable) {
          await this.$store.dispatch("auth/deinitializeGame")
        }

        const isScribeRequestNeeded = () =>
          user.selected &&
          user.role === Role.Player &&
          userGame &&
          !userGame.endTimestamp &&
          !userGame.deletedTimestamp &&
          userGame.started &&
          userGame.startTimestamp < Date.now()

        if (!isHost) {
          const obj = {}

          if (user.selected) {
            await db
              .auxiliary()
              .ref(`org/1/users/${user.id}/selected`)
              .set(false)
          }

          if (isUserGameUnavailable || !this.userGameID) {
            obj.noGameTimestamp = Date.now()

            if (this.isSpectator) {
              obj.role = Role.Player
            }
          }

          if (Object.keys(obj).length) {
            await this.$store.dispatch("updateUser", {
              userID: user.id,
              obj
            })
          }

          if (isScribeRequestNeeded()) {
            await this.$store.dispatch("group/autoScribeAndTeam")
          }
        }

        if (!isHost && client?.autoChat) {
          await db
            .auxiliary()
            .ref(`client/${clientID}/usersAutoChatQueue`)
            .push(true)
        }

        if (!isHost && this.$store.getters["auth/restriction"] > 0) {
          await db
            .auxiliary()
            .ref(`client/${clientID}/usersSpreadQueue`)
            .push(true)
        }

        const keynote = this.getDefaultKeynote(this.games)

        if (this.userGameID) {
          this.onGameCardSelect({ gameID: this.userGameID })
        } else if (this.firstGameID) {
          this.onGameCardSelect({ gameID: this.firstGameID })
        } else if (keynote) {
          this.onGameCardSelect({ gameID: keynote.id })
        }

        // subscribe to video announcements
        this.subscribeAnnouncementVideos({ clientID })
      } catch (e) {
        await this.$info(e.message)
        console.error(e)
      } finally {
        this.initializing = false
      }
    },
    async beforeDestroy() {
      this.announcementTaskID && this.$queue.kill(this.announcementTaskID)
      clearInterval(this.announcementVideosInterval)
      const promises = [
        this.$store.dispatch("livechat/end"),
        this.$store.dispatch("livechat/unsubscribe"),
        this.$store.dispatch(
          "recording/unsubscribeAnnouncementVideos",
          this.clientID
        ),
        this.purgeUser()
      ]
      await Promise.all(promises)
    },
    watch: {
      isTwilioLobbyStream(newValue, oldValue) {
        if (newValue !== oldValue && newValue) {
          // OMG
          this.currentAnnouncementVideo = null
          this.announcementClientURL = null
          this.noAnnouncement = true
          this.isAnnoncementVisible = false
          this.isGameVideoVisible = false
          this.playAnnouncement = false
        }
      },
      userGameID: {
        async handler(value) {
          if (this.initializing) return
          if (value && !this.isFlashCards(this._getGameByID(value))) {
            await this.onGameCardSelect({ gameID: value })
          } else {
            if (this.firstGameID)
              this.onGameCardSelect({ gameID: this.firstGameID })
            // push a user into the queue simply by updating the timestamp that
            // indicates when they landed on the lobby  page without a game ID
            await this.$store.dispatch("updateUser", {
              userID: this.user.id,
              obj: { noGameTimestamp: Date.now() }
            })
          }
        }
      },
      requests: {
        async handler(newValue) {
          if (!Array.isArray(newValue)) return
          const [room] = newValue
          if (!room) return
          const { roomID, priority = 0 } = room

          if (this.$_LiveChatMixin_isUserLocked(this.user) && priority < 1)
            return await this.onRequestResponse({ status: false, roomID })

          if (this.roomID) {
            if (this.roomUsers?.length > 1 && priority < 2)
              return await this.onRequestResponse({ status: false, roomID })

            await this.$store.dispatch("livechat/end")
          }

          await this.onRequestResponse({ status: true, roomID })
        },
        deep: true,
        immediate: false
      },
      currentAnnouncementVideo: {
        handler(value) {
          if (value) {
            const user =
              JSON.parse(localStorage.getItem(`user-${this.user.id}`)) || {}
            const recordings = user.recordings || []
            const stringified = JSON.stringify(value)
            if (recordings.every(obj => JSON.stringify(obj) !== stringified)) {
              recordings.unshift(value)
              localStorage.setItem(
                `user-${this.user.id}`,
                JSON.stringify({
                  ...user,
                  recordings: recordings.slice(0, 10)
                })
              )
              this.announcementClientURL = null
              this.announcementClientURL_flipped = value.flipped

              // promisify announcements
              const action = () =>
                new Promise(resolve => {
                  this.announcementClientURL = value.url
                  const unwatch = this.$watch("playAnnouncement", value => {
                    if (!value) {
                      unwatch()
                      resolve()
                    }
                  })
                })

              setTimeout(() => {
                this.announcementTaskID &&
                  this.$queue.kill(this.announcementTaskID)
                const { id } = this.$queue.enq({ priority: 2, action })
                this.announcementTaskID = id
              }, 1000)
            } else this.noAnnouncement = true
          } else {
            this.noAnnouncement = true
          }
        },
        immediate: true
      },
      announcementClients: {
        handler(value) {
          if (!value) return

          clearInterval(this.announcementVideosInterval)

          const videos = Object.keys(value)
            .map(announcementVideoId => value[announcementVideoId])
            .sort((a, b) => a.playingTimestamp - b.playingTimestamp)

          const setCurrentAnnouncementVideo = () => {
            const currentTimestamp = Date.now()
            for (let i = 0; i < videos.length; i++) {
              if (
                videos[i].playingTimestamp >= currentTimestamp &&
                videos[i - 1] !== undefined
              ) {
                this.currentAnnouncementVideo = videos[i - 1]
                break
              } else if (
                videos.length === i + 1 &&
                videos[i].playingTimestamp <= currentTimestamp
              ) {
                this.currentAnnouncementVideo = videos[i]
              }
            }
          }

          this.announcementVideosInterval = setInterval(
            setCurrentAnnouncementVideo,
            1000 * 10
          ) // run interval every 10 sec

          setCurrentAnnouncementVideo()
        },
        immediate: true
      },
      "selectedGame.streamUrl": function (newValue, oldValue) {
        // if broadcast was stopped
        if (!newValue && oldValue && this.returnToKeynote) {
          this.isDefaultKeynoteViewed = false
        }
      },
      showRecordingDialog(value) {
        if (value) {
          Media.add(this._uid)
        } else {
          Media.rm(this._uid)
        }
      },
      announcementClientURL(nextValue, prevValue) {
        if (nextValue && nextValue !== prevValue) {
          this.isAnnoncementVisible = true
        }
      },
      announcementText(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.$snackbar({
            message: newValue.trim().replace(/\.\s+/gi, "._").split("_"),
            offset: 65,
            timeout: 0
          })
        }
      },
      isForcedFullScreen: {
        immediate: true,
        handler(value) {
          if (this.ignoreLobbyScreen || this.isUserBroadcasting) return

          this.showLobbyScreen = value
        }
      }
    },
    computed: {
      ...mapGetters("GameUsers", ["users"]),
      ...mapGetters(["orgID", "time", "urlID"]),
      ...mapGetters("auth", [
        "user",
        "client",
        "clientID",
        "isHost",
        "isAudit",
        "isSuper",
        "isSpectator",
        "isModerator"
      ]),
      ...mapGetters("pregame", ["access"]),
      ...mapGetters("livechat", {
        roomID: "roomID",
        room: "room",
        requests: "requestsArray",
        roomUsers: "roomUsers"
      }),
      ...mapGetters("recording", ["announcementClients"]),
      isUserBroadcasting() {
        return this.roomID === this.lobbyStreamID
      },
      lobbyScreenTitle() {
        if (this.isMediaBannerOpen) return null

        return this.client?.stream?.title
      },
      lobbyScreenDescription() {
        if (this.isMediaBannerOpen) return null

        return this.client?.stream?.description
      },
      hasLobbyScreen() {
        return this.isTwilioLobbyStream || this.isMediaBannerOpen
      },
      ignoreLobbyScreen() {
        return this.closedBroadcasts[this.lobbyStreamID]
      },
      isTwilioLobbyStreamFullscreen() {
        if (!this.isTwilioLobbyStream) return false

        return this.showLobbyScreen
      },
      isForcedFullScreen() {
        return this.client?.stream?.fullscreen
      },
      isStreamingOn() {
        return !!(this.selectedGame && this.selectedGame.streamUrl)
      },
      isEscapeRoom() {
        return !!this.client?.escapeRoom
      },

      hasStandings() {
        return !!this.scoreboardGames[0]
      },
      hasImage() {
        return !!this.imageGames[0]
      },
      imageName() {
        return this.imageGames[0]?.externalName
      },
      hasFlashCard() {
        return !!this.flashCardGames[0]
      },
      isSpeaker() {
        return this.user.speaker
      },
      returnUrlText() {
        var url = this.client.returnUrl
        if (this.client.returnUrlText) return this.client.returnUrlText
        if (url.includes("gotomeeting")) return "Return To GoToMeeting"
        else if (url.includes("zoom")) return "Return To Zoom"
        else return "Return To Webinar"
      },
      /**
       * First game ID, that not is a default keynote
       **/
      firstGameID() {
        const game = this.standardRooms.find(
          ({ defaultKeynote }) => !defaultKeynote
        )
        return game ? game.id : null
      },
      activeGames() {
        return this.games.filter(
          ({ deactivate, ondeck, deletedTimestamp }) =>
            !deactivate && !ondeck && !deletedTimestamp
        )
      },
      flashCardGames() {
        return this.activeGames.filter(
          ({ gameType }) => gameType == GameType.FlashCard
        )
      },
      imageGames() {
        return this.activeGames.filter(
          ({ gameType }) => gameType === GameType.Image
        )
      },
      scoreboardGames() {
        return this.activeGames.filter(
          ({ gameType }) => gameType === GameType.Scoreboard
        )
      },
      recordAnnouncementGame() {
        const recordAnnouncementGameID = this.recordAnnouncementGameID
        return recordAnnouncementGameID
          ? this.games.find(game => game.id === recordAnnouncementGameID)
          : undefined
      },
      recordAnnouncementHeader() {
        if (
          this.recordAnnouncementTarget === TARGET_GAME &&
          this.recordAnnouncementGame
        ) {
          return `Video for ${this.recordAnnouncementGame.name}`
        } else if (this.recordAnnouncementTarget === TARGET_CLIENT) {
          return `Video Announcement`
        } else {
          return ""
        }
      },
      isPlayer() {
        return this.user.role === Role.Player
      },
      hostOptions() {
        return [
          {
            title: "Oversee Games",
            action: "auditGamesAction"
          },
          {
            type: "divider"
          },
          {
            title: "User Assign",
            action: "runUserSpread"
          },
          {
            title: "End Un-Staged Games",
            action: "endUnstagedGames"
          },
          {
            title: "Poke In The Eye",
            action: "pokeInTheEye"
          },
          {
            type: "divider"
          },
          {
            title: "Edit Event",
            action: "editClient"
          },
          {
            title: "Edit Activities",
            action: "editGames"
          },
          {
            type: "divider"
          },
          {
            title: "Upside Down",
            action: "toggleUpsideDown"
          },
          {
            title: "Show All",
            action: "toggleDeveloperMode"
          },
          this.allTestPlayers.length && {
            title: "Delete Test Players",
            action: "deleteTestPlayers"
          }
        ].filter(Boolean)
      },
      isProfileVisible() {
        return (
          !!this.selectedUserID &&
          !this.isAnnoncementVisible &&
          !this.isGameVideoVisible &&
          !this.showChatBody
        )
      },
      allTestPlayers() {
        return this.$store.getters["allusers/ONLINE_USERS"].filter(
          ({ headless }) => headless
        )
      },
      announcementText() {
        return this.client?.announcement || ""
      }
    },
    methods: {
      ...mapActions("auth", ["initializeToGame"]),
      ...mapActions("recording", [
        "subscribeAnnouncementVideos",
        "unsubscribeAnnouncementVideos"
      ]),
      ...mapActions(["toggleDeveloperMode"]),
      ...mapActions("GameUsers", ["removeTestUsers"]),
      onLobbyScreenClose() {
        if (this.isMediaBannerOpen) return this.closeMediaBanner()

        this.$set(this.closedBroadcasts, this.lobbyStreamID, true)
        this.showLobbyScreen = false
      },
      helpGameClicked(gameID) {
        this.onGameCardSelect({ gameID })
      },
      toggleActivityFeed() {
        this.showActivityFeed = !this.showActivityFeed
      },
      onSelect(round) {
        this.round = round || 0
      },
      onCloseStandings() {
        this.showActivityFeed = false
        var gameID = this.firstGameID
        this.onGameCardSelect({ gameID })
      },
      selectStandings() {
        this.showActivityFeed = false
        this.lastSelectedGameID = this.selectedGameID
        const gameID = this.scoreboardGames[0]?.id
        if (gameID) this.onGameCardSelect({ gameID })
      },
      selectImage() {
        this.showActivityFeed = false
        const gameID = this.imageGames[0]?.id
        if (gameID) this.onGameCardSelect({ gameID })
      },
      closeEdit() {
        this.editGameDialog = false
      },
      editFlashCard() {
        this.selectedGameID = this.flashCardGames?.[0]?.id
        this.editGameDialog = true
      },
      toggleFlashCard() {
        if (this.selectedFlashcard) {
          this.selectedFlashcard = null
        } else {
          const gameID = this.flashCardGames?.[0]?.id
          if (gameID) {
            this.selectedFlashcard = gameID
          }
        }
      },
      closeDrawer() {
        if (this.isAnnoncementVisible) {
          this.playAnnouncement = false
          this.noAnnouncement = true
        }
        this.isAnnoncementVisible = false
        this.isGameVideoVisible = false
      },
      callAction(actionName) {
        if (this[actionName]) {
          this[actionName]()
        }
      },
      auditGamesAction() {
        this.showAuditor = true
        this.showEditEvent = false
      },
      pokeInTheEye() {
        db.auxiliary()
          .ref(`client/${this.clientID}/usersAutoChatQueue`)
          .push(true)
      },
      runUserSpread() {
        LobbyService.autoAssignUsers()
      },
      isFlashCards(game) {
        return game?.gameType === GameType.FlashCard
      },
      editClient() {
        this.$router.push({
          name: Page.SESSION_SETTINGS,
          params: { id: this.clientID }
        })
      },
      editGames() {
        this.$router.push(`/games/`)
      },
      isScoreboard(game) {
        if (!game) return false
        return game.gameType === GameType.Scoreboard
      },

      onLoadVideo() {
        this.playAnnouncement = true
        this.noAnnouncement = false
      },
      playAnnoncementVideo() {
        if (this.announcementClientURL) {
          this.playAnnouncement = true
        }
      },
      addTime(gameID) {
        if (!this.isHost) return
        const MINUTES_TO_ADD = 5
        const game = this._getGameByID(gameID)
        const newGame = { ...game }
        newGame.gameTime = newGame.gameTime + 1000 * 60 * MINUTES_TO_ADD
        newGame.started = false
        newGame.startTimestamp =
          newGame.startTimestamp + 1000 * 60 * MINUTES_TO_ADD
        newGame.theKey = gameID
        this.$store.dispatch("Games/updateGame", newGame)
      },
      endUnstagedGames() {
        if (
          !confirm(
            "This will end all currently unstaged games and boot everyone out.  Are you sure?"
          )
        )
          return

        this.games
          .filter(game => !game.ondeck && game.gameType === GameType.Standard)
          .forEach(game => {
            this.$store.dispatch("Games/updateGameAny", {
              theKey: game?.theKey,
              endTimestamp: Date.now(),
              endedBy: this.user?.id ?? null
            })
          })
      },
      removeTime(gameID) {
        if (!this.isHost) return
        const MINUTES_TO_REMOVE = 1
        const game = this._getGameByID(gameID)
        const newGame = { ...game }
        newGame.gameTime = newGame.gameTime - 1000 * 60 * MINUTES_TO_REMOVE
        newGame.started = false
        newGame.startTimestamp =
          newGame.startTimestamp - 1000 * 60 * MINUTES_TO_REMOVE
        newGame.theKey = gameID
        this.$store.dispatch("Games/updateGame", newGame)
      },
      resetTimes(event) {
        if (!this.isHost) return
        if (event.altKey || event.ctrlKey) {
          const now = Date.now() + 1000 * 60 * 5
          var theTime = Math.floor(Date.now() / 1000)
          for (const game of this.games) {
            const newGame = { ...game }
            if (newGame.deletedTimestamp || newGame.endTimestamp) continue
            newGame.gameTime = theTime + 10000
            newGame.started = false
            newGame.startTimestamp = now
            newGame.theKey = game.id
            this.$store.dispatch("Games/updateGame", newGame)
          }
        }
      },
      async onMouseLeave() {
        await this.purgeUser()
        this.selectedUserID = null
      },
      async purgeUser() {
        await Promise.all([
          this.$store.dispatch("profile/unsubscribeFromUserRoom"),
          this.$store.dispatch("profile/unsubscribeFromUserRoomID"),
          this.$store.dispatch("profile/purgeData"),
          this.$store.dispatch("profile/unsubscribeFromUser")
        ])
      },

      onGameCardSelect({ gameID }) {
        this.selectedGameID = gameID
      },
      async onRequestResponse({ status, roomID }) {
        if (status) {
          try {
            await this.$store.dispatch("livechat/accept", { roomID })
          } catch (e) {
            console.error(e)
            await this.$store.dispatch("livechat/end")
          }
        } else {
          await this.$store.dispatch("livechat/decline", { roomID })
        }
      },
      async onBookGame({ gameID, _this }) {
        try {
          await this.initializeToGame({ gameID, clientID: this.clientID })
          const game = this._getGameByID(gameID)
          if (!game) return alert(`Game ${gameID} not found`)
          const gameStartTime = parseInt(game.startTimestamp) || 0
          const name = game.externalName || game.name
          if (!this.isHost && gameStartTime > 0) {
            const string = format(gameStartTime, "h:mm aaa")

            await this.$snackbar({
              message: `Thanks for joining ${name}. You will be escorted in at ${string}`,
              offset: 65,
              timeout: 6000
            })
          }
        } catch (e) {
          this.$info(e.message)
          console.error(e)
        }
        _this.loading = false
      },
      async onJoinGame({ gameID, _this }) {
        try {
          await this.initializeToGame({ gameID, clientID: this.clientID })
        } catch (e) {
          this.$info(e.message)
          console.error(e.message)
        }
      },
      async onLeaveGame({ _this }) {
        try {
          await this.$store.dispatch("auth/deinitializeGame")
          if (this.user.role === Role.Spectator) {
            await this.$store.dispatch("updateUser", {
              userID: this.user.id,
              obj: { role: Role.Player }
            })
          }
        } catch (e) {
          this.$info(e.message)
          console.error(e)
        }
        _this.loading = false
      },
      goToGamePage(gameID) {
        this.$navigator.navigateTo(`/game/${this.clientID}${gameID}`)
      },
      goToPickTeamPage(gameID) {
        this.$navigator.navigateTo(`/pickteams/${this.clientID}${gameID}`)
      },
      async onEnterGame({ gameID }) {
        await this.$store.dispatch("fetchMissions", gameID)
        await this.$store.dispatch("bringBackActualGame")
        const game = this._getGameByID(gameID)
        if (!game) return this.$info(`Room ${gameID} is no longer available.`)
        if (game.diy || game?.entryPage === EntryPage.GAME || game.started)
          return this.goToGamePage(gameID)

        this.goToPickTeamPage(gameID)
      },
      async onInvite({ userID }) {
        let priority = 0
        if (this.isUserBroadcasting) priority = 2
        else if (this.isHost) priority = 1

        let roomID =
          this.$_LiveChatMixin_streamRoomID && this.isSuper
            ? this.$_LiveChatMixin_streamRoomID
            : null

        await this.$store.dispatch("livechat/invite", {
          userID,
          priority,
          _roomID: roomID
        })
      },
      async onJoin({ roomID }) {
        try {
          await this.$store.dispatch("livechat/enter", { roomID })
        } catch (e) {
          console.error(e)
          this.$info(e.message)
        }
      },
      async onUserSelect({ userID, x, y, height, width }) {
        const popupWidth = 380
        const required = x + popupWidth + 10
        const available = window.innerWidth
        if (available < required + 10) {
          this.x = x - popupWidth + width + 5
          this.popupClass = "right"
        } else {
          this.x = x - 5
          this.popupClass = "left"
        }
        this.y = y - 140 + height + 5
        this.selectedUserID = userID
      },
      recordClientAnnouncement() {
        this.recordAnnouncementGameID = null
        this.recordAnnouncementTarget = TARGET_CLIENT
        this.showRecordingDialog = true
      },
      clientAnnouncementPosted() {
        setTimeout(() => (this.showRecordingDialog = false), 500)
      },
      deleteTestPlayers() {
        this.removeTestUsers({ users: this.allTestPlayers })
      }
    }
  })
)
