var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LobbyTabs",
    _vm._b(
      {
        staticClass: "lobby-rooms",
        attrs: { value: _vm.roomTab },
        on: {
          "update:value": function ($event) {
            _vm.roomTab = $event
          },
        },
      },
      "LobbyTabs",
      _vm.$attrs,
      false
    ),
    [
      _vm.hasStandardRooms
        ? _c("v-tab", { key: "hosted" }, [_vm._v(" HOSTED ")])
        : _vm._e(),
      _vm.hasDiyRooms
        ? _c("v-tab", { key: "diy" }, [_vm._v(" DIY ")])
        : _vm._e(),
      _vm.hasVideoRooms
        ? _c("v-tab", { key: "videos" }, [
            _vm._v(
              " " + _vm._s(_vm.videoRooms.length > 1 ? "VIDEOS" : "VIDEO") + " "
            ),
          ])
        : _vm._e(),
      _vm.hasStandardRooms
        ? _c(
            "v-tab-item",
            { key: "hosted" },
            [
              _c("GameSearch", {
                staticClass: "lobby-page__game-search",
                attrs: {
                  games: _vm.standardRooms,
                  selectedGameID: _vm.actualSelectedGameID,
                  isGamePlayedByUser: _vm.isRoomVisitedByUser,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.hasDiyRooms
        ? _c(
            "v-tab-item",
            { key: "diy" },
            [
              _c("GameSearch", {
                staticClass: "lobby-page__game-search",
                attrs: {
                  games: _vm.diyRooms,
                  selectedGameID: _vm.actualSelectedGameID,
                  isGamePlayedByUser: _vm.isRoomVisitedByUser,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.hasVideoRooms
        ? _c(
            "v-tab-item",
            { key: "videos" },
            [
              _c("GameSearch", {
                staticClass:
                  "lobby-page__game-search lobby-page__game-search--no-status",
                attrs: {
                  games: _vm.videoRooms,
                  selectedGameID: _vm.actualSelectedGameID,
                  isGamePlayedByUser: _vm.isRoomVisitedByUser,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }