var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "recomended-players" },
    [
      _c(
        "transition",
        { attrs: { name: "fade-in", mode: "out-in" } },
        [
          _vm.recommendedUsers.length > 0
            ? _c("UserList", {
                attrs: { users: _vm.recommendedUsers },
                on: { onSelect: _vm.onSelectUser },
              })
            : _c("div", { staticClass: "no-recommended" }, [
                _vm._v("No one is available to video chat"),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }