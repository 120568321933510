














































import Vue, { PropType } from "vue"
import { WithRefs } from "vue-typed-refs"
import { mapActions, mapGetters } from "vuex"
import SyncedMedia from "@/modules/smart-media/components/SyncedMedia.vue"

import { Game } from "@/types"

import WithAudioContext from "@/modules/audio/components/WithAudioContext"

export type Refs = {
  player: HTMLVideoElement
}

export default (Vue as WithRefs<Refs>).extend({
  name: "GameVideo",
  components: {
    WithAudioContext,
    SyncedMedia
  },
  props: {
    game: {
      type: Object as PropType<Game>,
      required: true
    },
    height: {
      type: String
    },
    canPlay: {
      type: Boolean,
      default: false
    },
    controls: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost", "user", "isSuper"]),
    ...mapGetters("pregame", ["selectedKeynote"]),
    videoClasses() {
      return [
        "absolute",
        "top-0",
        "left-0",
        "w-full",
        "h-full",
        "object-contain",
        "--reset-flip"
      ]
    },
    globalState: {
      get() {
        const { videoPaused, videoStartedAt, videoCurrentTime } = this.game
        return {
          paused: (videoPaused ?? true) || !this.canPlay,
          currentTime: videoCurrentTime,
          startedAt: videoStartedAt
        }
      },
      set(value: { paused: string; currentTime: number; startedAt: number }) {
        this.updateGameAny({
          theKey: this.game.id,
          videoPaused: value.paused,
          videoCurrentTime: value.currentTime || null,
          videoStartedAt: value.startedAt || null
        })
      }
    },
    localState: {
      get() {
        const { currentTime, startedAt } = this.selectedKeynote
        return {
          paused: false && !this.canPlay,
          currentTime,
          startedAt
        }
      },
      set(value) {
        this.updateSelectedKeynote(value)
      }
    }
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    ...mapActions("pregame", ["updateSelectedKeynote"])
  }
})
