import { render, staticRenderFns } from "./LobbyDesktop.vue?vue&type=template&id=60a95743&"
import script from "./LobbyDesktop.vue?vue&type=script&lang=ts&"
export * from "./LobbyDesktop.vue?vue&type=script&lang=ts&"
import style0 from "./LobbyDesktop.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VContainer,VDialog,VDivider,VFlex,VLayout,VList,VListTile,VListTileTitle,VMenu,VTab,VTabItem})


/* hot reload */
if (module.hot) {
  var api = require("/Users/dkurbatov/Documents/TGG/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60a95743')) {
      api.createRecord('60a95743', component.options)
    } else {
      api.reload('60a95743', component.options)
    }
    module.hot.accept("./LobbyDesktop.vue?vue&type=template&id=60a95743&", function () {
      api.rerender('60a95743', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Lobby/LobbyDesktop.vue"
export default component.exports