var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "video-drawer",
      attrs: {
        dark: "",
        absolute: "",
        value: _vm.visibility,
        stateless: "",
        right: "",
        "hide-overlay": "",
        width: _vm.drawerWidth,
      },
    },
    [
      _c("img", {
        staticClass: "top-image-url",
        attrs: { src: _vm.topImageURL },
      }),
      _c("img", {
        staticClass: "bottom-image-url",
        attrs: { src: _vm.bottomImageURL },
      }),
      _c(
        "header",
        { staticClass: "video-drawer__header" },
        [
          _c("h2", { staticClass: "video-drawer__title" }, [
            _vm._v(
              " " +
                _vm._s(_vm.isAnnoncementVisible ? "ANNOUNCEMENT" : _vm.title) +
                " "
            ),
          ]),
          _vm.showCloseBtn
            ? _c(
                "v-btn",
                { staticClass: "ma-0 close-button", on: { click: _vm.close } },
                [_vm._v(" Close ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "video-drawer__cnt" },
        [
          _vm.game && _vm.isGameVideoVisible && !_vm.isAnnoncementVisible
            ? [
                _vm.game.streamUrl
                  ? _c("TAStream", {
                      staticClass: "flex-grow twitch-container",
                      staticStyle: { height: "70vh" },
                      attrs: { streamUrl: _vm.game.streamUrl },
                    })
                  : _vm.game.gameType === "YouTube" ||
                    _vm.game.gameType === "Twitch"
                  ? _c("GameYouTube", {
                      attrs: {
                        game: _vm.game,
                        canPlay: _vm.canPlay,
                        height: "70vh",
                      },
                    })
                  : _vm.game.gameType === "Our Video"
                  ? _c("GameVideo", {
                      attrs: {
                        game: _vm.game,
                        canPlay: _vm.canPlay,
                        controls: _vm.isHost,
                        height: "70vh",
                      },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isAnnoncementVisible,
                  expression: "isAnnoncementVisible",
                },
              ],
              staticClass: "annoncement-video",
            },
            [
              _c("WithAudioContext", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var videoListeners = ref.videoListeners
                      return [
                        _vm.announcementURL
                          ? _c(
                              "SmartMedia",
                              _vm._g(
                                {
                                  ref: "announcementVideoNode",
                                  staticClass:
                                    "annoncement-video__video video-an",
                                  class: { flipped: _vm.announcementFlipped },
                                  attrs: {
                                    autoplay: "",
                                    paused: !_vm.isAnnoncementVisible,
                                    src: _vm.announcementURL,
                                  },
                                  on: {
                                    ended: _vm.onAnnouncementEnd,
                                    loadeddata: function ($event) {
                                      return _vm.$emit("onLoadVideo")
                                    },
                                  },
                                },
                                videoListeners
                              )
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }