var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      class:
        ((_obj = {
          "pre-game-all-users": true,
        }),
        (_obj["pre-game-all-users--size--" + _vm.size] =
          _vm.size !== undefined),
        _obj),
      attrs: { "d-flex": "" },
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            {
              attrs: {
                "d-flex": "",
                shrink: "",
                "pa-3": "",
                "text-xs-center": "",
              },
            },
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c("v-flex", [
                    _c(
                      "form",
                      {
                        staticClass: "user-search__form",
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.onQueryChange(_vm.query)
                          },
                        },
                      },
                      [
                        _c("WeveTextField", {
                          staticClass: "user-search__field",
                          attrs: {
                            label: "Search",
                            "hide-label": "",
                            placeholder: "Search name or email",
                          },
                          model: {
                            value: _vm.query,
                            callback: function ($$v) {
                              _vm.query = $$v
                            },
                            expression: "query",
                          },
                        }),
                        _vm.isFilterVisible
                          ? _c("WeveSelectField", {
                              staticClass: "user-search__select ml-2",
                              attrs: {
                                value: _vm.identifier,
                                options: _vm.customInputOptions,
                                label: _vm.customInputLabel || "option",
                                "hide-label": "",
                              },
                              on: { input: _vm.onIdentifierInput },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm.isHost
                    ? _c(
                        "v-flex",
                        {
                          staticClass: "pre-game-all-users__filters",
                          attrs: { "mt-2": "", "d-flex": "" },
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", "justify-center": "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs2: "" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      dark: "",
                                      dense: "",
                                      label: "ALL",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.filtersDisabled,
                                      callback: function ($$v) {
                                        _vm.filtersDisabled = $$v
                                      },
                                      expression: "filtersDisabled",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs2: "" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      dark: "",
                                      label: "PLAYERS",
                                      dense: "",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.filters.player,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "player", $$v)
                                      },
                                      expression: "filters.player",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs2: "" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      dark: "",
                                      dense: "",
                                      label: "SPECTATOR",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.filters.spectator,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "spectator", $$v)
                                      },
                                      expression: "filters.spectator",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs2: "" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      dark: "",
                                      dense: "",
                                      label: "HOSTS",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.filters.host,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, "host", $$v)
                                      },
                                      expression: "filters.host",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.isHost
                                ? [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs2: "" } },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            dark: "",
                                            dense: "",
                                            label: "AUDIT",
                                            "hide-details": "",
                                          },
                                          model: {
                                            value: _vm.filters.audit,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filters,
                                                "audit",
                                                $$v
                                              )
                                            },
                                            expression: "filters.audit",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs2: "" } },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            dark: "",
                                            dense: "",
                                            label: "ASSIGNED",
                                            "hide-details": "",
                                          },
                                          model: {
                                            value: _vm.filters.assigned,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filters,
                                                "assigned",
                                                $$v
                                              )
                                            },
                                            expression: "filters.assigned",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs2: "" } },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            dark: "",
                                            dense: "",
                                            label: "UNASSIGNED",
                                            "hide-details": "",
                                          },
                                          model: {
                                            value: _vm.filters.unassigned,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filters,
                                                "unassigned",
                                                $$v
                                              )
                                            },
                                            expression: "filters.unassigned",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs2: "" } },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            dark: "",
                                            dense: "",
                                            label: "VIP",
                                            "hide-details": "",
                                          },
                                          model: {
                                            value: _vm.filters.vip,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.filters, "vip", $$v)
                                            },
                                            expression: "filters.vip",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              _c("Separator", [
                _c(
                  "span",
                  { staticClass: "n-of-players-wrap" },
                  [
                    _c("span", { staticClass: "n-of-players" }, [
                      _vm._v(_vm._s(_vm.filteredUsersCount)),
                    ]),
                    _c("span", [_vm._v(" " + _vm._s(_vm.separatorText))]),
                    _vm.shouldDisplayTotalUsersCount
                      ? [
                          _vm._v(" ("),
                          _c("span", { staticClass: "n-of-players" }, [
                            _vm._v(_vm._s(_vm.nOfUsers)),
                          ]),
                          _c("span", [_vm._v(" Total)")]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
          _vm.sortedUsers.length === 0
            ? _c(
                "v-flex",
                {
                  attrs: {
                    "mt-2": "",
                    "d-flex": "",
                    "justify-center": "",
                    "align-center": "",
                    "text-xs-center": "",
                  },
                },
                [
                  _vm.users.length === 0
                    ? _c("h1", { staticClass: "no-results-text" }, [
                        _vm._v("LOADING..."),
                      ])
                    : _c("h1", { staticClass: "no-results-text" }, [
                        _vm._v("NO RESULTS"),
                      ]),
                ]
              )
            : _c(
                "v-flex",
                { attrs: { "d-flex": "", "mt-2": "" } },
                [
                  _c("UserList", {
                    ref: "list",
                    staticClass: "pre-game-all-users__user-list",
                    attrs: {
                      selectedUserID: _vm.selectedUserID,
                      users: _vm.sortedUsers,
                    },
                    on: { onSelect: _vm.onSelect },
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }