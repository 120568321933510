var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "relative game-video",
      style: { height: _vm.height ? _vm.height : "330px" },
    },
    [
      _vm.game.watchParty
        ? [
            _c("WithAudioContext", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var videoListeners = ref.videoListeners
                      return [
                        _c(
                          "SyncedMedia",
                          _vm._g(
                            {
                              class: _vm.videoClasses,
                              attrs: {
                                controlslist: "nodownload",
                                disablePictureInPicture: "",
                                sync: _vm.isHost,
                                src: _vm.game.ourVideoUrl,
                                controls: _vm.controls,
                                loop: _vm.game.videoLoop,
                              },
                              model: {
                                value: _vm.globalState,
                                callback: function ($$v) {
                                  _vm.globalState = $$v
                                },
                                expression: "globalState",
                              },
                            },
                            videoListeners
                          )
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                2086375625
              ),
            }),
            _c(
              "div",
              {
                staticClass: "game-video__overlay",
                class: { "game-youtube--host": _vm.isHost },
              },
              [
                _vm.globalState.paused
                  ? _c(
                      "div",
                      { staticClass: "game-video__tip" },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "game-video__tip-icon",
                            attrs: { color: "#fff" },
                          },
                          [_vm._v("info")]
                        ),
                        _c("p", [_vm._v("Host has paused the movie")]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]
        : [
            _c("WithAudioContext", {
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var videoListeners = ref.videoListeners
                    return [
                      _c(
                        "SyncedMedia",
                        _vm._g(
                          {
                            class: _vm.videoClasses,
                            attrs: {
                              sync: "",
                              src: _vm.game.ourVideoUrl,
                              controls: _vm.controls,
                              loop: _vm.game.videoLoop,
                            },
                            model: {
                              value: _vm.localState,
                              callback: function ($$v) {
                                _vm.localState = $$v
                              },
                              expression: "localState",
                            },
                          },
                          videoListeners
                        )
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }