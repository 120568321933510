


















import { defineComponent } from "@vue/composition-api"
import useLobbyRoom from "@/use/useLobbyRoom"

export default defineComponent({
  name: "GameImage",

  setup() {
    const { game, name } = useLobbyRoom()
    return { game, name }
  }
})
