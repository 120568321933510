






































































// @ts-nocheck
import { mapGetters } from "vuex"
import { TweenMax, Power2 } from "gsap/TweenMax"

import RatioContainer from "@/components/GroupTeams/Common/RatioContaier.vue"
import UserName from "@/components/GroupTeams/Common/User/UserName.vue"
import UserMedia from "@/components/GroupTeams/Common/User/UserMedia.vue"
import PreGameCountdown from "@/components/PreGameCountdown.vue"
import User from "@shared/User"
import ClientUsersConsumer from "@/modules/users/components/ClientUsersConsumer"
import ClientGamesConsumer from "@/consumers/ClientGamesConsumer"

export default ClientUsersConsumer.extend(
  ClientGamesConsumer.extend({
    name: "LobbyPlayerSlot",
    components: {
      RatioContainer,
      UserMedia,
      UserName,
      PreGameCountdown
    },
    props: {
      showTimer: Boolean,
      track: Object,
      imageURL: String,
      src: MediaStream,
      firstname: String,
      lastname: String,
      isChatActive: {
        type: Boolean,
        default: true
      },
      player: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapGetters({
        plays: "missionPlaysArray"
      }),
      ...mapGetters(["user"]),
      ...mapGetters("twilio", ["dominantSpeakerId"]),
      isDominantSpeaker() {
        return this.player.id === this.dominantSpeakerId
      },
      gameID() {
        return this.player.gameID
      },
      game() {
        return this._getGameByID(this.gameID) ?? null
      },
      gameStartTime() {
        return this.game ? this.game.startTimestamp : 0
      },
      canInvitePlayer() {
        return !User.isOnboarding(player)
      }
    },
    methods: {
      onGameClick() {
        this.$emit("onGameClick", { gameID: this.gameID })
      },
      onPlayerInvite() {
        if (this.canInvitePlayer) {
          this.$emit("onPlayerInvite", { userID: this.player.id })
        }
      },
      toggleAudio() {
        const { player, user } = this
        if (player.id === user.id) return
        this.$emit("muteUser", {
          userID: player.id,
          muted: player.muted
        })
      },
      playWinningAnimation() {
        this.$nextTick(() => {
          //console.log("WINNING ANIMATION")
          const root = this.$refs.root
          if (!root) return console.log("The root node is undefined")

          root.classList.add("on-top")
          setTimeout(() => {
            root.classList.remove("on-top")
          }, 1500)

          if (this.$refs.square) {
            TweenMax.fromTo(
              this.$refs.square,
              0.25,
              { opacity: 0 },
              { opacity: 1, overwrite: false }
            )
            TweenMax.fromTo(
              this.$refs.square,
              1,
              { scale: 1 },
              { scale: 1.33, overwrite: false, ease: Power2.easeInOut }
            ).delay(0.15)
            TweenMax.to(this.$refs.square, 0.3, {
              opacity: 0,
              overwrite: false
            }).delay(0.75)
          }
        })
      }
    },
    watch: {
      plays() {
        const plays = this.plays.filter(play => play.userID === this.player.id)
        const successfulPlays = plays.filter(({ score }) => score > 0)
        if (successfulPlays.length) this.playWinningAnimation()
      }
    }
  })
)
