<template>
  <v-flex class="room-detail rtb-d-flex flex-column flex-grow h-0">
    <div
      class="room-detail-image flex-shrink-0 relative"
      :style="headStyle"
      v-if="!game.streamUrl"
    >
      <RtbButton
        v-if="isHost"
        variant="icon"
        size="sm"
        class="abrolute mx-1 my-1"
        @click="openEditTeamsDialog"
      >
        <SvgIcon
          name="recent-actors-outlined"
          width="20"
          height="20"
          class="rtb-right-toolbar__list-item-icon"
        />
      </RtbButton>
    </div>

    <div class="room-detail-info rtb-d-flex flex-column flex-grow h-0">
      <v-flex
        v-if="isStandard && !isHostless && !game.streamUrl"
        class="flex-grow-0"
      >
        <LobbyRoomHost class="game-info-host" :user="roomHost" />
      </v-flex>

      <v-layout
        v-if="isHost"
        row
        align-center
        style="margin: -50px 0 20px 140px"
        class="flex-grow-0"
      >
        <v-flex class="mr-2" shrink>
          <GameInfoTooltip :gameId="game.id" :clientId="game.clientID" />
        </v-flex>

        <v-flex shrink>
          <div
            class="cancel-game"
            @mouseleave="isOverCancel = false"
            @mouseover="isOverCancel = true"
          >
            <button class="cancel-button" @click="toggleCancelOptions">
              Move Participants
            </button>
            <div class="cancel-options" ref="cancel">
              <div class="cancel-option" @click.self="endGame">
                By Ending Game
              </div>
              <div class="cancel-option">
                Merge Into
                <v-select
                  :items="filteredGamesArray"
                  v-model="mergeToGameSelection"
                  label="Play Type"
                  required
                >
                  <template slot="item" slot-scope="data">
                    <div>
                      {{ data.item.externalName || data.item.name }}
                    </div>
                  </template>
                </v-select>
              </div>
              <div class="cancel-option" @click.self="unendGame">
                Un-End Game
              </div>
            </div>
          </div>
        </v-flex>
      </v-layout>

      <div class="main-title">{{ name }}</div>
      <div>{{ description }}</div>
      <v-layout row wrap class="room-detail-container flex-grow-0">
        <v-flex grow lg2 md4 class="room-detail-items">
          Game time<br />
          {{ startTime }}
        </v-flex>
        <v-flex v-if="game.duration" grow lg2 md4 class="room-detail-items">
          Duration<br />
          {{ duration }}
        </v-flex>
        <v-flex
          grow
          lg2
          md4
          class="room-detail-items"
          style="padding-right: 10px"
        >
          <GameTime
            :game="game"
            :time="time"
            :gameStartTime="gameStartTime"
            :gameEndTime="gameEndTime"
            :isGameBooked="isGameBooked"
            :isPlayedByUser="isVisitedByUser"
          />
        </v-flex>
        <v-spacer></v-spacer>

        <v-flex v-if="isGameSpectable && canShowBigButton" grow lg3 md6>
          <v-btn
            flat
            v-if="isGameSpectable"
            @click.stop="tryToJoin({ audit: true })"
            class="spectate-button"
            style="right: 10px"
            :loading="loading"
          >
            Spectate
          </v-btn>
        </v-flex>
        <v-flex grow lg3 md6 v-if="canShowBigButton">
          <v-btn
            @click.stop="tryToJoin"
            v-if="status === 'available'"
            class="join-button"
            style="right: 10px"
            :loading="loading"
          >
            Join Now!
          </v-btn>
          <v-btn
            v-else-if="status === 'locked'"
            class="closed-button"
            style="right: 10px; pointer-events: none"
          >
            Locked
          </v-btn>
          <v-btn
            v-else-if="status === 'played'"
            class="closed-button"
            style="right: 10px"
          >
            Played
          </v-btn>
          <v-btn
            v-else-if="status === 'full'"
            class="closed-button"
            style="right: 10px"
          >
            Full
          </v-btn>
        </v-flex>
        <v-layout flex row>
          <v-flex class="mx-3" grow lg5 v-if="!canShowBigButton">
            <v-btn
              flat
              @click.stop="onEnterGame"
              v-if="isEnterable"
              class="spectate-button"
              style="right: 10px; width: 100%"
              :loading="loading"
            >
              Enter
            </v-btn>
          </v-flex>
          <v-flex class="mx-3" grow lg5 v-if="!canShowBigButton">
            <v-btn
              @click.stop="onLeaveGame"
              v-if="isLeaveable"
              class="join-button"
              style="right: 10px; width: 100%"
              :loading="loading"
            >
              Leave
            </v-btn>
          </v-flex>
        </v-layout>
      </v-layout>
      <v-layout v-if="isStreamVisible" column>
        <v-flex>
          <v-flex>
            <v-btn
              flat
              @click.stop="showTheVideo"
              class="increase-video-size-button"
              style="margin-top: -30px"
            >
              Increase Video Size
            </v-btn>
          </v-flex>
          <WithAudioContext
            #default="{ mute, unmute }"
            v-if="!videoDrawer && canPlay"
          >
            <TAStream
              style="align-self: center height: 40vh"
              class="flex-grow twitch-container"
              :streamUrl="game.streamUrl"
              @play="mute"
              @pause="unmute"
              @end="unmute"
            />
          </WithAudioContext>
        </v-flex>
      </v-layout>
      <template v-else>
        <div class="room-detail__attendees-txt">Attendees</div>
        <div class="room-detail__attendees-txt">
          {{ nOfRoomPlayers }} of {{ nOfMaxPlayers }}
        </div>
        <div class="room-detail__attendees-container">
          <UserList
            v-if="!announcementVideo"
            :size="userListRowLength"
            :users="roomUsersToShow"
            :selectedUserID="selectedUserID"
            @onSelect="onSelectUser"
          />
        </div>
      </template>
    </div>

    <RtbControlButton
      v-if="isHost && hasBroadcasting"
      @click="isBroadcastHidden = false"
      class="abrolute bottom-0"
      variant="outline"
      danger
    >
      Show Broadcast
    </RtbControlButton>
    <PickTeamsDialog v-if="isHost" :gameID="game.id" :users="roomUsersToShow" />
  </v-flex>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { RtbButton, RtbControlButton } from "@/components/ui"

import SvgIcon from "@/components/base/SvgIcon.vue"
import GameTime from "@/components/Lobby/GameTime.vue"
import { GameType } from "@/entities/GameType"
import WithAudioContext from "@/modules/audio/components/WithAudioContext"

import ClientGamesConsumer from "@/consumers/ClientGamesConsumer"

import UserList from "@/components/Lobby/UserList.vue"
import MissionTypes from "@/components/MissionTypes.vue"
import PreGameCountdown from "@/components/PreGameCountdown.vue"

import GameInfoTooltip from "@/components/Shared/GameInfoTooltip.vue"

import { Role, UserSorter } from "@/helpers"

import { state as pickTeamState } from "@/components/dialogs/PickTeamsDialog"
import LobbyRoomHost from "@/components/Lobby/LobbyRoomHost.vue"
import useLobbyRoom from "@/use/useLobbyRoom"
import useLobby from "@/use/useLobby"

export default ClientGamesConsumer.extend({
  name: "GameInfo",
  components: {
    RtbControlButton,
    PreGameCountdown,
    UserList,
    MissionTypes,
    SvgIcon,
    GameTime,
    GameInfoTooltip,
    WithAudioContext,
    LobbyRoomHost,
    RtbButton,
    TAStream: () => import("@/components/VideoPlayers/TAStream.vue"),
    PickTeamsDialog: () => import("@/components/dialogs/PickTeamsDialog")
  },
  props: {
    announcementVideo: String,
    selectedUserID: String,

    videoDrawer: Boolean,
    canPlay: Boolean,
    isAnnouncementVisible: Boolean
  },
  setup() {
    const {
      game,
      name,
      time,
      description,
      gameStartTime,
      gameEndTime,
      isVisitedByUser,
      startTime,
      isGameBooked,
      isGameSpectable,
      canShowBigButton,
      status,
      STATUSES,
      isEnterable,
      isLeaveable,
      tryToJoin,
      onEnterGame,
      onLeaveGame,
      duration,
      loading,
      nOfRoomPlayers,
      nOfMaxPlayers,
      headStyle,
      isGameTime,
      roomUsers,
      roomUsersToShow,
      roomHost
    } = useLobbyRoom()

    const { isBroadcastHidden, isChimeStreamSelected } = useLobby()
    return {
      game,
      name,
      time,
      description,
      gameStartTime,
      isVisitedByUser,
      isGameSpectable,
      canShowBigButton,
      isGameTime,
      status,
      STATUSES,
      isEnterable,
      isLeaveable,
      isGameBooked,
      gameEndTime,
      startTime,
      tryToJoin,
      onEnterGame,
      onLeaveGame,
      nOfRoomPlayers,
      nOfMaxPlayers,
      duration,
      roomUsersToShow,
      roomUsers,
      roomHost,
      headStyle,
      isBroadcastHidden,
      isChimeStreamSelected,
      loading
    }
  },
  data() {
    return {
      timeout: null,
      playAnnouncement: false,

      activeTab: null,
      mergeToGameSelection: false,
      isOverCancel: false
    }
  },

  computed: {
    ...mapGetters("auth", ["isHost"]),

    hasBroadcasting() {
      return this.game.streamUrl || this.isChimeStreamSelected
    },

    isStreamVisible() {
      return this.game.streamUrl && !this.isBroadcastHidden
    },
    userListRowLength() {
      const width = parseInt(this.$window.width)
      if (width < 1350) return 4
      if (width < 1450) return 5
      if (width < 1520) return 6
      if (width < 1700) return 7
      return 8
    },
    isHostless() {
      return this.game.hostless || this.game.hostless2
    },

    isStandard() {
      return (
        !this.game.gameType ||
        this.game.gameType == "Standard" ||
        this.game.gameType == "Green Room" ||
        !!this.streamUrl
      )
    },

    filteredGamesArray() {
      return this.games.filter(
        ({
          id: gameID,
          runStatus,
          gameType,
          ondeck,
          endTimestamp,
          deactivate,
          deletedTimestamp
        }) =>
          gameID !== this.game.id &&
          runStatus !== "Deleted" &&
          !ondeck &&
          !deactivate &&
          !endTimestamp &&
          !deletedTimestamp &&
          (!gameType ||
            gameType === GameType.Standard ||
            gameType === GameType.GreenRoom)
      )
    }
  },

  watch: {
    time: {
      handler(value) {
        const isGameTime = !!(this.gameStartTime && value > this.gameStartTime)
        if (this.isGameTime !== isGameTime) this.isGameTime = isGameTime
      },
      immediate: true
    },
    mergeToGameSelection(game) {
      if (!game) return console.warn("Undefined game")
      this.mergeToGame(game)
      this.mergeToGameSelection = false
    }
  },

  created() {
    // throw and Error when game.name is empty
    if (typeof this.game.name !== "string" || this.game.name === "") {
      throw new Error(
        "game.name is not typeof 'string' or equeals to empty string"
      )
    }
  },

  mounted() {
    document.addEventListener("click", this.globalClick)
    this.loading = true
    setTimeout(() => {
      this.loading = false
    }, 1000)
  },

  beforeDestroy() {
    document.removeEventListener("click", this.globalClick)
  },

  methods: {
    ...mapActions("GameUsers", ["pushUsersToGame"]),
    ...mapActions("Games", ["updateGameCapacity"]),

    showTheVideo() {
      this.$emit("onVideoDrawer")
    },

    async mergeToGame(game) {
      this.closeCancelOptions()
      const gameUsers = this.roomUsers.filter(
        user => user.gameID === this.game.id && user.role !== Role.Host
      )
      const targetGamePlayers = this.roomUsers.filter(
        ({ gameID, role }) => gameID === game.id && role === Role.Player
      )
      const targetGameCapacity = parseInt(game.players) || 999999
      const capacityRequired = targetGamePlayers.length + gameUsers.length
      if (targetGameCapacity < capacityRequired) {
        await this.updateGameCapacity({
          gameID: game.id,
          capacity: capacityRequired
        })
      }
      await this.pushUsersToGame({
        users: gameUsers.map(({ id }) => id),
        gameID: game.id
      })
      await this.$store.dispatch("Games/updateGame", {
        ...this.game,
        theKey: this.game.id,
        deactivate: true
      })
    },
    onSelectUser(obj) {
      this.$emit("onSelect", obj)
    },

    async endGame() {
      this.closeCancelOptions()
      if (
        !confirm(
          "Are you sure you want to end this game? This will remove all players and they won't be able to return to this game"
        )
      )
        return
      const gameUsers = this.roomUsers.filter(
        ({ gameID, role }) => gameID === this.game.id && role !== Role.Host
      )
      await this.pushUsersToGame({
        users: gameUsers.map(({ id }) => id),
        gameID: 0
      })
      await this.$store.dispatch("Games/updateGame", {
        ...this.game,
        theKey: this.game.id,
        endTimestamp: Date.now()
      })
    },
    async unendGame() {
      this.closeCancelOptions()
      if (!confirm("This will un-end a game that was accidentally ended"))
        return
      await this.$store.dispatch("Games/updateGame", {
        ...this.game,
        theKey: this.game.id,
        endTimestamp: null,
        endedBy: null
      })
    },
    toggleCancelOptions() {
      if (this.$refs.cancel) this.$refs.cancel.classList.toggle("show")
    },
    globalClick() {
      if (!this.isOverCancel) this.closeCancelOptions()
    },
    closeCancelOptions() {
      if (this.$refs.cancel) this.$refs.cancel.classList.remove("show")
    },
    openEditTeamsDialog() {
      pickTeamState.open = true
    }
  }
})
</script>

<style lang="scss">
.room-detail {
  .vue-recycle-scroller__item-view {
    justify-content: flex-start;
  }

  &__attendees-container {
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex: 1;
  }

  &__attendees-txt {
    font-weight: 400;
    color: #fff;
    font-size: 14px;
  }

  .cancel-game {
    min-width: max-content;
    position: relative;
    margin-left: auto;
    flex-shrink: 0;
    border: 1px solid #aaa;
    border-radius: 4px;
    button {
      position: relative;
      width: 100%;
      padding: 0 10px;
      text-transform: uppercase;
      color: $color-white;
      font-weight: bold;
      font-size: 10px;
      border-radius: 2px;
      line-height: 20px;
      z-index: 1;
      transition: all 0.1s ease-out;
      outline: none;
      padding-bottom: 5px;
    }
    .cancel-options {
      display: none;
      position: absolute;
      color: #000;
      left: 0;
      top: 100%;
      width: 100%;
      background: $color-white;
      border: solid 1px $color-grey;
      &.show {
        display: block;
      }
      .cancel-option {
        position: relative;
        padding: 0 10px;
        font-weight: bold;
        font-size: 10px;
        line-height: 20px;
        text-transform: uppercase;
        background: $color-grey-light;
        cursor: pointer;
        &:hover {
          color: $primary_accent_color;
        }
      }
    }
    .v-select {
      position: absolute;
      left: 0;
      top: 0;
      margin: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      padding: 0;
      overflow: hidden;
    }
  }
  .twitch-container {
    height: 40vh !important;
  }
  .game-info-host {
    margin-top: -82px;
    margin-left: 8px;
    margin-bottom: 10px;
    width: 120px;
  }
}
</style>
