var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "pre-game-user-list", attrs: { "d-flex": "" } },
    [
      _c("RecycleScroller", {
        ref: "scroller",
        staticClass: "pre-game-user-list__recycle-scroller",
        attrs: { items: _vm.chunks, "item-size": 110, "key-field": "id" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var item = ref.item
              return _vm._l(item.users, function (user) {
                return _c(
                  "div",
                  {
                    key: user.id,
                    staticClass: "pre-game-user-list__column",
                    class: {
                      "pre-game-user-list__column--selected":
                        user.id === _vm.selectedUserID,
                    },
                  },
                  [
                    _c("LobbyPlayer", {
                      staticClass: "pre-game-user-list__user",
                      attrs: {
                        player: user,
                        showScore: _vm.showScore,
                        userID: user.id,
                      },
                      on: { onMouseOver: _vm.onMouseOver },
                      nativeOn: {
                        mouseleave: function ($event) {
                          return _vm.onMouseLeave.apply(null, arguments)
                        },
                        click: [
                          function ($event) {
                            if (!$event.altKey) {
                              return null
                            }
                            return _vm.edit(user)
                          },
                          function ($event) {
                            if (!$event.shiftKey) {
                              return null
                            }
                            return _vm.edit(user)
                          },
                        ],
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "pre-game-user-list__username",
                        attrs: { "mt-1": "", "text-xs-center": "" },
                      },
                      [_vm._v(" " + _vm._s(user.firstname) + " ")]
                    ),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }