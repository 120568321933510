var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { on: { mouseenter: _vm.onMouseOver } },
    [
      _c("RatioContainer", { staticClass: "pre-game-player" }, [
        _vm.showScore && parseInt(_vm.player.score)
          ? _c("div", { staticClass: "lobby-player-points-tag" }, [
              _vm._v(" " + _vm._s(parseInt(_vm.player.score)) + " "),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "video-container outline",
            class: {
              "--with-onboarding-overlay": _vm.isOnboarding,
              "video-container--conference": _vm.player.conference,
            },
          },
          [
            _c(
              "v-flex",
              {
                staticClass: "video-stream-wrap",
                attrs: { "d-flex": "", "justify-center": "" },
              },
              [
                _c(
                  "v-flex",
                  {
                    staticClass: "video-steam-placeholder-wrap",
                    attrs: { "d-flex": "", "justify-center": "" },
                  },
                  [_c("img", { attrs: { src: _vm.image } })]
                ),
              ],
              1
            ),
            _vm.player.vip && _vm.isHost
              ? _c("span", { staticClass: "pre-game-player__vip-tag" }, [
                  _vm._v(" VIP "),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }